import React from 'react';
import Product from '../Product';

const ProductsList = ({ isLoading, error, productsList }) => {
	return (
		<div className=''>
			{isLoading || error ? (
				<div className='text-center my-5'>
					{isLoading && (
						<div className='spinner-border text-primary' role='status'></div>
					)}
					{error && (
						<p className='h5 fw-normal text-muted'>
							{error || 'Products loading failed.'}
						</p>
					)}
				</div>
			) : (
				<section className='row'>
					{productsList.map((item) => (
						<div key={item.id} className='col-lg-4 col-sm-6 my-3'>
							<Product
								{...item}
								rating={item.averageRating}
								reviewCount={item.reviews?.length || 0}
							/>
						</div>
					))}
					{productsList.length === 0 && (
						<p className='col-12 h5 text-center my-5'>
							No products found in this category at the moment.
						</p>
					)}
				</section>
			)}
		</div>
	);
};

export default ProductsList;
