
import { footerLabels } from '../../StaticData';

const AboutUs = () => {
	return (
		<div className='row my-3'>
			<div className='col-12'>
				<p className='display-6 text-center'>About Us</p>
			</div>
			<div className='col-12'>
				<table className='table table-bordered table-hover h5 fw-normal'>
					<tbody>
						<tr>
							<td>Nume</td>
							<td>S.C. BIOMASER SANCOS S.R.L.</td>
						</tr>
						<tr>
							<td>CUI</td>
							<td>39953215</td>
						</tr>
						<tr>
							<td>Nr. Ord. ONRC</td>
							<td>J24/1501/2018</td>
						</tr>
						<tr>
							<td>Cod EORI</td>
							<td>RO39953215</td>
						</tr>
						<tr>
							<td>Capital social</td>
							<td>200 RON</td>
						</tr>
						<tr>
							<td>Sediu social</td>
							<td>
								Str. Grănicerilor bl. 102 ap. 49, Baia Mare, Jud. Maramureș, cod
								poștal 430373
							</td>
						</tr>
						<tr>
							<td>Punct de lucru</td>
							<td>
								Blv. București nr. 23, Baia Mare, Jud. Maramureș, cod poștal
								430251
							</td>
						</tr>
						<tr>
							<td>Banca</td>
							<td>Banca Transilvania</td>
						</tr>
						<tr>
							<td>{'IBAN (RON)'}</td>
							<td>RO12BTRLRONCRT0469613301</td>
						</tr>
						<tr>
							<td>SWIFT</td>
							<td>BTRLRO22</td>
						</tr>
						<tr>
							<td>Contact</td>
							<td>{footerLabels.contactEmail}</td>
						</tr>
						<tr>
							<td>Mobile</td>
							<td>{footerLabels.contactPhone}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AboutUs;
