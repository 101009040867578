
import { footerLabels } from '../../StaticData';
const Help = () => {
	return (
		<div className='row my-3'>
			<div className='col-12'>
				<p className='display-6 text-center'>Suport tehnic:</p>
			</div>
			<div className='col-12'>
				<table className='table table-bordered table-hover h5 fw-normal'>
					<tbody>
						<tr>
							<td>E-mail:</td>
							<td>{footerLabels.contactEmail}</td>
						</tr>
						<tr>
							<td>Telefon:</td>
							<td>{footerLabels.contactPhone}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Help;
