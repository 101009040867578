import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { functions } from '../../Firebase';
import { httpsCallable } from 'firebase/functions';

const initialState = {
	favItems: [],
	favItemsList: [],
	favCoursesList: [],
	favAmount: 0,
	syncFavs: false,
	isLoading: false,
	postFavItemsLoading: false,
};

const newItem = (productId, cover, title, price, stock) => {
	return { productId, cover, title, price, stock };
};
export const generateFavListAsync = createAsyncThunk(
	'favorites/generateFavListAsync',
	async (data, thunkAPI) => {
		const productsObj = thunkAPI.getState().products.productItemsObj;
		const list = thunkAPI.getState().favorites.favItems;
		let favoritesList = [];
		list.length > 0 &&
			list.forEach((item) => {
				const productItem = productsObj[item.productId];
				const itemToAdd = newItem(
					productItem.id,
					productItem.cover,
					productItem.title,
					productItem.price,
					productItem.stock
				);
				if (productItem.variations) {
					itemToAdd.variations = productItem.variations;
				}
				favoritesList.push(itemToAdd);
			});
		thunkAPI.dispatch(calculateFavTotals(JSON.stringify(favoritesList)));
		return favoritesList;
	}
);
export const getFavoriteItemsAsync = createAsyncThunk(
	'favorites/getFavoriteItemsAsync',
	async (data, { dispatch, rejectWithValue }) => {
		const getFavorites = httpsCallable(functions, 'getFavorites');
		return getFavorites()
			.then(async (result) => {
				dispatch(generateFavListAsync());
				return result.data;
			})
			.catch((err) => {
				console.log(err);
				return rejectWithValue(err);
			});
	}
);
export const addFavoriteItemAsync = createAsyncThunk(
	'favorites/addFavoriteItemAsync',
	async (data, thunkAPI) => {
		const user = thunkAPI.getState().user.userInfo;
		const productsObj = thunkAPI.getState().products.productItemsObj;
		const productItem = productsObj[data];
		const favItem = newItem(
			productItem.id,
			productItem.cover,
			productItem.title,
			productItem.price,
			productItem.stock
		);
		if (productItem.variations) {
			favItem.variations = productItem.variations;
		}
		thunkAPI.dispatch(addFavItem(favItem));
		if (user) {
			const addFavoritesItem = httpsCallable(functions, 'addFavoritesItem');
			return addFavoritesItem(data)
				.then((result) => {
					return result;
				})
				.catch((err) => {
					console.log(err);
					return err;
				});
		}
		return;
	}
);
export const removeFavoritesItemAsync = createAsyncThunk(
	'favorites/removeFavoritesItemAsync',
	async (data, thunkAPI) => {
		const user = thunkAPI.getState().user.userInfo;
		thunkAPI.dispatch(removeFavItem(data));
		if (user) {
			const removeFavoritesItem = httpsCallable(
				functions,
				'removeFavoritesItem'
			);
			return removeFavoritesItem(data)
				.then((result) => {
					return result;
				})
				.catch((err) => {
					console.log(err);
					return err;
				});
		}
		return;
	}
);
export const postFavItemsAsync = createAsyncThunk(
	'favorites/postFavItemsAsync',
	async (data, thunkAPI) => {
		const addFavoriteItems = httpsCallable(functions, 'addFavoriteItems');
		const { favItems, favCoursesList } = thunkAPI.getState().favorites;

		return addFavoriteItems({
			products: favItems,
			courses: favCoursesList,
		})
			.then((result) => {
				thunkAPI.dispatch(getFavoriteItemsAsync());
				return result;
			})
			.catch((err) => {
				console.log(err);
				return err;
			});
	}
);

export const toggleCourseItemFavorite = createAsyncThunk(
	'favorites/toggleCourseItemFavorite',
	async (data, { getState, fulfillWithValue, rejectWithValue }) => {
		const { favCoursesList } = getState().favorites;
		const { userInfo } = getState().user;

		const { itemId, operationType } = data;
		try {
			// Save entry to DB only if user is logged in
			if (userInfo) {
				const toggleCourseFavorite = httpsCallable(
					functions,
					'toggleCourseItemFavoritesFunc'
				);
				await toggleCourseFavorite(data);
			}
			let coursesList = [...favCoursesList];
			if (operationType === 'add') {
				coursesList.push(itemId);
			} else {
				coursesList = coursesList.filter((courseId) => courseId !== itemId);
			}
			return fulfillWithValue(coursesList);
		} catch (error) {
			console.log('FAILED:::', error);
			return rejectWithValue(error);
		}
	}
);

const favoritesSlice = createSlice({
	name: 'favorites',
	initialState,
	reducers: {
		clearFavorites: (state) => {
			state.favItems = [];
			state.favCoursesList = [];
		},
		removeFavItem: (state, action) => {
			const itemId = action.payload;
			state.favItems = state.favItems.filter(
				(item) => item.productId !== itemId
			);
		},
		updateFavorites: (state, { payload }) => {
			const productsObj = JSON.parse(payload);
			let newFavList = [];
			state.favItems.forEach((item) => {
				const productItem = productsObj[item.productId];
				if (productItem.stock !== item.stock) {
					newFavList.push({ ...item, stock: productItem.stock });
				} else newFavList.push(item);
			});
			state.favItems = newFavList;
		},
		addFavItem: (state, { payload }) => {
			state.favItems.push(payload);
		},
		calculateFavTotals: (state, { payload }) => {
			const list = JSON.parse(payload);
			state.favAmount = list.length || 0;
		},
	},
	extraReducers: {
		[getFavoriteItemsAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[getFavoriteItemsAsync.fulfilled]: (state, action) => {
			state.favItems = action.payload.products;
			state.favCoursesList = action.payload.courses;
			state.isLoading = false;
		},
		[getFavoriteItemsAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[addFavoriteItemAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[addFavoriteItemAsync.fulfilled]: (state, action) => {
			state.isLoading = false;
		},
		[addFavoriteItemAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[postFavItemsAsync.pending]: (state) => {
			state.postFavItemsLoading = true;
		},
		[postFavItemsAsync.fulfilled]: (state, action) => {
			state.postFavItemsLoading = false;
		},
		[postFavItemsAsync.rejected]: (state) => {
			state.postFavItemsLoading = false;
		},
		[removeFavoritesItemAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[removeFavoritesItemAsync.fulfilled]: (state, action) => {
			state.isLoading = false;
		},
		[removeFavoritesItemAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[generateFavListAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[generateFavListAsync.fulfilled]: (state, action) => {
			state.favItemsList = action.payload;
			state.isLoading = false;
		},
		[generateFavListAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[toggleCourseItemFavorite.fulfilled]: (state, { payload }) => {
			state.favCoursesList = payload;
		},
	},
});
export const {
	removeFavItem,
	calculateFavTotals,
	addFavItem,
	clearFavorites,
	updateFavorites,
} = favoritesSlice.actions;

// SELECTORS
const selectFavouritesData = (state) => {
	return state.favorites;
};
export const selectFavouritesCoursesList = createSelector(
	selectFavouritesData,
	(favouritesData) => favouritesData.favCoursesList
);

export default favoritesSlice.reducer;
