export const SelectedProdVariaties = ({
	curvature,
	thickness,
	length,
	className,
	showBrackets,
}) => {
	return (
		<p className={`me-2 mb-0 ${className || ''}`}>
			{showBrackets && <span>{'( '}</span>}
			<span>{curvature} &bull;</span>
			<span className='mx-1'>{thickness} &bull;</span>
			<span>{length}</span>
			{showBrackets && <span>{' )'}</span>}
		</p>
	);
};
