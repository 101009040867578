import { Modal } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import PasswordUpdateFrom from './PasswordUpdateFrom';
import ProfileUpdateFrom from './ProfileUpdateFrom';
import AddressUpdateFrom from './AddressUpdateFrom';

const modalTitles = {
	profile: 'Profile',
	password: 'Password',
	billingAddress: 'Billing Address',
	shippingAddress: 'Shipping Address',
};

const AcountUpdateModal = ({
	showFormModal,
	handleCloseFormModal,
	formType,
}) => {
	return (
		<Modal show={showFormModal} onHide={handleCloseFormModal} backdrop='static'>
			<div className='bg-white rounded position-relative'>
				<div className='d-flex align-items-center justify-content-between px-3 pt-3 border-bottom'>
					<h5>Update {modalTitles[formType]}</h5>
					<button
						className='btn btn-sm'
						type='button'
						onClick={handleCloseFormModal}
					>
						<BsX />
					</button>
				</div>
				<div className='px-5 pt-4 pb-5'>
					{formType === 'password' && (
						<PasswordUpdateFrom closeModal={handleCloseFormModal} />
					)}
					{formType === 'profile' && (
						<ProfileUpdateFrom closeModal={handleCloseFormModal} />
					)}
					{(formType === 'billingAddress' ||
						formType === 'shippingAddress') && (
						<AddressUpdateFrom
							addressType={formType}
							closeModal={handleCloseFormModal}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default AcountUpdateModal;
