import clsx from 'clsx';
import { ErrorBoundary } from 'react-error-boundary';
import SharedLayout from '../../pages/SharedLyout';
import { BsExclamationTriangle } from 'react-icons/bs';

import styles from './ErrorBoundaries.module.scss';

export const AppErrorBoundary = ({ children }) => {
	return (
		<ErrorBoundary FallbackComponent={AppErrorFallback}>
			{children}
		</ErrorBoundary>
	);
};

const AppErrorFallback = ({ resetErrorBoundary }) => {
	const onRecoverError = () => {
		resetErrorBoundary();
		window.location.reload(false);
	};

	return (
		<div className={styles.container}>
			<SharedLayout />
			<div className={styles.errorWrapper}>
				<div className={clsx('card non-hoverable', styles.errorInner)}>
					<BsExclamationTriangle className={styles.errorIcon} />
					<p className='display-6'>Oops!</p>
					<p className='h4 fw-light'>
						There was some error with the app. We are working to fix it. Please
						refresh the app.
					</p>
					<div className=''>
						<button className={styles.refreshBtn} onClick={onRecoverError}>
							Refresh
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
