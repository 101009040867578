import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fireDB } from '../../Firebase';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';

const initialState = {
	productItems: [],
	productItemsObj: {},
	productsLoading: true,
};

export const getProductItems = createAsyncThunk(
	'products/getProductItems',
	async () => {
		try {
			const data = await getDocs(collection(fireDB, 'products'));
			let items = {};
			let itemsObj = {};
			let itemslist = [];
			data.forEach((doc) => {
				itemsObj[doc.id] = { ...doc.data(), id: doc.id };
				itemslist.push({ ...doc.data(), id: doc.id });
			});
			items['list'] = itemslist;
			items['obj'] = itemsObj;
			return items;
		} catch (error) {
			console.log(error.message);
			return error;
		}
	}
);
export const getProductItem = createAsyncThunk(
	'products/getProductItem',
	async (id, thunkAPI) => {
		try {
			const docRef = doc(fireDB, 'products', id);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				return { ...docSnap.data(), id: docSnap.id };
			} else {
				console.log('No such document: ' + id);
				toast.error('Produsul nu a fost gasit, încercați mai târziu.');
			}
		} catch (error) {
			console.log(error.message);
			return error;
		}
	}
);

const productsSlice = createSlice({
	name: 'products',
	initialState,
	extraReducers: {
		[getProductItems.pending]: (state) => {
			state.productsLoading = true;
		},
		[getProductItems.fulfilled]: (state, action) => {
			state.productItems = action.payload.list;
			state.productItemsObj = action.payload.obj;
			state.productsLoading = false;
		},
		[getProductItems.rejected]: (state) => {
			state.productsLoading = false;
		},
		[getProductItem.pending]: (state) => {
			state.productsLoading = true;
		},
		[getProductItem.fulfilled]: (state, action) => {
			const newProductItem = action.payload;
			const productsObj = state.productItemsObj;
			const stateProductItem = productsObj[newProductItem.id];
			if (stateProductItem.stock !== newProductItem.stock) {
				productsObj[newProductItem.id].stock = newProductItem.stock;
				state.productItemsObj = productsObj;
				state.productItems = Object.values(productsObj);
			}
			state.productsLoading = false;
		},
		[getProductItem.rejected]: (state) => {
			state.productsLoading = false;
		},
	},
});

export default productsSlice.reducer;
