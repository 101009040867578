import BannerItem from '../../components/ProductBannerItem';
import { bannerItems } from '../../StaticData';

const ProductBanner = () => {
    const items = bannerItems.map(item => {
        return (
            <BannerItem
                key={item.id}
                {...item}
            />
        )
    });

    return (
        <div className='col-md-12'>
            <div className='card non-hoverable border-0 mt-4'>
                <div className='card-body d-flex justify-content-center align-items-center'>
                    {items}
                </div>
            </div>
        </div>
    );
};

export default ProductBanner;
