import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
} from 'firebase/auth';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import * as Yup from 'yup';
import { setUser } from '../../features/User';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { auth } from '../../Firebase';
import { getFirebaseErrorMessageByCode } from '../../utils/firebaseErrors';

import styles from './AuthForm.module.scss';

const loginSchema = Yup.object({
	email: Yup.string()
		.email('Vă rugăm să furnizați un e-mail valid')
		.required('Acest câmp este obligatoriu'),
	password: Yup.string().required('Acest câmp este obligatoriu'),
});

export const LoginForm = ({ activeTab }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [passowrdVisible, setPassowrdVisible] = useState(false);

	const submitForm = async ({ email, password }) => {
		setIsLoading(true);
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				setIsLoading(false);
				const user = userCredential.user;
				dispatch(setUser(JSON.stringify({ user })));
				toast.success('Te-ai logat cu succes.');
				navigate(-1);
				return;
			})
			.catch((error) => {
				setIsLoading(false);
				console.error(error.message);
				const errorMessage = getFirebaseErrorMessageByCode(error.code);
				toast.error(errorMessage);
				return;
			});
	};

	const { values, handleChange, handleSubmit, resetForm, touched, errors } =
		useFormik({
			initialValues: {
				email: '',
				password: '',
			},
			validationSchema: loginSchema,
			onSubmit: submitForm,
		});

	const provider = new GoogleAuthProvider();

	const handleResetPassClick = (e) => {
		e.preventDefault();
		navigate('/reset-password');
	};

	const signinWithGoogle = async (e) => {
		e.preventDefault();
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				dispatch(setUser(JSON.stringify({ user })));
				toast.success('Te-ai logat cu succes.');
				navigate(-1);
			})
			.catch((error) => {
				toast.error(error.message);
				console.log(error.message);
			});
	};

	useEffect(() => {
		resetForm();
	}, [activeTab]);

	return (
		<form onSubmit={handleSubmit}>
			<div>
				<h3 className={styles['Auth-form-title']}>Intră în cont</h3>
				<div className='form-group mt-5'>
					<label>Adresa de email</label>
					<input
						name='email'
						type='email'
						className='form-control mt-1'
						placeholder='Email'
						value={values.email}
						onChange={handleChange}
					/>
					{touched.email && errors.email && (
						<span className='text-danger tiny-text'>{errors.email}</span>
					)}
				</div>
				<div className='form-group mt-3'>
					<label>Parola</label>
					<div className='input-group position-relative mt-1'>
						<input
							name='password'
							type={passowrdVisible ? 'text' : 'password'}
							className={clsx('form-control', styles.passwordInput)}
							placeholder='Parola'
							value={values.password}
							onChange={handleChange}
						/>
						<button
							className='btn btn-sm position-absolute h-100 top-0 end-0'
							type='button'
							onClick={() => setPassowrdVisible(!passowrdVisible)}
						>
							{passowrdVisible ? (
								<BsEyeSlashFill className='default' size={16} />
							) : (
								<BsEyeFill className='default' size={16} />
							)}
						</button>
					</div>
					{touched.password && errors.password && (
						<span className='text-danger tiny-text'>{errors.password}</span>
					)}
				</div>
				<div className='d-grid gap-2 mt-5'>
					<button
						type='submit'
						className='btn btn-primary submit text-white py-2 rounded-0'
						disabled={isLoading}
					>
						{isLoading ? 'Trimiterea' : 'Trimite'}
					</button>
				</div>
				<p className='style-text mt-4'>
					<a href='#' onClick={handleResetPassClick}>
						Resetează parola
					</a>
				</p>
				<p className={clsx('mb-4', styles.seperator)}>
					<span>sau</span>
				</p>
				<button
					type='submit'
					className={styles.googleAuthBtn}
					onClick={signinWithGoogle}
					disabled={isLoading}
				>
					<img className={styles.autLoglogo} src={'/GoogleLogo.svg'} />
					<span>Loghează-te cu Google</span>
				</button>
			</div>
		</form>
	);
};
