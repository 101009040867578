import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { BsX } from 'react-icons/bs';
import CustomSelect from '../CustomSelect';
import { parseProductVariations } from '../../utils/products.utils';
import styles from './FavoritesItem.module.scss';

export const VariationsSelector = ({
	isVisible,
	variations,
	selectedCurvature,
	slectedThickness,
	selectedLength,
	onCancel,
	onSave,
}) => {
	const [curvature, setCurvature] = useState(selectedCurvature);
	const [thickness, setThickness] = useState(slectedThickness);
	const [length, setLength] = useState(selectedLength);

	const [selectableCurvatures, setSelectableCurvatures] = useState([]);
	const [selectableThicknesse, setSelectableThicknesses] = useState({});
	const [selectableLengths, setSelectableLengths] = useState({});

	const onChangeCurvature = (value) => {
		setCurvature(value);
		setThickness('');
		setLength('');
	};
	const onChangeThickness = (value) => {
		setThickness(value);
		setLength('');
	};
	const onChangeLength = (value) => {
		setLength(value);
	};

	const handleSave = () => {
		onSave({
			newCurvature: curvature,
			newThickness: thickness,
			newLength: length,
		});
	};

	useEffect(() => {
		setCurvature(selectedCurvature);
		setThickness(slectedThickness);
		setLength(selectedLength);
	}, [selectedCurvature, slectedThickness, selectedLength]);

	useEffect(() => {
		const { curvaturesList, thicknessList, lengthsList } =
			parseProductVariations(variations);

		setSelectableCurvatures(curvaturesList);
		setSelectableThicknesses(thicknessList);
		setSelectableLengths(lengthsList);
	}, []);

	return (
		<div
			className={clsx({
				[styles.variationsContainer]: true,
				[styles.show]: isVisible,
			})}
		>
			<div className='text-end'>
				<button className='btn btn-sm' onClick={onCancel}>
					<BsX />
				</button>
			</div>
			<div className='d-flex flex-column flex-sm-row gap-3'>
				<div className=''>
					<label className='form-label'>curbura</label>
					<CustomSelect
						placeholder={'curbura'}
						options={selectableCurvatures}
						selected={curvature}
						onChange={({ value }) => onChangeCurvature(value)}
					/>
				</div>
				<div className=''>
					<label className='form-label'>grosime</label>
					<CustomSelect
						placeholder={'grosime'}
						options={selectableThicknesse[curvature] || []}
						selected={thickness}
						onChange={({ value }) => onChangeThickness(value)}
					/>
				</div>
				<div className=''>
					<label className='form-label'>lungime</label>
					<CustomSelect
						placeholder={'lungime'}
						options={selectableLengths[`${curvature}_${thickness}`] || []}
						selected={length}
						onChange={({ value }) => onChangeLength(value)}
					/>
				</div>
			</div>
			<div className='mt-3'>
				<button className='btn btn-secondary' onClick={onCancel}>
					Cancel
				</button>
				<button
					className='btn btn-primary text-white ms-2'
					onClick={handleSave}
					disabled={!curvature || !thickness || !length}
				>
					Save Changes
				</button>
			</div>
		</div>
	);
};
