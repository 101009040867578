import { useSelector } from 'react-redux';
import { selectFavouritesCoursesList } from '../features/Favorites';
import { selectAllCoursesObjectified } from '../Store/courses';
import { selectCoursesInCart } from '../Store/coursesCart';
import { selectActiveCourses } from '../Store/ordersHistory';
import { selectUserPreferences } from '../features/User';
import { calculateCourseSku, decodeCourseSku } from '../utils/courses.utils';

export const useIsCourseInFavorites = (courseId) => {
	const favoritesList = useSelector(selectFavouritesCoursesList);
	return favoritesList.some((id) => courseId === id);
};

export const useIsCourseInCart = (courseId, classTime) => {
	const courses = useSelector(selectCoursesInCart);
	let courseSku = calculateCourseSku(courseId, classTime);
	return courses.some((sku) => courseSku === sku);
};

export const useCoursesInCartTotalAmount = () => {
	const courses = useSelector(selectCoursesInCart);
	const allCourses = useSelector(selectAllCoursesObjectified);
	return courses.reduce((prev, current) => {
		const [courseId] = decodeCourseSku(current);
		const course = allCourses[courseId];
		const coursePrice = course ? course.price : 0;
		return prev + coursePrice;
	}, 0);
};

export const useCoursesInCart = () => {
	const courses = useSelector(selectCoursesInCart);
	const allCourses = useSelector(selectAllCoursesObjectified);
	return courses
		.map((courseSku) => {
			const [courseId, classTime] = decodeCourseSku(courseSku);
			if (!allCourses[courseId]) {
				return null;
			}
			let foundCourse = {
				...allCourses[courseId],
				selectedClassTime: classTime,
				courseSku: courseSku,
			};
			return foundCourse;
		})
		.filter((item) => !!item);
};

export const useGetActiveCourseById = (courseId, classTime) => {
	const { activeCourses } = useSelector(selectActiveCourses);
	return activeCourses.find(
		(activeCourse) =>
			courseId === activeCourse.courseId &&
			(activeCourse.classTime ? classTime === activeCourse.classTime : true)
	);
};

export const useSelectFirstAvailableClass = (courseId, classes) => {
	const userPreferences = useSelector(selectUserPreferences);
	if (!classes) {
		return null;
	}
	const userPreferredClassTime =
		userPreferences && userPreferences.preferedCoursesClasses
			? userPreferences.preferedCoursesClasses[courseId]
			: null;
	const isPreferredClassAvailable = classes.find(
		(item) => item.class === userPreferredClassTime && item.availableSeats > 0
	);

	if (isPreferredClassAvailable) {
		return userPreferredClassTime;
	}
	const availableClassKey = classes.find(
		(classSession) => classSession.availableSeats > 0
	);
	if (availableClassKey) {
		return availableClassKey.class;
	}
	return userPreferredClassTime || 'morning';
};
