import styles from './EmailVerificationAlert.module.scss';
import { Link } from 'react-router-dom';

export const EmailVerificationAlertModal = () => {
	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalCard}>
				<p>Pentru a avea acces deplin pe website-ul nostru, te rugăm să îți verifici adresa de email accesând link-ul primit.
					Dacă nu îl găsești, poți solicita altul din
					<Link className='btn btn-link' to='/my-account'>
						Contul meu
					</Link>
					butonul Verifică Email.
				</p>
			</div>
		</div>
	);
};
