import { footerLabels } from '../../StaticData';
const TermsConditions = () => {
	return (
		<div className='row my-5'>
			<div className='col-12'>
				<p className='display-6 text-center'>TERMENI ȘI CONDIȚII</p>
			</div>
			<div className='h5 fw-normal'>
				<div className='row'>
					<div className='col-12'>
						<p className='fw-bold'>Definitii generale</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											SERVICIU – serviciul de comert electronic condus exclusiv
											pe portiunile public disponibile ale
											www.sancosacademy.com, in sensul acordarii posibilitatii
											CLIENTULUI sa contracteze produse/ cursuri folosind
											mijloace exclusiv electronice.
										</p>
									</li>
									<li>
										<p>
											UTILIZATOR – persoana fizica sau juridica de drept public
											sau privat care are sau obtine acces la CONTINUT, prin
											orice mijloc de comunicare (electronic, telefonic, etc)
											sau in baza unui acord de utilizare intre S.C. BIOMASER
											SANCOS S.R.L. si acesta.
										</p>
									</li>
									<li>
										<p>
											BENEFICIAR – persoana fizica sau juridica de drept public
											sau privat care are sau obtine acces la CONTINUT, prin
											orice mijloc de comunicare (electronic, telefonic, etc)
											sau in baza unui acord de utilizare intre S.C. BIOMASER
											SANCOS S.R.L. si acesta.
										</p>
									</li>
									<li>
										<p>
											PRESTATOR - denumit generic „Sancos Academy” reprezentata
											prin firma partenera și/ sau colaboratoare S.C. BIOMASER
											SANCOS S.R.L.,
										</p>
									</li>
									<li>
										<p>
											CONT – ansamblul format dintr-o adresa de e-mail si o
											parola care permit unui singur UTILIZATOR accesul la zone
											restrictionate ale SITE-ULUI prin care se face accesul la
											PRODUS/ SERVICIU.
										</p>
									</li>
									<li>
										<p>
											CLIENT – Persoana fizica sau juridica de drept public sau
											privat care are sau obtine acces la CONTINUT si SERVICIU,
											prin orice mijloc sau in baza unui acord de utilizare
											intre S.C. BIOMASER SANCOS S.R.L. si acesta, sau persoana
											fizica sau juridica care beneficiaza de pe urma produselor
											si/sau serviciilor oferite de S.C. BIOMASER SANCOS S.R.L.
											si achizitionate de catre acesta prin folosirea
											SERVICIULUI.
										</p>
									</li>
									<li>
										<p>DOCUMENT – prezentele Termeni si Conditii</p>
									</li>
									<li>
										<p>
											BULETIN INFORMATIV / NEWSLETTER – mijlocul de informare
											periodic, exclusiv electronic, asupra produselor,
											serviciilor si/sau promotiilor desfasurate de catre S.C.
											BIOMASER SANCOS S.R.L.. intr-o anumita perioada, fara nici
											un angajament din partea S.C. BIOMASER SANCOS S.R.L. cu
											referire la informatiile continute de acesta.
										</p>
									</li>
									<li>
										<p>
											TRANZACTIE – incasare sau rambursare a unei sume rezultata
											din vanzarea unui produs / serviciu de catre S.C. BIOMASER
											SANCOS S.R.L. catre client indiferent de modalitatea de
											livrare.
										</p>
									</li>
									<li>
										<p>
											CONTRACT la distanta – conform definitiei cuprinse in
											Ordonanta de urgenta a Guvernului nr. 34/2014.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Politica generală</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={2}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Acest document stabileste termenii si conditiile de
											utilizare ai site- ului/continutului/serviciului/
											produselor de catre utilizator, client sau beneficiar, in
											cazul in care acesta nu dispune de un alt acord de
											utilizare valid, incheiat intre S.C. BIOMASER SANCOS SRL.,
											Bld. București nr. 23, Baia Mare, Jud. Maramures si
											acesta.
										</p>
									</li>
									<li>
										<p>
											Accesul la serviciu se face exclusiv prin accesarea
											site-ului public www.sancosacademy.com
										</p>
									</li>
									<li>
										<p>
											Folosirea, inclusiv dar nelimitandu-se la accesarea,
											vizitarea si vizualizarea, continutului/serviciului,
											implica aderarea utilizatorului sau clientului la
											prezentele termene si conditii in afara de cazul in care
											continutul respectiv nu are conditii de folosire distinct
											formulate.
										</p>
									</li>
									<li>
										<p>
											Prin folosirea site-ului/ continutului/ serviciului/
											produselor, Utilizatorul sau Clientul este singurul
											responsabil pentru toate activitatile care decurg prin
											folosirea acestuia.
										</p>
									</li>
									<li>
										<p>
											De asemenea, acesta raspunde pentru orice daune materiale,
											intelectuale sau electronice sau de orice alta natura
											produse site-ului, continutului, serviciului, S.C.
											BIOMASER SANCOS S.R.L. sau oricarui tert cu care S.C.
											BIOMASER SANCOS S.R.L. are incheiate contracte, in
											conformitate cu legislatia romana in vigoare.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>
							Conținut site si drept de proprietate intelectuala
						</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={3}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Continutul informativ/imagistic prezentat pe site este
											PROPRIETATEA INTELECTUALA a S.C. BIOMASER SANCOS S.R.L.
										</p>
									</li>
									<li>
										<p>
											Nici un continut transmis catre Utilizator sau Client,
											prin orice mijloc de comunicare sau dobandit de acesta
											prin accesare, vizitare si/sau vizualizare nu constituie o
											obligatie contractuala din partea si/sau al angajatului
											S.C. BIOMASER SANCOS S.R.L. care a mijlocit transferul de
											continut, in cazul in care aceasta exista, fata de
											respectivul continut.
										</p>
									</li>
									<li>
										<p>
											Orice utilizare a continutului in alte scopuri decat cele
											permise expres prin prezentul document este interzisa.
										</p>
									</li>
									<li>
										<p>
											Continutul acestui site nu poate fi utilizat, reprodus,
											distribuit, transmis, expus, in alte scopuri decat cele
											expres si legal permise. Extragerea oricaror informatii
											urmata de orice utilizare in scop comercial care depaseste
											sfera copiei private reglementate de lege sau pentru
											vanzare ori licentiere si fara a avea in prealabil un
											consimtamant scris al titularilor drepturilor de
											proprietate constituie o incalcare a termenilor si
											conditiilor.
										</p>
									</li>
									<li>
										<p>
											Sunteti de asemenea de acord sa nu afectati si interferati
											in vreun fel cu elementele de securitate ale site-ului, cu
											elementele care previn sau restrictioneaza utilizarea,
											copierea unui continut sau elementele care intaresc
											limitele de utilizare a siteului sau a continutului
											acestuia.
										</p>
									</li>
									<li>
										<p>
											Va rugam sa va asigurati ca ati verificat specificatiile
											produsului, compatibilitatea/ detaliile, cuprinsul
											cursurilor inainte de a face achiziții. Raporturile
											comerciale dintre cumparator si www.sancosacademy.com sunt
											reglementate oficial de Ordonanta Guvernului nr. 34/2014,
											privind drepturile consumatorilor in cadrul contractelor
											incheiate cu profesionistii, coroborata cu Ordonanta
											Guvernului nr. 130/2000, privind regimul juridic al
											contractelor la distanta.
										</p>
									</li>
									<li>
										<p>
											Administratorul www.sancosacademy.com face eforturi
											permanente pentru a pastra acuratetea informatiilor de pe
											site, insa uneori acestea pot contine inadvertente
											(specificatiile sau pretul produselor modificate de catre
											producator fara preaviz sau viciate de erori de operare).
											Atentie: fotografiile au caracter informativ si pot
											contine accesorii care nu sunt incluse in pachetele
											standard.
										</p>
									</li>
									<li>
										<p>
											Ne rezervăm dreptul de a modifica Informațiile și aplicațiile pe care le oferă acest Site, oricând și fără anunțarea prealabilă a utilizatorilor.
										</p>
										<p>
											Imaginile produselor prezentate pe Site sunt cu titlu de prezentare și pot diferi în orice mod (culoare, aspect etc.) de imaginile produselor livrate, acestea putând prezenta abateri minore de la pozele și descrierile prezentate pe Site.
										</p>
										<p>
											Magazinul nu se obligă să prezinte pe Site fotografii și informații indentice cu ale produselor ce vor fi livrate. În schimb, în conformitate cu dispozițiile legale, cumpăratorul care comandă online un produs, cu livrare la domiciliul acestuia poate returna în 14 zile produsul, în cazul în care nu corespunde cu așteptările sale, cu condiția ca produsul să nu prezinte urme de folosire (fără zgârieturi, urme ale folosirii produsului, deteriorarea pachetului în care a fost asamblat, etc.)
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Politica de vânzare on-line</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={4}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Accesul la serviciu este permis oricarui utilizator care
											poseda sau isi creeaza un cont.
										</p>
									</li>
									<li>
										<p>
											Pentru a i se permite accesul la serviciu, Utilizatorul va
											trebui sa accepte prevederile prezentului document.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>
							Produsele oferite on line pe www.sancosacademy.com
						</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={5}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Produsele comercializate prin intermediul site-ului
											www.sancosacademy.com sunt noi, in ambalajul original sau
											al www.sancosacademy.com si in momentul livrarii sunt
											insotite de factura fiscala si declaratie de conformitate,
											conform legislatiei in vigoare.
										</p>
									</li>
									<li>
										<p>
											Produsele si serviciile sunt oferite in conditiile
											respectarii prevederilor prezentului document si in limita
											stocului disponibil. Din acest motiv S.C. BIOMASER SANCOS
											S.R.L. isi asuma dreptul de a nu onora o anumita comanda
											in cazul in care respectivul produs nu se mai regaseste in
											oferta sa curenta.
										</p>
									</li>
									<li>
										<p>
											Preturile prezentate nu includ cheltuielile de livrare, in
											afara cazului in care se specifica altfel pe site. Pretul
											de achizitie tiparit pe factura fiscală va fi acelasi cu
											cel stabilit in momentul confirmarii comenzii, ca fiind
											comanda ferma.
										</p>
									</li>
									<li>
										<p>
											Prețurile reprezintă prețurile de cumpărare, și sunt
											afișate în lei (RON) sau euro (EUR). Deoarece Furnizorul
											nu este societate plătitoare de TVA, prețurile nu includ
											TVA. Prețul prezentat pe factură va fi același cu cel
											prezentat pe Site la momentul plasării comenzii. Prețurile
											nu includ costuri/tarife de asigurare și expediere.
											Pentru produsele cu preturi exprimate in euro sau alte
											monede, se va achita contravaloarea sumelor respective
											in lei, la cursul valutar al bancii care proceseaza
											plata +1%.
										</p>
									</li>
									<li>
										<p>
											Pentru o comandă, se vor adăuga și costurile de livrare
											(prețul companiei de curierat selectate). Costul livrării
											este afișat la plasarea comenzii și diferă în funcție de
											serviciul de curierat rapid selectat. Deci, suma finală
											include atât sumele corespunzătoare produselor comandate,
											cât și costul de livrare.
										</p>
										<p>
											Costul fix de livrare menționat pe site NU conține costul pentru kilometri suplimentari. Costul pentru kilometri suplimentari este suportat de către cumpărător/ client și îi v-a fi comunicat ulterior clientului/ cumpărătorului prin apel telefonic.
										</p>
										<p>
											În cazul comenzilor care depășesc o anumită sumă, încadrate pentru “livrare gratuită”, pretul final al comenzii NU conține costul pentru kilometri suplimentari. Costul pentru kilometri suplimentari este suportat de către cumpărător/ client și îi v-a fi comunicat ulterior clientului/ cumpărătorului prin apel telefonic.
										</p>
									</li>
									<li>
										<p>Plata se poate face în următoarele moduri:</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													numerar, ramburs (la ridicarea coletului) direct către
													compania de curierat;
												</p>
											</li>
											<li>
												<p>
													numerar, la ridicarea coletului de la punctul de lucru Sancos Academy;
												</p>
											</li>
											<li>
												<p>
													în avans, prin transfer bancar, în baza unei facturi;
												</p>
											</li>
											<li>
												<p>
													card bancar, prin intermediul procesatorului de plăți
													Stripe.
												</p>
											</li>

										</ul>
										<p>
											În cazul transferului bancar, vă rugăm să treceți la
											detalii/referință numărul comenzii. Vă rugăm să vă
											asigurați că acoperiți în suma trimisă eventualele
											comisioanele percepute de bancă, adică în contul
											Furnizorului să ajungă suma finală de achitat pentru
											comanda în cauză.
										</p>
										<p>
											În cazul plății prin card bancar, ne rezervăm dreptul
											de a întârzia procesarea comenzii până în momentul în
											care plata a fost aprobată de către procesatorul de
											plăți.
										</p>
									</li>
									<li>
										<p>
											Livrările se vor face doar pe teritoriul României prin intermediul unui serviciu de curierat rapid, dacă destinația se află în raza de acoperiere a serviciilor de curierat disponibile. Serviciul de curierat poate fi selectat de Client la plasarea comenzii. Costul livrării pentru Client va fi afișat în momentul selectării serviciului de curierat rapid.
										</p>
										<p>
											Costul efectiv al livrării poate fi mai mare decât cel estimat, datorită kilometrilor suplimentari. In aceste situații, după crearea AWB-ului, veți fi contactat telefonic pentru clarificări si informații in legătură cu restul sumei pe care o aveți de plată.
										</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													În mod normal, livrarea se face în 36-72 ore. Acest
													termen este orientativ, el putând fi modificat din
													motive independente de voința noastră.
												</p>
											</li>
										</ul>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Valabilitatea si confirmarea comenzilor</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={6}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. isi rezerva dreptul de a
											modifica tarifele practicate pentru produsele si/sau
											serviciile disponibile pe site fara notificarea prealabila
											a Utilizatorului / Clientului.
										</p>
									</li>
									<li>
										<p>
											Pretul de achizitie al produselor si serviciilor este cel
											din momentul emiterii comenzii, in limita stocului
											disponibil.
										</p>
									</li>
									<li>
										<p>
											Pretul de achizitie al produselor si serviciilor
											promotionale este cel din momentul emiterii comenzii, in
											limita stocului disponibil si/sau al perioadei
											promotionale, definita concret pe site.
										</p>
									</li>
									<li>
										<p>
											Pretul de achizitie al produselor sau serviciilor dintr-o
											comanda emisa nu se poate modifica la un moment ulterior
											emiterii acesteia decat cu acordul partilor.
										</p>
									</li>
									<li>
										<p>
											Pretul de achizitie al produselor si/sau serviciilor
											dintr-un contract onorat nu se poate modifica.
										</p>
									</li>
									<li>
										<p>
											Clientul poate renunta la produsele si serviciile
											achizitionate prin comanda, numai in perioada de timp
											cuprinsa intre emiterea comenzii si confirmarea acesteia.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Garantie produse</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={7}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Clientul este obligat sa-si insuseasca si sa respecte
											indicatiile de utilizare a produselor.
										</p>
									</li>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. este răspunzătoare faţă de
											client pentru orice lipsă a conformităţii existentă la
											momentul când au fost livrate produsele.
										</p>
									</li>
									<li>
										<p>
											Clientul are dreptul de a solicita să i se aducă produsul
											la conformitate, fără plată, prin reparare sau înlocuire,
											sau să beneficieze de reducerea corespunzătoare a preţului
											ori de rezoluţiunea contractului privind acest produs.
										</p>
									</li>
									<li>
										<p>
											Clientul are dreptul de a solicita în primul rând
											repararea produsului sau are dreptul de a solicita
											înlocuirea produsului, în fiecare caz fără plată, cu
											excepţia situaţiei în care măsura este imposibilă sau
											disproporţionată.
										</p>
									</li>
									<li>
										<p>
											Orice reparare sau înlocuire a produselor va fi făcută în
											cadrul unei perioade rezonabile de timp, stabilită de
											comun acord de parti şi fără niciun inconvenient
											semnificativ pentru client, luându-se în considerare
											natura produselor şi scopul pentru care acesta a solicitat
											produsele.
										</p>
									</li>
									<li>
										<p>
											Perioada de timp stabilită nu poate depăşi 15 zile
											calendaristice de la data la care clientul a adus la
											cunoştinţă lipsa de conformitate a produsului.
										</p>
									</li>
									<li>
										<p>
											La solicitarea garantiei va rugam sa returnati marfa
											impreuna cu factura fiscala si cu formularul de retur.
											Aveți obligația de a solicita Formularul de Retur pe
											adresa noastră de email {footerLabels.contactEmail}
										</p>
									</li>
									<li>
										<p>
											Contravaloarea produsului returnat se va plati prin
											transfer bancar in maximum 14 zile calendaristice de la
											data la care S.C. BIOMASER SANCOS S.R.L. a primit
											produsele impreuna cu formularul de retur.
										</p>
									</li>
									<li>
										<p>
											In aceasta situatie clientului care a notificat S.C.
											BIOMASER SANCOS S.R.L. ii revine responsabilitatea de a se
											asigura ca produsele la care a facut referire vor fi
											returnate la adresa afisata pe site contact in maximum 14
											zile calendaristice de la data expedierii notificarii, in
											caz contrar S.C. BIOMASER SANCOS S.R.L. va considera
											cererea nefondata/invalida. Transportul pentru returnarea
											produselor se achita de catre client.
										</p>
									</li>
									<li>
										<p>
											In cazul inlocuirii produsului cu unul identic, inlocuirea
											se va face in conditiile si limitele unei comenzi normale.
										</p>
									</li>
									<li>
										<p>
											In cazul in care Clientul a returnat un produs, iar S.C.
											BIOMASER SANCOS S.R.L. nu dispune de un produs identic
											pentru inlocuire, aceasta va oferi clientului
											contravaloarea produsului.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Politică de returnare</p>
						<p>
							Informațiile din acest capitol sunt valabile numai pentru
							Consumatori (persoane fizice). În cazul persoanelor juridice,
							Furnizorul nu are obligații legale de returnare a produselor.
							Totuși, în cazul unor probleme legate de produsele
							achiziționate, nu ezitați să ne contactați și vom încerca să
							acționăm la fel ca în cazul persoanelor fizice, dacă este
							posibil.
						</p>
						<p>
							Clientul S.C. BIOMASER SANCOS S.R.L. poate returna produsele
							achizitionate in urmatoarele situatii:
						</p>
						{/* <ul style={{ listStyleType: 'disc' }}>
							<li> */}
						<ul style={{ listStyleType: 'disc' }}>
							<li>
								<p>
									Produsul care nu este conform cu specificatiile de pe site
								</p>
							</li>
							<li>
								<p>Coletele ce prezinta deteriorari severe</p>
							</li>
							<li>
								<p>Produsele ce au fost livrate gresit</p>
							</li>
						</ul>
						{/* </li> */}
						<p>
							Consumatorul are dreptul de a denunta unilateral contractul la
							distanta, fara penalitati si fara invocarea unui motiv, in
							termen de 14 zile calendaristice de la primirea produsului.
						</p>
						<p>
							Singurele costuri care pot cadea in sarcina consumatorului
							sunt cheltuielile directe de returnare a produselor, conform
							Ordonantei de urgenta a Guvernului nr. 34/2014, pentru
							persoanele fizice ce achizitioneaza produse din site-uri,
							folosind tehnicile de comunicare la distanta.
						</p>
						<p>
							Consumatorul va suporta costul direct al returnării
							produselor.
						</p>
						<p>
							Consumatorul este responsabil doar pentru diminuarea valorii
							produselor care rezultă din manipulări, altele decât cele
							necesare pentru determinarea naturii, calităților și
							funcționării produselor. Furnizorul poate percepe costuri
							suplimentare pentru returnarea produselor uzate, deteriorate,
							fără ambalaj, etc., pentru readucerea lor la starea inițială,
							care pot avea o valoare între 0 si 80% din valoarea inițială a
							produsului, în funcţie de gradul de afectare al formei
							iniţiale a coletului şi/sau produsului.
						</p>
						<p>
							De ținut cont atunci când se returnează produsele comandate:
						</p>

						<ul style={{ listStyleType: 'disc' }}>
							<li>
								<p>
									Produsele se returnează în forma originală, fără a fi
									deteriorate, cu etichetele intacte, fără a prezenta urme
									vizibile de folosire;
								</p>
							</li>
							<li>
								<p>
									În situația în care produsele au un sigiliu, acesta nu are
									voie să fie desfacut - produsul trebuie returnat sigilat;
								</p>
							</li>
							<li>
								<p>
									Aparatele sau orice alte produse care conțin accesorii, se
									returnează în aceeași stare în care au fost recepționate,
									trebuie să fie complete și să conțină toate accesoriile cu
									care au fost livrate, cu etichetele intacte și documentele
									care le-au însoțit;
								</p>
							</li>
						</ul>
						<p>Excepții de la returnare:</p>
						<ul style={{ listStyleType: 'disc', marginBottom: '1rem' }}>
							<li>produsele achiziționate de persoane juridice;</li>
							<li>
								produsele care prezintă modificări fizice, lovituri,
								ciobiri, zgârieturi, şocuri etc.;
							</li>
							<li>
								produsele aduse pe comandă specială (care nu sunt în mod
								uzual în stoc);
							</li>
							<li>produsele consumabile</li>
							<li>
								produse ce contin accesorii de tip consumabile care au fost
								desigilate si folosite;
							</li>
							<li>
								produse ce necesita o înregistrare pe site-ul producătorului
								înainte de a fi utilizate.
							</li>
						</ul>
						<p>
							Clientul este obligat sa notifice S.C. BIOMASER SANCOS S.R.L.
							intentia sa de a returna produsele achizitionate, prin orice
							mijloc de comunicare (telefonic {footerLabels.contactPhone}, sau email:
							{footerLabels.contactEmail}), in termen de maximum 14 zile
							calendaristice de la primirea produselor.
						</p>
						<p>
							In cazul rambursarii contravalorii produsului, aceasta se va
							face in cel mult 14 de zile calendaristice de la data
							receptionarii produselor, prin virament bancar.
						</p>
						<p>
							Contravaloarea serviciilor suplimentare incluzand dar
							nelimitandu-se la transportul produselor, achitata de catre
							client, nu se ramburseaza.
						</p>
						<p>
							In toate cazurile, cheltuielile de returnare/re-expediere vor
							fi suportate de catre client.
						</p>
						<p>
							* Clientul se obligă să returneze produsele într-o stare
							impecabilă, nefolosite/netestate, cu sigiliul si ambalajul
							original, respectiv într-o stare în care produsele pot fi
							comercializate din nou. Produsele vor fi returnate împreună cu
							accesoriile/cadourile gratuite, în ambalajul original la
							sediul S.C. BIOMASER SANCOS S.R.L. , Bld. București nr. 23,
							Baia Mare Jud. Maramures, România, cod postal 430251 (în
							incinta hotelului Eurohotel), contact: {footerLabels.contactPhone}, împreună cu
							formularul de retur completat si copia facturii în care ați
							marcat produsele pe care le returnați.
						</p>

						<p>
							Pentru returnarea produselor te rugam sa soliciți un formular
							de retur pe adresa noastră de email: {footerLabels.contactEmail}
						</p>
						<p>
							Acest formular trebuie să fie completat în întregime, și
							retransmis prin e-mail {footerLabels.contactEmail}), vă vom
							răspunde prin e-mail sau telefon în termen de 3 zile
							lucrătoare cu instrucțiunile de returnare.
						</p>
						<p>
							Dacă aveţi o problemă cu un produs comandat de la noi, care nu
							a fost rezolvată de către Departamentul Vanzări și respecta
							Politicile de Returnare aveți posibilitatea de a returna
							produsul. Primul pas este completarea acestui formular de
							returnare.
						</p>
						<p>
							Până în momentul în care Furnizorul recepționează produsul din
							partea companiei de curierat rapid, pentru efectuarea
							returului catre Consumator, produsul este în responsabilitatea
							Consumatorului.
						</p>
						<p>
							Returnarea produselor se va face folosind același serviciu de
							livrare cu care s-a facut expedierea, împreună cu formularul
							de retur completat si copia facturii în care ați marcat
							produsele pe care le returnați.
						</p>
					</div>

					<div className='col-12'>
						<p className='fw-bold'>Achiziție cursuri online și/ sau fizice</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={8}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>Părțile acordului</p>
										<p>
											PRESTATORUL, denumit generic „Sancos Academy” reprezentata
											prin firma partenera sau colaboratoare de mai jos:
										</p>
										<p>
											S.C. BIOMASER SANCOS S.R.L., CUI 39953215, J24/1501/2018
										</p>
										<p>şi</p>
										<p>
											BENEFICIARUL, persoana fizica sau juridica care
											achiziţioneaza online cursuri puse la dispozitie de catre
											PRESTATOR,
										</p>
										<p>
											denumite în continuare în mod colectiv “Părțile” și în mod
											individual “Partea” având în vedere că :
										</p>
										<ul style={{ listStyleType: 'disc', marginBottom: '1rem' }}>
											<li>
												PRESTATORUL pune la dispoziţie BENEFICIARULUI cursuri de
												formare;
											</li>
											<li>BENEFICIARUL doreşte achiziţionarea cursurilor</li>
										</ul>
										<p>
											au convenit să încheie prezentul acord în următoarele
											condiţii:
										</p>
									</li>
									<li>
										<p>Obiectul acordului</p>
										<p>
											Obiectul acordului îl reprezintă vânzarea de către
											PRESTATOR a cursurilor prezentate pe platforma online
											“www.sancosacademy.com” BENEFICIARULUI. Beneficiarul intra
											in posesie:
										</p>
										<ul style={{ listStyleType: 'disc', marginBottom: '1rem' }}>
											<li>
												pentru curs on line in 24 ore sau dupa confirmarea
												platii conform extrasului de cont.
											</li>
											<li>
												pentru curs fizic – la data stabilita conform
												programului de curs, afisat pe www.sancosacademy.com
											</li>
										</ul>
									</li>
									<li>
										<p>Durata acordului</p>
										<p>
											Prezentul acord intră în vigoare din momentul
											achiziţionării de către BENEFICIAR a cursului
											(cursuruilor) pus(e) la dispoziţie de către PRESTATOR şi
											încetează, în funcție de natura acestuia: la data
											absolvirii cursului și/ sau după 30 de zile de la intrarea
											cursului în posesia beneficiarului.
										</p>
									</li>
									<li>
										<p>Prețul acordului și modalități de plată</p>
										<ul style={{ listStyleType: 'disc', marginBottom: '1rem' }}>
											<li>
												<p>
													Prețurile cursurilor vor fi cele comunicate pe
													paginile de vânzare individuale pentru fiecare curs în
													parte.
												</p>
											</li>
											<li>
												<p>
													Plata se va putea face online, cu cardul, prin
													transfer bancar, ordin de plată sau cu bani lichizi și
													se achită un avans (pentru cursurile cu prezenta
													fizica) în momentul plasării comenzii.
												</p>
											</li>
											<li>
												<p>
													Pentru cursurile cu preturi exprimate in euro sau alte
													monede, se va achita contravaloarea sumelor respective
													in lei, la cursul valutar al bancii care proceseaza
													plata +1%.
												</p>
											</li>
											<li>
												<p>
													Factura fiscală se va emite la data debitării sumei în
													contul PRESTATORULUI (conform extrasului de cont) sau
													după confirmarea plății în contul BENEFICIARULUI
													(conform extrasului de cont).
												</p>
											</li>
											<li>
												<p>
													Toate sumele achitate in avans pentru cursuri ce se
													vor desfasura la o data ulterioara sunt nereturnabile.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Drepturile și obligațiile părților</p>
										<p>PRESTATORUL se obligă:</p>
										<ul style={{ listStyleType: 'disc', marginBottom: '1rem' }}>
											<li>
												<p>
													Să pună la dispoziţia BENEFICIARULUI cursuri, așa cum
													sunt ele prezentate pe paginile de vânzare ale
													curusurilor individuale.
												</p>
											</li>
											<li>
												<p>
													Să îi ofere BENEFICIARULUI suport, sub forma unui grup
													privat de curs sau individual.
												</p>
											</li>
											<li>
												<p>
													PRESTATORUL, angajaţii ori interpuşii acestuia vor
													ajuta cu răspunsuri și informații utile BENEFICIARUL.
												</p>
											</li>
											<li>
												<p>
													Să ofere serviciile solicitate în cele mai bune
													condiții şi la termenele comunicate în prealabil
													BENEFICIARULUI şi stabilite în mod unilateral de către
													PRESTATOR.
												</p>
											</li>
											<li>
												<p>
													Să verifice prezența BENEFICIARULUI la programul de
													instruire .
												</p>
											</li>
										</ul>
										<p>BENEFICIARUL se obligă să:</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													Să achite prețul convenit pentru serviciile
													contractate, la termenele prevăzute în prezentul
													acord. In cazul in care Beneficiarul nu onoreaza
													termenele de plata comunicate de PRESTATOR, acesta din
													urma este absolvit de obligația acordării serviciilor
													de instruire online sau de livrarea produselor
													comandate.
												</p>
											</li>
											<li>
												<p>Să participe la toate orele de instruire.</p>
											</li>
											<li>
												<p>
													Să obligă să folosească întreaga competenţă
													profesională şi experienţă în domeniu.
												</p>
											</li>
											<li>
												<p>
													Să respecte întocmai, integral şi la timp toate
													task-urile pe care PRESTATORUL, angajaţii ori
													interpuşii acestuia i le dau pe toată perioada
													cursului.
												</p>
											</li>
											<li>
												<p>
													Să respecte întocmai regulile de comunicare pe orice
													platforma/ cale pentru suport stabilite de PRESTATOR
													și să nu foloseasca un limbaj nepotrivit sau licențios
													la adresa PRESTATORULUI sau a oricărui alt participant
													în cadrul cursului.
												</p>
											</li>
											<li>
												<p>
													Să nu pună la dispoziţia nici unui terţ, indiferent de
													metodă ori suport, cu titlu gratuit sau oneros,
													cursurile puse la dispoziţia sa de către PRESTATOR.
												</p>
											</li>
											<li>
												<p>
													Îşi dă acordul pentru ca PRESTATORUL să folosească
													numele și imaginea sa, precum şi orice altă dată,
													informaţie, valoare ori statistică care conţine
													persoana BENEFICIARULUI, activitatea ori rezultatul
													său în urma cursului, pentru promovarea și
													publicitatea Sancos Academy, în ceea ce priveste
													comunicările sale publice şi în campaniile de
													marketing ce promovează participarea la curs în orice
													formă (inclusiv site-ul, social media TV etc.), fără
													ca PRESTATORUL să fie ţinut la vreun cost faţă de
													BENEFICIAR.
												</p>
											</li>
											<li>
												<p>
													Îşi dă expres acordul pentru filmarea şi fotografierea
													sa în cadrul cursurilor ce fac obiectul prezentului
													contract, ori ulterior absolvirii cursului dar în
													strânsă legătură cu acesta, precum şi pentru
													utilizarea imaginilor în cadrul materialelor video
													care se vor realiza de către PRESTATOR
												</p>
											</li>
											<li>
												<p>
													Îşi dă expres acordul ca materialele video menționate,
													drepturile de imagine și orice materiale video
													realizate de către PRESTATOR în legătură cu cursul ce
													face obiectul prezentului contract să fie cesionate
													PRESTATORULUI cu titlu exclusiv, pentru toate
													teritoriile lumii, PRESTATORUL având, incluzând fără
													limitare dreptul exclusiv pentru: utilizarea,
													afișarea, expunerea, comercializarea, publicarea,
													dreptul de a reproduce integral sau parţial
													materialele, promovarea și distribuirea în orice
													format (inclusiv, fără limitare, televiziune, video,
													audio, internet, dispozitive și sisteme de comunicații
													mobile și electronice) şi în orice modalitate
													considerată oportună de către PRESTATOR.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Răspundere</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													Fiecare dintre părţi este răspunzătoare pentru
													îndeplinirea întocmai, integrală şi la timp a tuturor
													obligaţiilor asumate prin prezentul acord.
												</p>
											</li>
											<li>
												<p>
													Părţile stabilesc de comun ca în cazul în care
													BENEFICIARUL pune la dispoziţia unui terţ cursul (8.5
													Drepturile și obligațiile părților) acesta va fi ţinut
													la plata către PRESTATOR a sumei de 30 000
													EUR/obligaţie încălcată cu titlu de daune interese.
												</p>
											</li>
											<li>
												<p>
													Părţile stabilesc de comun ca în cazul în care
													BENEFICIARUL încalcă oricare dintre obligaţiile
													privitoare la clauza de confidenţialitate, acesta va
													fi ţinut la plata către PRESTATOR a sumei de 30 000
													EUR/obligaţie încălcată, cu titlu de daune interese.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Clauze de confidențialitate</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													BENEFICIARUL se obligă să păstreze confidenţialitatea
													faţă de terţi, să nu dezvăluie sub nicio formă şi prin
													niciun mijloc, să nu utilizeze în nicio modalitate,
													fie direct, fie indirect, fie în folos propriu, fie în
													folosul altuia, niciun fel de materiale, acte, date,
													informaţii rezultate din/în legătură cu clauzele
													prezentului contract sau altor aspecte confidenţiale
													aparţinând PRESTATORULUI, de care BENEFICIARUL a luat
													la cunoştinţă în orice mod pe durata contractului.
												</p>
											</li>
											<li>
												<p>
													Obligaţia de confidenţialitate, astfel cum este
													prevăzută mai sus, se menţine în sarcina
													BENEFICIARULUI timp de 10 (zece) ani de la data
													încetării prezentului contract. Prin informatii
													confidentiale se intelege orice informatie apartinand
													PRESTATORULUI sau terilor cu care PRESTATORUL are
													relatii de afaceri sau privind activitatea acestora si
													care este transmisa de catre PRESTATOR in executarea
													Contractului, precum si orice imprejurare relevanta
													legata de Beneficiar (inclusiv, dar fara a se limita
													la cele prevazute în acest document), indiferent daca
													aceasta informatie a fost transmisa de catre PRESTATOR
													sau terti, primita de catre PRESTATOR in forma scrisa,
													orala sau in orice alt mod, si care se refera inclusiv
													dar nelimitat la:
												</p>
												<ul
													style={{
														listStyle: 'lower-alpha',
														marginBottom: '1rem',
													}}
												>
													<li>
														Planurile de afaceri, de marketing si financiare ale
														PRESTATORULUI, precum si ale clientilor acestuia
														si/sau ale tertilor cu care are relatii de afaceri
														sau cu care intra in contact prin natura activitatii
														desfasurate;
													</li>
													<li>
														Sistemul organizatoric, informational si de
														management al PRESTATORULUI;
													</li>
													<li>
														Informatii referitoare la numele clientilor si
														furnizorilor PRESTATORULUI, precum si orice alte
														informatii referitoare la datele tehnice,
														financiare, comerciale, indiferent daca in
														documentele respective figureaza sau nu cuvintele
														“confidential” ori “proprietate exclusiva”.
													</li>
												</ul>
											</li>
											<li>
												<p>
													BENEFICIARUL declara si inteleg ca vor folosi
													informatiile confidentiale care le parvin pe parcursul
													executarii prezentului Contract numai in interesul si
													in scopul indeplinirii obligatiilor sale contractuale
													si se obliga sa nu le transmita tertilor, indiferent
													de motiv si indiferent de forma in care ar putea fi
													transmise.
												</p>
											</li>
											<li>
												<p>
													În cazul în care se dovedește că au încălcat obligația
													de confidențialitate, vor datora Beneficiarului
													penalități egale cu prejudiciul cauzat.
												</p>
											</li>
											<li>
												<p>
													Orice declaraţii sau interviuri, precum şi dezvăluirea
													oricăror informaţii către media în legatură cu cursul
													se poate face de BENEFICIAR numai cu acordul prealabil
													al PRESTATORULUI.
												</p>
											</li>
											<li>
												<p>
													Toate drepturile ce decurg din dreptul de proprietate
													intelectuală asupra programului, modelelor de curs,
													precum și a oricărui material folosit de formatorii
													incluși în programul de formare, în timpul procesului
													de formare sunt proprietatea exclusivă a PRESTATORULUI
													şi sunt protejate de legislația în domeniul
													proprietății intelectuale.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Clauza de neconcurență</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													BENEFICIARUL garantează şi răspunde că nu va intra, în
													mod direct sau indirect, în concurenţă cu activitatea
													PRESTATORULUI și nici nu va desfășura, în mod direct
													sau indirect, el însuşi, angajaţi ori interpuşi ai
													acestuia, cu titlu gratuit sau cu titlu oneros, alte
													cursuri care au obiect identic sau similiar cu cel pus
													la dispoziţia sa de către PRESTATOR.
												</p>
											</li>
											<li>
												<p>
													În masura în care această obligație este încălcată,
													BENEFICIARUL va fi ținut la plata daunelor interese
													către PRESTATOR, al căror cuantum este de minim 30.000
													euro. În masura in care prejudiciul cauzat este unul
													mai ridicat, PRESTATORUL va fi indreptatit la
													repararea integrala a prejudiciului.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Încetarea</p>
										<p>Prezentul Contract încetează în următoarele cazuri:</p>
										<p>&mdash; prin ajungerea la termen;</p>
										<p>&mdash; prin acordul scris al ambelor parti;</p>
										<p>&mdash; prin rezilierea contractului.</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													Părtile au dreptul de a considera contractul ca fiind
													reziliat de plin drept, fără cerere de chemare în
													judecată şi fără nicio altă formalitate prealabilă, în
													cazul în care partea în culpă încalcă oricare dintre
													obligațiile din prezentul contract considerate de
													catre parţi esenţiale şi nu remediază această situaţie
													în termen de maxim 3 zile de la primirea notificării
													în acest sens de la cealalata parte (notificarea
													putând prevedea şi un termen mai scurt, în functie de
													circumstanţe).
												</p>
											</li>
											<li>
												<p>
													În cazul neachitării la timp a serviciilor
													contractate, Prestatorul este absolvit de obligația
													acordării serviciilor de instruire online sau fizice
													sau de livrarea produselor comandate.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Politica de anulare</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													În baza legislației UE, la achiziția unui produs sau
													seriviu online, aveți dreptul de a anula comanda în
													termen de 14 zile, indiferent de motiv și fără nicio
													justificare.
												</p>
											</li>
											<li>
												<p>
													În cazul retragerii din program a Beneficiarului,
													acestuia nu i se restituie nici o suma din prețul
													achitat.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Forță majoră</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													Niciuna dintre Părţi nu răspunde de neexecutarea la
													termen sau/și de executarea în mod necorespunzător –
													total sau parțial – a oricărei obligatii care îi
													revine în baza prezentului contract, dacă neexecutarea
													sau executarea necorespunzătoare a obligaţiei
													respective a fost cauzată de forţa majoră, aşa cum
													este definită de lege.
												</p>
											</li>
											<li>
												<p>
													Partea care invocă forţa majoră este obligată să
													notifice celeilalte părţi, în termen de 5 (cinci) zile
													de producerea evenimentului şi să ia toate masurile
													posibile în vederea limitării consecinţelor lui.
												</p>
											</li>
											<li>
												<p>
													Dacă în termen de 10 (zece) de zile de la producere
													evenimentul respectiv nu încetează, părţile au dreptul
													să-şi notifice încetarea de plin drept a prezentului
													contact fără ca vreuna dintre ele să pretindă
													daune-interese.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Alte clauze</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													BENEFICIARUL nu va fi îndreptătit să cesioneze şi/sau
													să transfere drepturile şi/sau obligaţiile rezultând
													sau derivând din curs către un terţ fără
													consimţământul scris şi prealabil al PRESTATORULUI.
												</p>
											</li>
											<li>
												<p>
													Părţile înţeleg şi acceptă ca toate sumele primite din
													parteneriate, promovari şi/sau publicitate în legătură
													cu cursul ce face obiectul prezentelor termeni şi
													condiţii revin exclusiv PRESTATORULUI.
												</p>
											</li>
											<li>
												<p>
													În cazul neachitării în mod integral, exact şi la timp
													a serviciilor contractate, PRESTATORUL este absolvit
													de obligația acordării serviciilor de instruire.
												</p>
											</li>
											<li>
												<p>
													Părţile înţeleg şi declară expres că, în situaţia în
													care BENEFICIARUL nu participă la toate orele sau nu
													îşi îndeplineşte în mod exact, integral şi la timp
													toate task-urile şi misiunile oferite lui de către
													PRESTATOR, absolvirea cursului sau participarea la
													examenul final, în cazul în care aceasta există, pot
													fi întârziate sau anulate.
												</p>
											</li>
											<li>
												<p>
													Părţile înţeleg ca în cazul retragerii din program a
													BENEFICIARULUI, acestuia nu i se restituie nici o suma
													din prețul achitat.
												</p>
											</li>
											<li>
												<p>
													Întârzierea în plata mai mult de 2 zile da dreptul
													PRESTATORULUI de a întrerupe accesul la serviciile
													furnizate până la achitarea sumelor cuvenite.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Documentele acordului</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>Prezentele termeni şi condiţii</p>
											</li>
											<li>
												<p>
													Orice altă informaţie de pe wwww.sancosacademy.com
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Litigii</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													Toate litigiile şi disputele referitoare la prezentul
													contract vor fi soluţionate pe cale amiabilă.
												</p>
											</li>
											<li>
												<p>
													În cazul în care părţile nu reuşesc pe cale amiabilă,
													orice litigiu decurgând din sau în legătură cu
													prezentul contract, inclusiv referitor la încheierea,
													nulitatea, interpretarea, executarea sau desființarea
													acestuia, va fi soluționat definitiv de către
													instanțele de judecată de la sediul Beneficiarului.
												</p>
											</li>
										</ul>
									</li>
									<li>
										<p>Clauze finale</p>
										<ul style={{ listStyleType: 'disc' }}>
											<li>
												<p>
													Părţile declară că înteleg deplin efectele clauzelor
													prezentelor termeni şi condiţii şi că aceste clauze
													reflectă voinţa comună şi neîngradită a părţilor.
												</p>
											</li>
											<li>
												<p>
													Orice comunicare dintre părţi referitoare la
													prezentele termeni şi condiţii poate fi transmisă prin
													e-mail sau scrisoare, potrivit dispozitiilor legale în
													vigoare.
												</p>
											</li>
											<li>
												<p>
													Modificarea prezentelelor termeni şi condiţii poate fi
													făcută de către S.C. BIOMASER SANCOS S.R.L. fără
													acordul scris al părţilor contractante.
												</p>
											</li>
											<li>
												<p>
													Clauzele prezentelor termeni şi condiţii se
													completează cu dispozițiile legale în vigoare.
												</p>
											</li>
											<li>
												<p>
													Prezentele termeni şi condiţii se supun dispoziţiilor
													legii române.
												</p>
											</li>
											<li>
												<p>
													Prezentelor termeni şi condiţii nu aduce atingere nici
													unui alt contract încheiat între Părţi.
												</p>
											</li>
										</ul>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Limitare si responsabilitati</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={9}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. nu poate fi tinuta
											responsabila pentru orice fel de prejudiciu (direct,
											indirect, accidental sau nu, etc) ce rezulta din folosirea
											sau din incapacitatea de folosire a informatiei de tip
											continut prezentata sau nu pe site, sau pentru orice tip
											de erori sau omisiuni in prezentarea continutului care pot
											conduce la orice fel pierderi.
										</p>
									</li>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. nu garanteaza utilizatorului
											sau clientului acces pe site sau la serviciu si nu ii
											confera dreptul de a descarca sau de a modifica partial
											si/sau integral continutul, de a reproduce partial si/sau
											integral continutul, de a copia, sau de a exploata orice
											continut in orice alta maniera, sau de a transfera vreunui
											tert orice continut asupra caruia are si/sau a obtinut
											acces, in baza unui acord de utilizare, fara acordul
											prealabil scris al S.C. BIOMASER SANCOS S.R.L.
										</p>
									</li>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. nu raspunde de continutul,
											calitatea sau natura altor site-uri la care se ajunge prin
											legaturi din continut, indiferent de natura acestor
											legaturi. Pentru respectivele site-uri, raspunderea o
											poarta integral proprietarii acestora.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Forta majora si cazul fortuit</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={10}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Exceptand cazurile in care nu au prevazut in mod expres
											altfel, nici una din partile unui contract incheiat, care
											este inca in derulare, nu va fi raspunzatoare pentru
											neexecutarea la termen si/sau in mod corespunzator, total
											sau partial, a oricareia din obligatiile care ii incumba
											in baza contractului, daca neexecutarea obligatiei
											respective a fost cauzata de un eveniment de forta majora.
										</p>
									</li>
									<li>
										<p>
											Partea sau reprezentantul legal al partii care invoca
											evenimentul mai sus mentionat este exonerata de la aceasta
											obligatie numai in cazul in care evenimentul o impiedica
											sa o duca la bun sfarsit.
										</p>
									</li>
									<li>
										<p>
											Partea sau reprezentantul legal al partii care invoca
											evenimentul mai sus mentionat este obligat sa aduca la
											cunostinta celeilalte parti, imediat si in mod complet,
											producerea acestuia si sa ia orice masuri care-i stau la
											dispozitie in vederea limitarii consecintelor
											respectivului eveniment.
										</p>
									</li>
									<li>
										<p>
											Daca in termen de 15 zile de la data producerii lui,
											respectivul eveniment nu inceteaza, fiecare parte va avea
											dreptul sa notifice celeilalte parti incetarea de plin
											drept a acestui contract fara ca vreuna dintre ele sa
											poata pretinde celeilalte parti daune-interese.
										</p>
									</li>
									<li>
										<p>
											Partea care invoca evenimentul de forta majora trebuie sa
											faca dovada imposibilitatii executarii obligatiilor in
											termen de maxim 30 zile de la data producerii
											evenimentului.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Litigii si drept aplicabil</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={11}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											Prin folosirea/vizitarea/vizualizarea/etc. site-urilor
											si/sau al oricarui continut trimis de catre S.C. BIOMASER
											SANCOS S.R.L. Utilizatorului/Clientului, prin accesare
											si/sau expediere prin orice mijloc , acesta se declara de
											acord cel putin cu prezentele „Termeni si conditii”.
										</p>
									</li>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. nu raspunde de nici o
											pierdere, costuri, procese, pretentii, cheltuieli sau alte
											raspunderi, in cazul in care acestea sunt cauzate direct
											de nerespectarea prezentelor „Termeni si conditii”.
										</p>
									</li>
									<li>
										<p>
											Daca oricare dintre clauzele de mai sus va fi gasita nula
											sau nevalida, indiferent de cauza, aceasta clauza nu va
											afecta valabilitatea celorlalte clauze.
										</p>
									</li>
									<li>
										<p>
											Prezentul document a fost redactat si va fi interpretat in
											conformitate cu legislatia romana.
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12'>
						<p className='fw-bold'>Dispozitii finale</p>
						<ol style={{ listStyleType: 'none', paddingLeft: 0 }} start={12}>
							<li>
								<ol style={{ listStyle: 'initial' }}>
									<li>
										<p>
											S.C. BIOMASER SANCOS S.R.L. isi rezerva dreptul de a
											introduce bannere publicitare de orice natura si/sau
											legaturi pe oricare pagina a site-ului, cu respectarea
											legislatiei in vigoare.
										</p>
									</li>
									<li>
										<p>
											Orice alta problema cauzata de produsele si serviciile
											prezentate pe www.sancosacademy.com si care nu este deja
											tratata de nici un articol din prezentul document, se va
											solutiona pe cale amiabila in termen de maxim 30 de zile
											lucratoare de la data sesizarii in scris a problemelor, de
											catre utilizator.
										</p>
									</li>
									<li>
										<p>
											In cazul in care nu s-a reusit stingerea conflictului pe
											cale amiabila, competenta revine instantelor de judecata
											de la sediul proprietarului site-ului, in speta de la
											sediul S.C. BIOMASER SANCOS S.R.L. sau unui organism de
											rezolvare a disputelor alternative (prin procedura
											medierii), agreat de ambele parti.
										</p>
									</li>
									<li>
										<p>
											Fiind de acord cu acesti „Termeni si conditii”, clientul
											isi asuma in totalitate consecintele ce decurg din
											folosirea in aceste conditii a prezentului
										</p>
									</li>
								</ol>
							</li>
						</ol>
					</div>
					<div className='col-12 mt-3'>
						<p className='fw-bold'>POLITICA PRIVIND PROCESAREA DATELOR PERSONALE ÎN CONFORMITATE CU ART. 13-14 din Regulamentul UE 2016/679</p>
						<p>Vă informăm că vom procesa datele dvs. personale în conformitate cu principiile corectitudinii, legalității, transparenței și protecției vieții private și a drepturilor dumneavoastră și vă informăm despre următoarele:</p>
						<ul style={{ listStyle: 'initial' }}>
							<li>
								<p>
									SC BIOMASER SANCOS SRL, CUI 39953215, Sediu Social: str. Granicerilor 102/49, Baia Mare, Jud Maramures, Cod 430373, e-mail: {footerLabels.contactEmail}, în calitate de operator de date cu caracter personal
								</p>
							</li>
						</ul>
						<p>Scopul tratamentului</p>
						<p>Tratamentul se desfășoară în următoarele scopuri:</p>
						<ul style={{ listStyle: 'initial' }}>
							<li>
								<p>
									executarea corectă și completă a contractului și obligațiile aferente;
								</p>
							</li>
							<li>
								<p>
									îndeplinirea obligațiilor legale impuse de legislația în vigoare;
								</p>
							</li>
							<li>
								<p>
									îndeplinirea obligațiilor fiscale și fiscale;
								</p>
							</li>
							<li>
								<p>
									activitatea de marketing;
								</p>
							</li>
							<li>
								<p>
									satisfacția clienților;
								</p>
							</li>
							<li>
								<p>
									activitatea de informare a clientului
								</p>
							</li>
						</ul>
						<p>
							Temeiul juridic al prelucrării
						</p>
						<p>
							Baza legală a acestor tratamente este realizarea serviciilor inerente relației contractuale stabilite, respectarea obligațiilor legale și interesului legitim pentru efectuarea tratamentelor necesare în aceste scopuri.
						</p>
						<p>
							Către cine sunt comunicate datele
						</p>
						<p>
							Datele pot fi comunicate:
						</p>
						<ul style={{ listStyle: 'initial' }}>
							<li>
								<p>
									Furnizorilor terți ai companiei noastre;
								</p>
							</li>

							<li>
								<p>
									clienților companiei noastre;
								</p>
							</li>

							<li>
								<p>
									instituțiile bancare, autoritățile fiscale, organismele responsabile cu controlul obligațiilor fiscale, avocații și organele de justiție;
								</p>
							</li>

							<li>
								<p>
									furnizorii de servicii;

								</p>
							</li>
							<li>
								<p>
									autorități și / sau organisme publice;
								</p>
							</li>
						</ul>
						<p>
							Comunicarea și transferul datelor pot avea loc și împotriva unuia sau a mai multor subiecți care operează în afara teritoriului național și, de asemenea, în afara teritoriului Uniunii Europene.
						</p>

						<p>
							Consecințele eșecului de a comunica datele
						</p>

						<p>
							Rețineți că, luând în considerare scopurile tratamentului descris mai sus, furnizarea de date este obligatorie, iar neîndeplinirea acestora, acordarea parțială sau incorectă poate avea drept consecință imposibilitatea de a efectua serviciile contractuale.
						</p>

						<p>
							În cazul în care persoana în cauză este sub 16 ani, tratamentul trebuie să fie autorizat de titularul răspunderii părintești, pentru care se vor obține datele de identificare și copia documentelor de identificare.
						</p>

						<p>
							Metodă de tratament
						</p>

						<p>
							Tratamentul va fi efectuat atât cu instrumente manuale la calculator și organizarea de prelucrare a logicii electronice și în strânsă legătură cu scopurile menționate mai sus și, în orice caz, în așa fel încât să se asigure securitatea, integritatea și confidențialitatea datelor în conformitate cu măsurile organizatorice, fizice și logica prevăzută de dispozițiile în vigoare.
						</p>

						<p>
							Datele dvs. pot fi stocate fie pe hârtie, fie în arhive electronice.
						</p>

						<p>
							Datele dvs. nu sunt supuse dezvăluirii sau procesului de luare a deciziilor complet automatizate, inclusiv a profilării.
						</p>

						<p>
							Reținerea datelor
						</p>

						<p>
							Datele cu caracter personal sunt păstrate pentru întreaga durată a contractului și timp de zece ani de la emiterea ultimei facturi, cu excepția cazului în care legea prevede altfel.
						</p>

						<p>
							Exercitarea drepturilor tale
						</p>

						<p>
							Puteți să scrieți în orice moment proprietarului și administratorului de date să exercite drepturile:
						</p>

						<ul style={{ listStyle: 'initial' }}>
							<li>
								<p>
									accesul la datele dvs. personale;
								</p>
							</li>

							<li>
								<p>
									pentru a obține corectarea datelor dvs. personale dacă este eronată;
								</p>
							</li>

							<li>
								<p>
									pentru a obține anularea datelor dvs. personale în cazurile menționate în GDPR;
								</p>
							</li>

							<li>
								<p>
									obtinerea limitarii procesarii in limitele menționate în GDPR;
								</p>
							</li>

							<li>
								<p>
									să utilizeze portabilitatea datelor în conformitate cu menționările din GDPR;
								</p>
							</li>

							<li>
								<p>
									să se opună tratamentului în cazurile prevăzute în GDPR;
								</p>
							</li>

							<li>
								<p>
									să nu fie supus procesării automatizate, conform prevederilor din GDPR;
								</p>
							</li>

							<li>
								<p>
									revocarea consimțământului, în cazul în care se prevede: revocarea consimțământului nu afectează legalitatea tratamentului pe baza consimțământului acordat înainte de revocare;
								</p>
							</li>

							<li>
								<p>
									să propună o plângere autorității de supraveghere (Garant de confidențialitate).
								</p>
							</li>
						</ul>
						<p>
							Informații actualizate la data de 18 Noiembrie 2022
						</p>

						<p>
							Titularul
						</p>

						<p>
							Sancos Academy
						</p>

						<p>
							Politica de utilizare cookie-uri
						</p>

						<p>
							Site-ul https://sancosacademy.com utilizeaza cookie-uri pentru a oferi vizitatorilor sai o experienta mai buna de navigare si servicii adaptate intereselor fiecarui vizitator. Va prezentam mai jos informatiile necesare pentru a va aduce la cunostinta detaliile legate de plasarea, utilizarea si administrarea cookie-urilor utilizate de site-ul https://sancosacademy.com.
						</p>

						<p>
							Similar, in mediul online, exista si alte site-uri, pagini personalizate inclusiv pe platforme ale tertelor parti (cum ar fi Facebook sau YouTube) si aplicatiilor accesate sau utilizate prin asemenea site-uri care sunt operate de sau in numele Sancos Academy si care folosesc cookie-uri conform celor prezentate mai jos.
						</p>

						<p>
							Aplicabilitatea acestei Politici
						</p>

						<p>
							Aceasta Politica se aplica site-ului sancosacademy.com
						</p>

						<p>
							De asemenea, pentru identitate, informatiile din acest document se aplica si altor platforme online care sunt operate de sau in numele Sancos Academy.
						</p>

						<p>
							Utilizand acest site, sunteti de acord cu modul de utilizare de catre noi a cookie-urilor, in conformitate cu aceasta Politica privind utilizarea lor.
						</p>

						<p>
							Daca nu sunteti de acord cu modul nostru de utilizare a cookie-urilor, trebuie sa setati corespunzator setarile browser-ului dumneavoastra.
						</p>

						<p>
							Decuplarea cookie-urilor pe care le utilizam ar putea afecta experienta dumneavoastra ca utilizator pe acest site.
						</p>

						<p>
							Ce sunt cookie-urile?
						</p>

						<p>
							Cookie-urile sunt fisiere de mici dimensiuni, formate din litere si numere, care vor fi stocate pe computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se acceseaza internetul.
						</p>

						<p>
							Cookie-urile sunt instalate prin solicitarea emisa de catre un web-server, unui browser (ex: Internet Explorer, Chrome).
						</p>

						<p>
							Cookie-urile sunt complet “pasive” (nu contin programe software, virusi sau spyware si nu pot accesa informatiile de pe hard driverul utilizatorului).
						</p>

						<p>
							Un cookie este format din 2 parti: numele si continutul sau valoarea cookie-ului. Durata de existenta a unui cookie este determinata; tehnic, doar webserverul care a trimis cookie-ul il poate accesa din nou in momentul in care un utilizator se intoarce pe website-ul asociat web-serverului respectiv.
						</p>

						<p>
							Cookie-urile in sine nu solicita informatii cu caracter personal pentru a putea fi utilizate si, in cele mai multe cazuri, nu identifica personal utilizatorii de internet.
						</p>

						<p>
							Exista 2 categorii mari de cookie-uri:
						</p>

						<ul style={{ listStyle: 'initial' }}>
							<li>
								<p>
									cookie-uri de sesiune: sunt stocate temporar in dosarul de cookie-uri al browserului web pentru ca acesta sa le memoreze pana cand utilizatorul iese de pe web-siteul respectiv sau inchide fereastra browserului;
								</p>
							</li>
							<li>
								<p>
									cookie-uri persistente: sunt stocate pe hard-drive-ul unui computer sau echipament si sunt activate din nou cand vizitati site-ul care a creat acel cookie specific. Cookie-urile persistente le includ si pe cele plasate de un alt website decat cel pe care il viziteaza utilizatorul la momentul respectiv. Acestea sunt cunoscute sub numele de “third party cookies” respectiv cookie-uri plasate de terti. Acestea memoreaza interesele unui utilizator pentru a livra publicitate cat mai relevanta pentru acesta.
								</p>
							</li>
						</ul>

						<p>
							Care este durata de viata a unui cookie?
						</p>

						<p>
							Durata de viata a unui cookie poate varia semnificativ, in functie de scopul pentru care acesta este plasat. Unele cookie-uri sunt menite unei singure utilizari (cum este cazul cookie-urilor de sesiune), prin urmare nu sunt mentinute odata ce utilizatorul paraseste site-ul. Alte cookie-uri sunt mentinute si reutilizate de fiecare data cand utilizatorul revine pe site (cookie-uri persistente). Totusi, cookie-urile pot fi sterse de utilizator in orice moment prin utilizarea setarilor browser-ului.
						</p>

						<p>
							Ce sunt cookie-urile plasate de terti?
						</p>

						<p>
							Unele sectiuni de continut ale unor site-uri pot fi furnizate prin cookie-uri ale tertilor cum este cazul unei reclame. Astfel de cookie-uri pot fi plasate pe site si sunt denumite cookie-uri plasate de terti intrucat nu sunt plasate de proprietarul site-ului. Astfel de terti sunt la randul lor obligati sa respecte reglementarile legale aplicabile si politicile de confidentialitate ale proprietarului site-ului respectiv.
						</p>

						<p>
							De ce sunt cookie-urile importante pentru internet?
						</p>

						<p>
							Cookie-urile reprezinta punctul central al functionarii eficiente a Internetului, ajutand la generarea unei experiente de navigare prietenoase si adaptata preferintelor si intereselor fiecarui utilizator. Refuzarea sau dezactivarea cookie-urilor poate face unele site-uri imposibil de folosit. Refuzarea sau dezactivarea cookie-urilor nu inseamna ca nu veti mai primi publicitate online – ci doar ca aceasta nu va mai putea tine cont de preferintele si interesele dvs, evidentiate prin comportamentul de navigare. Exemple de intrebuintari importante ale cookie-urilor (care nu necesita autentificarea unui utilizator prin intermediul unui cont):
						</p>

						<p>
							Continut si servicii adaptate preferintelor utilizatorului – categorii de produse si servicii;
						</p>
						<p>
							Oferte adaptate pe interesele utilizatorilor – retinerea parolelor;
						</p>
						<p>
							Retinerea filtrelor de protectie a copiilor privind continutul pe Internet (optiuni family mode, functii de safe search);
						</p>
						<p>
							Limitarea frecventei de difuzare a reclamelor – limitarea numarului de afisari a unei reclame pentru un anumit utilizator pe un site;
						</p>
						<p>
							Furnizarea de publicitate mai relevanta pentru utilizator;
						</p>
						<p>
							Masurarea, optimizarea si caracteristicile de Analytics – cum ar fi confirmarea unui anumit nivel de trafic pe un website, ce tip de continut este vizualizat si modul cum un utilizator ajunge pe un website (exemplu: prin motoare de cautare, direct, din alte website-uri etc). Website-urile deruleaza aceste analize ale utilizarii lor pentru a imbunatati site-urile in beneficiul user-ilor.
						</p>

						<p>
							Ce tipuri de cookie-uri folosim?
						</p>

						<p>
							Accesand acest site se pot plasa urmatoarele tipuri de cookie-uri:
						</p>

						<ul style={{ listStyle: 'initial' }}>
							<li>
								<p>

									de performanță a site-ului: acestea retin preferintele utilizatorului pe acest site;
								</p>
							</li>

							<li>
								<p>
									de analiză a vizitatorilor: acestea ne spun daca ati mai vizitat acest site pana acum; browserul ne va spune daca aveti acest cookie, iar daca nu, vom genera unul;
								</p>
							</li>

							<li>
								<p>
									pentru geotargetting: acestea sunt utilizate de catre un soft care stabileste din ce tara proveniti, sunt complet anonime si sunt folosite doar pentru targetarea continutului;
								</p>
							</li>

							<li>
								<p>
									de înregistrare: acestea ne anunta daca sunteti inregistrat sau nu, ne arata contul cu care sunteti inregistrat si daca aveti permisiunea pentru un anumit serviciu;
								</p>
							</li>

							<li>
								<p>
									pentru publicitate: pe baza acestora putem afla daca ati vizualizat sau nu o reclama online, ce tip de reclama si cat timp a trecut de cand ati vizualizat aceasta reclama.
								</p>
							</li>
						</ul>
						<p>
							Cookie-urile contin date personale?
						</p>

						<p>
							Cookie-urile in sine nu solicita informatii cu caracter personal pentru a putea fi utilizate si, in cele mai multe cazuri, nu identifica personal utilizatorii de internet. Datele personale pot fi colectate prin utilizarea Cookie-urilor doar pentru a facilita anumite functionalitati pentru utilizator.
						</p>

						<p>
							Cum puteti modifica setarile cookie din browser-ul dumneavoastra?
						</p>

						<p>
							Stergerea Cookie-urilor
						</p>

						<p>
							Aplicatiile folosite pentru accesarea paginilor web permit salvarea Cookie-urilor pe terminal in mod implicit. Aceste setari pot fi schimbate in asa fel incat administrarea automata a Cookie-urilor sa fie blocata de browser-ul web sau utilizatorul sa fie informat de fiecare data cand Cookie-uri sunt trimise catre terminalul sau. Informatii detaliate despre posibilitatile si modurile de administrare a Cookie-urilor pot fi gasite in zona de setari a aplicatiei (browser-ului web). Limitarea folosirii Cookie-urilor poate afecta anumite functionalitati ale paginii web.
						</p>

						<p>
							Majoritatea browserelor ofera posibilitatea de a schimba setarile cookie-urilor. Aceste setari se gasesc de regula in “optiuni” sau in meniul de “preferinte” al browserului tau.
						</p>
					</div>
				</div>
			</div>
		</div >
	);
};

export default TermsConditions;
