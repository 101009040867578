import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
	BillingAddress,
	Breadcrumb,
	OrderSummary,
	ShippingAddress,
} from '../../components/Checkout';
import { EmailVerificationAlertModal } from '../../components/EmailVerificationAlert';
import {
	proceedCheckout,
	selectCheckoutData,
	selectIsCheckoutLoading,
	selectQuickCheckout,
} from '../../Store/checkout';
import {
	selectIsUserEmailVerified,
	selectUserPreferences,
} from '../../features/User';
import {
	useCoursesInCart,
	useCoursesInCartTotalAmount,
} from '../../hooks/courses';
import { nonShippingValidationSchema, validationSchema } from './validation';
import { orderSummaryLabels } from '../../StaticData';

const Checkout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const coursesInCart = useCoursesInCart();
	const coursesPayableAmount = useCoursesInCartTotalAmount();
	const {
		cartItemsList,
		amount: cartedProducts,
		total,
		shipping,
		codCharges,
	} = useSelector((state) => state.cart);
	const isLoading = useSelector(selectIsCheckoutLoading);
	const checkoutStoredData = useSelector(selectCheckoutData);
	const storePreferences = useSelector(selectUserPreferences);
	const isEmailVerified = useSelector(selectIsUserEmailVerified);
	const {
		isQuickCheckout,
		quickOrderType,
		courseToProcess,
		courseToProcessClassTime,
	} = useSelector(selectQuickCheckout);

	const initialValues = useMemo(() => {
		return {
			billingAddress: storePreferences?.billingAddress || {
				isBussiness: false,
				name: '',
				phone: '',
				address: '',
				vat: '',
				city: '',
				zipCode: '',
				country: 'romania',
			},
			shippingAddress: storePreferences?.shippingAddress || {
				selfPickup: false,
				name: '',
				phone: '',
				address: '',
				city: '',
				zipCode: '',
				country: 'romania',
			},
			saveBillingPreferences: true,
			saveShippingPreferences: true,
			isPrepaid: 'true',
			termsAccept: false,
		};
	}, []);

	const displayShippingForm = useMemo(() => {
		// if have products, or one of the course have tools to dispatch: Return true
		// if is quick checkout (direct buy course) and the course includes tools
		if (courseToProcess?.isOnline) {
			return courseToProcess.kitIncluded;
		}

		if (cartItemsList.length > 0) {
			return true;
		}
		return coursesInCart.some((courseItem) => courseItem.kitIncluded);
	}, [cartItemsList, coursesInCart, courseToProcess]);

	const onSubmit = async (values) => {
		try {
			const { payload: result } = await dispatch(proceedCheckout(values));
			if (result && result.nextRoute) {
				return navigate(result.nextRoute);
			}
		} catch (error) { }
	};

	return (
		<div className='mb-4 position-relative'>
			<Breadcrumb />

			{cartedProducts > 0 || coursesInCart.length > 0 || isQuickCheckout ? (
				<div className='container'>
					<Formik
						initialValues={checkoutStoredData || initialValues}
						validationSchema={
							displayShippingForm
								? validationSchema
								: nonShippingValidationSchema
						}
						onSubmit={onSubmit}
					>
						{({ values }) => (
							<div className='row'>
								<div className='col-md-7 col-lg-8'>
									<BillingAddress
										isBusinessAddress={values.billingAddress.isBussiness}
									/>
									{displayShippingForm && (
										<div className='my-4'>
											<ShippingAddress
												isSelfPickup={values.shippingAddress.selfPickup}
											/>
										</div>
									)}
								</div>
								<div className='col-md-5 col-lg-4'>
									<Form>
										{isQuickCheckout && courseToProcess ? (
											<OrderSummary
												btnTitle={orderSummaryLabels.pay}
												cartItems={[]}
												cartCourses={[]}
												hasShipping={
													!values.shippingAddress.selfPickup &&
													displayShippingForm
												}
												shippingCharges={shipping}
												codCharges={0}
												total={
													quickOrderType === 'full'
														? courseToProcess.price
														: quickOrderType === 'registration'
															? courseToProcess.registrationFee
															: quickOrderType === 'outstanding'
																? courseToProcess.price -
																courseToProcess.registrationFee
																: 0
												}
												totalCoursesAmount={0}
												isActionLoading={isLoading}
												actionDisabled={!isEmailVerified}
												isQuickCheckout={true}
												quickCheckoutTitle={courseToProcess.title}
												courseClassTime={courseToProcessClassTime}
												quickOrderType={quickOrderType}
											/>
										) : (
											<OrderSummary
												btnTitle={
													values.isPrepaid === 'true'
														? `${orderSummaryLabels.next}`
														: `${orderSummaryLabels.pay}`
												}
												cartItems={cartItemsList}
												cartCourses={coursesInCart}
												hasShipping={!values.shippingAddress.selfPickup}
												//{
												// !values.shippingAddress.selfPickup &&
												// 	displayShippingForm
												// }
												shippingCharges={shipping}
												codCharges={
													values.isPrepaid === 'true' ? 0 :
														(values.shippingAddress.selfPickup ? 0 : codCharges)
												}
												total={total}
												totalCoursesAmount={coursesPayableAmount}
												isActionLoading={isLoading}
												actionDisabled={!isEmailVerified}
											/>
										)}
									</Form>
								</div>
							</div>
						)}
					</Formik>
				</div>
			) : (
				<div className='container text-center py-5'>
					<p className='h3'>Empty Cart</p>
					<p className='h5'>
						Please add items to cart to proceed with checkout.
					</p>
					<div className=''>
						<Link className='h5' to={'/'}>
							Home Page
						</Link>
					</div>
				</div>
			)}
			{!isEmailVerified && <EmailVerificationAlertModal />}
		</div>
	);
};

export default Checkout;
