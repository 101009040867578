import { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { LoginForm } from './LoginFrom';
import { RegisterFrom } from './RegisterFrom';

import styles from './AuthForm.module.scss';
import './styles.scss';

const Login = () => {
	const [visibleTab, setVisibleTab] = useState('loginForm');

	return (
		<div className={styles['Auth-form-container']}>
			<Tabs
				activeKey={visibleTab}
				className={styles.authTabNav}
				onSelect={setVisibleTab}
			>
				<Tab
					tabClassName={styles.authTabButton}
					eventKey='loginForm'
					title='Login'
				>
					<div className={styles.formContainerInner}>
						<LoginForm activeTab={visibleTab} />
					</div>
				</Tab>
				<Tab
					tabClassName={styles.authTabButton}
					eventKey='registerForm'
					title='Inregistreaza-te'
				>
					<div className={styles.formContainerInner}>
						<RegisterFrom
							activeTab={visibleTab}
							onActivateLoginTab={() => setVisibleTab('loginForm')}
						/>
					</div>
				</Tab>
			</Tabs>
		</div>
	);
};

export default Login;
