import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import {
	collection,
	doc,
	documentId,
	getDoc,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { fireDB, functions } from '../Firebase';
import { getEndOfToday } from '../utils/date.utils';

const initialState = {
	onlineCourses: [],
	onlineCoursesLoading: true,
	onlineCoursesError: null,
	inPersonCourses: [],
	inPersonCoursesLoading: true,
	inPersonCoursesError: null,
	courseDetail: null,
	courseDetailLoading: true,
	courseDetailError: null,
	featuredCourses: [],
	featuredCoursesLoading: true,
	relatedCoursesLoading: true,
	relatedCoursesList: [],
	relatedCoursesError: null,
	allCoursesObjectified: {},
};

export const loadOnlineCourses = createAsyncThunk(
	'courses/loadOnlineCourses',
	async (_, { rejectWithValue }) => {
		const coursesRef = collection(fireDB, 'courses');
		const coursesQuery = query(coursesRef, where('isOnline', '==', true));
		const coursesSnap = await getDocs(coursesQuery);
		if (coursesSnap.size === 0) {
			return rejectWithValue('Sorry, No Online course available currently.');
		}

		return coursesSnap.docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
		}));
	}
);

export const loadInPersonCourses = createAsyncThunk(
	'courses/loadInPersonCourses',
	async (_, { rejectWithValue }) => {
		const endOfToday = getEndOfToday();
		const coursesRef = collection(fireDB, 'courses');
		const coursesQuery = query(
			coursesRef,
			where('startsOn', '>', endOfToday),
			where('isOnline', '==', false)
		);
		const coursesSnap = await getDocs(coursesQuery);
		if (coursesSnap.size === 0) {
			return rejectWithValue('Sorry, No In-Person course available currently.');
		}

		return coursesSnap.docs.map((doc) => {
			const data = doc.data();
			const classes = Object.keys(data.classes)
				.map((key) => ({ ...data.classes[key], class: key }))
				.sort((a, b) => a.startTime - b.startTime);

			return {
				...data,
				id: doc.id,
				classes,
			};
		});
	}
);

export const loadFeaturedCourse = createAsyncThunk(
	'courses/loadFeaturedCourse',
	async (_, { rejectWithValue }) => {
		const coursesRef = doc(fireDB, 'coursesByTypes', 'featured');
		const coursesSnap = await getDoc(coursesRef);
		if (!coursesSnap.exists()) {
			return rejectWithValue('Sorry, No featured course available currently.');
		}
		const { courses: featuredCourses } = coursesSnap.data();

		// Get refrence of all the courses by IDs
		const courseRefs = collection(fireDB, 'courses');
		const coursesQueriesBatches = Math.ceil(featuredCourses.length / 10);
		const allFeaturedCourses = [];

		for (let index = 0; index < coursesQueriesBatches; index++) {
			const batchStartIndex = index * 10;
			const batchEndIndex = batchStartIndex + 10;
			const docsQuery = query(
				courseRefs,
				where(
					documentId(),
					'in',
					featuredCourses
						.slice(batchStartIndex, batchEndIndex)
						.map(({ id }) => id)
				)
			);
			const coursesDocsSnap = await getDocs(docsQuery);
			const refinedCourses = coursesDocsSnap.docs.map((doc) => {
				const data = {
					...doc.data(),
					id: doc.id,
				};
				if (data.classes) {
					const classes = Object.keys(data.classes)
						.map((key) => ({ ...data.classes[key], class: key }))
						.sort((a, b) => a.startTime - b.startTime);
					data.classes = classes;
				}

				return data;
			});
			allFeaturedCourses.push(...refinedCourses);
		}
		return allFeaturedCourses;
	}
);

export const loadAllCourses = createAsyncThunk(
	'courses/loadAllCourses',
	async (_) => {
		const data = await getDocs(collection(fireDB, 'courses'));
		const objectifiedCourses = {};

		for (const courseItem of data.docs) {
			objectifiedCourses[courseItem.id] = {
				...courseItem.data(),
				id: courseItem.id,
			};
		}
		return objectifiedCourses;
	}
);

export const loadCourseDetail = createAsyncThunk(
	'courses/loadCourseDetail',
	async (courseId, { fulfillWithValue, rejectWithValue }) => {
		const getCourseDetail = httpsCallable(functions, 'getCourseDetail');
		const { data: result } = await getCourseDetail(courseId);
		if (result.success) {
			return fulfillWithValue(result);
		} else {
			return rejectWithValue(result.message);
		}
	}
);

export const loadRelatedCourses = createAsyncThunk(
	'courses/relatedCourses',
	async (_, { getState, rejectWithValue }) => {
		const { courseDetail } = getState().courses;
		const courseCollectionType = courseDetail.isOnline ? 'online' : 'in-person';

		const docRef = doc(fireDB, 'coursesByTypes', courseCollectionType);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			return rejectWithValue('Courses list not available for this category.');
		}
		let { courses: categoryCourses } = docSnap.data();
		categoryCourses = categoryCourses.filter(
			(course) => course.id !== courseDetail.id
		);
		categoryCourses = categoryCourses.slice(0, 3);
		const coursesRef = collection(fireDB, 'courses');
		const docsQuery = query(
			coursesRef,
			where(
				documentId(),
				'in',
				categoryCourses.map(({ id }) => id)
			)
		);
		const coursesDocsSnap = await getDocs(docsQuery);
		const courses = coursesDocsSnap.docs.map((doc) => {
			const data = {
				id: doc.id,
				...doc.data(),
			};
			if (data.classes) {
				const classes = Object.keys(data.classes)
					.map((key) => ({ ...data.classes[key], class: key }))
					.sort((a, b) => a.startTime - b.startTime);
				data.classes = classes;
			}

			return data;
		});
		return courses;
	}
);

const slice = createSlice({
	name: 'courses',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loadOnlineCourses.pending, (state, action) => {
			state.onlineCourses = [];
			state.onlineCoursesLoading = true;
			state.onlineCoursesError = null;
		});
		builder.addCase(loadOnlineCourses.fulfilled, (state, action) => {
			state.onlineCourses = action.payload;
			state.onlineCoursesLoading = false;
			state.onlineCoursesError = null;
		});
		builder.addCase(loadOnlineCourses.rejected, (state, action) => {
			state.onlineCourses = [];
			state.onlineCoursesLoading = false;
			state.onlineCoursesError =
				action.payload ||
				action.error.message ||
				"Couldn't load the data, please refresh.";
		});
		builder.addCase(loadInPersonCourses.pending, (state, action) => {
			state.inPersonCourses = [];
			state.inPersonCoursesLoading = true;
			state.inPersonCoursesError = null;
		});
		builder.addCase(loadInPersonCourses.fulfilled, (state, action) => {
			state.inPersonCourses = action.payload;
			state.inPersonCoursesLoading = false;
			state.inPersonCoursesError = null;
		});
		builder.addCase(loadInPersonCourses.rejected, (state, action) => {
			state.inPersonCourses = [];
			state.inPersonCoursesLoading = false;
			state.inPersonCoursesError =
				action.payload ||
				action.error.message ||
				"Couldn't load the data, please refresh.";
		});
		builder.addCase(loadFeaturedCourse.pending, (state, action) => {
			state.featuredCourses = [];
			state.featuredCoursesLoading = true;
		});
		builder.addCase(loadFeaturedCourse.fulfilled, (state, action) => {
			state.featuredCourses = action.payload;
			state.featuredCoursesLoading = false;
		});
		builder.addCase(loadFeaturedCourse.rejected, (state, action) => {
			state.featuredCourses = [];
			state.featuredCoursesLoading = false;
		});
		builder.addCase(loadAllCourses.fulfilled, (state, action) => {
			state.allCoursesObjectified = action.payload;
		});
		builder.addCase(loadCourseDetail.pending, (state, action) => {
			state.courseDetail = null;
			state.courseDetailError = null;
			state.courseDetailLoading = true;
		});
		builder.addCase(loadCourseDetail.fulfilled, (state, { payload }) => {
			state.courseDetail = payload;
			state.courseDetailLoading = false;
		});
		builder.addCase(loadCourseDetail.rejected, (state, action) => {
			console.log('loadCourseDetail.rejected', action);
			state.courseDetail = null;
			state.courseDetailError =
				action.payload ||
				action.error.message ||
				"Couldn't load the data, please refresh.";
			state.courseDetailLoading = false;
		});
		builder.addCase(loadRelatedCourses.pending, (state, action) => {
			state.relatedCoursesList = [];
			state.relatedCoursesLoading = true;
			state.relatedCoursesError = null;
		});
		builder.addCase(loadRelatedCourses.fulfilled, (state, action) => {
			state.relatedCoursesList = action.payload;
			state.relatedCoursesLoading = false;
		});
		builder.addCase(loadRelatedCourses.rejected, (state, action) => {
			console.log('loadRelatedCourses.rejected', action);
			state.relatedCoursesList = [];
			state.relatedCoursesLoading = false;
			state.relatedCoursesError =
				action.payload || action.error.message || "Couldn't load the data.";
		});
	},
});

// SELECTORS
const selectCoursesData = (state) => {
	return state.courses;
};
export const selectOnlineCourses = createSelector(
	selectCoursesData,
	(coursesData) => ({
		onlineCourses: coursesData.onlineCourses,
		onlineCoursesError: coursesData.onlineCoursesError,
		onlineCoursesLoading: coursesData.onlineCoursesLoading,
	})
);

export const selectInPersonCourses = createSelector(
	selectCoursesData,
	(coursesData) => ({
		inPersonCourses: coursesData.inPersonCourses,
		inPersonCoursesLoading: coursesData.inPersonCoursesLoading,
		inPersonCoursesError: coursesData.inPersonCoursesError,
	})
);

export const selectFeaturedCourses = createSelector(
	selectCoursesData,
	(coursesData) => ({
		featuredCourses: coursesData.featuredCourses,
		featuredCoursesLoading: coursesData.featuredCoursesLoading,
	})
);

export const selectCourseDetail = createSelector(
	selectCoursesData,
	(coursesData) => ({
		courseDetail: coursesData.courseDetail,
		courseDetailLoading: coursesData.courseDetailLoading,
		courseDetailError: coursesData.courseDetailError,
	})
);

export const selectRelatedCourses = createSelector(
	selectCoursesData,
	(coursesData) => ({
		relatedCourses: coursesData.relatedCoursesList,
		relatedCoursesLoading: coursesData.relatedCoursesLoading,
		relatedCoursesError: coursesData.relatedCoursesError,
	})
);

export const selectAllCoursesObjectified = createSelector(
	selectCoursesData,
	(coursesData) => coursesData.allCoursesObjectified
);

export default slice.reducer;
