import { useEffect, useState, useRef, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { BsCalendar, BsClock, BsGeoAlt } from 'react-icons/bs';
import { Rating } from 'react-simple-star-rating';
import FloatingHeader from './FloatingHeader';
import CourseContent from './CourseContent';
import { Reviews } from '../../components/Reviews/Reviews';
import { LocalizedDate } from '../../components/LocalizedDate';
import RelatedCourses from './RelatedCourses';
import { Currency } from '../../components/Currency';
import FloatingActions from './FloatingActions';
import CoursePreview from './CoursePreview';
import ItemsList from './ItemsList';
import { CourseClassTimeSelection } from '../../components/CourseClassTimeSelection';
import { loadCourseDetail, selectCourseDetail } from '../../Store/courses';
import { getHumanReadableTimeDifference } from '../../utils/date.utils';
import { updateCourseClassTimePreference } from '../../features/User';
import {
	useGetActiveCourseById,
	useIsCourseInCart,
	useSelectFirstAvailableClass,
} from '../../hooks/courses';
import { useSearchParam } from '../../hooks/general';
import { toggleCourseCart } from '../../Store/coursesCart';
import { setQuickCheckoutCourse } from '../../Store/checkout';

import styles from './CourseDetail.module.scss';

const CourseDetail = () => {
	const dispatch = useDispatch();
	const { courseId } = useParams();
	const queryClassValue = useSearchParam('class');
	const navigate = useNavigate();
	const [showFloatingHeader, setShowFloatingHeader] = useState(false);
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const [selectedClassTime, setSelectedClassTime] = useState('');
	const floatingHeaderPintRef = useRef();
	const activeCourseData = useGetActiveCourseById(courseId, selectedClassTime);
	const isCourseInCart = useIsCourseInCart(courseId, selectedClassTime);

	const { courseDetail, courseDetailLoading, courseDetailError } =
		useSelector(selectCourseDetail);
	const preferredClassTime = useSelectFirstAvailableClass(
		courseId,
		courseDetail?.classes
	);

	const controlNavbar = () => {
		const divNode = floatingHeaderPintRef.current;
		if (!divNode) {
			return;
		}
		const rect = divNode.getBoundingClientRect();
		const isOutOfPage = rect.bottom < 0 || rect.top > window.innerHeight;
		setShowFloatingHeader(isOutOfPage);
	};

	const togglePreviewModal = () => {
		document.body.style.overflow = showPreviewModal ? 'auto' : 'hidden';
		setShowPreviewModal(!showPreviewModal);
	};

	const onAddToCart = () => {
		dispatch(
			toggleCourseCart({
				courseId,
				classTime: selectedClassTime,
				operationType: 'add',
			})
		);
	};

	const updateSelectedClassTime = async (classTime) => {
		dispatch(
			updateCourseClassTimePreference({
				courseId: courseId,
				selectedClassTime: classTime,
			})
		);
		setSelectedClassTime(classTime);
	};

	const onQuickCheckout = async (paymentType) => {
		await dispatch(
			setQuickCheckoutCourse({
				isQuickCheckout: true,
				quickOrderType: paymentType,
				course: courseDetail,
				selectedClassTime: selectedClassTime,
			})
		);
		navigate('/checkout-details');
	};

	const goToCourseConsole = () => {
		navigate(`/courses/console/${courseId}`);
	};

	const discountExpiryText = useMemo(() => {
		if (courseDetail && courseDetail.discountEndsAt && courseDetail.oldPrice) {
			return getHumanReadableTimeDifference(courseDetail.discountEndsAt);
		}
		return null;
	}, [courseDetail]);

	const courseExpiryText = useMemo(() => {
		if (activeCourseData && activeCourseData.expiresOn) {
			return getHumanReadableTimeDifference(activeCourseData.expiresOn);
		}
		return null;
	}, [activeCourseData]);

	const selectedClass = useMemo(() => {
		if (courseDetail?.classes && selectedClassTime) {
			return courseDetail?.classes.find(
				(item) => item.class === selectedClassTime
			);
		}
	}, [courseDetail?.classes, selectedClassTime]);

	const {
		allowedToCart,
		allowedToBuy,
		allowedToPayRegistration,
		allowedToPayOutstandingAmount,
		allowedToCourseConsole,
		outstandingAmount,
	} = useMemo(() => {
		const courseStatus = {
			allowedToCart: true,
			allowedToBuy: true,
			allowedToPayRegistration: false,
			allowedToPayOutstandingAmount: false,
			allowedToCourseConsole: false,
			outstandingAmount: 0,
		};

		if (!courseDetail) {
			courseStatus.allowedToBuy = false;
			return courseStatus;
		}

		if (activeCourseData) {
			courseStatus.allowedToBuy = false;
			courseStatus.allowedToCart = false;
			if (activeCourseData.outstandingAmount > 0) {
				courseStatus.allowedToPayOutstandingAmount = true;
				courseStatus.outstandingAmount =
					courseDetail.price - courseDetail.registrationFee;
			} else {
				courseStatus.allowedToCourseConsole = true;
			}
		} else {
			// Check if only registration allowed
			// OR if Seat is Available
			if (!courseDetail.isOnline) {
				if (courseDetail.registrationFee > 0) {
					courseStatus.allowedToPayRegistration = true;
				}
				if (selectedClass?.availableSeats < 1) {
					courseStatus.allowedToBuy = false;
					courseStatus.allowedToCart = false;
					courseStatus.allowedToPayRegistration = false;
				}
			}
		}

		return courseStatus;
	}, [activeCourseData, courseDetail, selectedClass]);

	useEffect(() => {
		if (queryClassValue) {
			setSelectedClassTime(queryClassValue);
		} else if (preferredClassTime) {
			setSelectedClassTime(preferredClassTime);
		}
	}, [queryClassValue, preferredClassTime]);

	useEffect(() => {
		window.addEventListener('scroll', controlNavbar);
		return () => {
			window.removeEventListener('scroll', controlNavbar);
		};
	}, []);

	useEffect(() => {
		dispatch(loadCourseDetail(courseId));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [courseId]);

	console.log('SELECTED CLASS TIME::', selectedClassTime);

	return (
		<div className=''>
			{courseDetail && (
				<section className='position-relative'>
					<FloatingHeader
						showFloatingHeader={showFloatingHeader}
						isAccredited={courseDetail.isAccredited}
						title={courseDetail.title}
						averageRating={courseDetail.averageRating}
						totalReviews={courseDetail.reviews.length}
						oldPrice={outstandingAmount ? 0 : courseDetail.oldPrice}
						price={outstandingAmount || courseDetail.price}
						totalPurchases={courseDetail.totalPurchases}
						allowBuyCourse={allowedToBuy}
						allowPayRemaingAmount={allowedToPayOutstandingAmount}
						allowedToCourseConsole={allowedToCourseConsole}
						onQuickCheckout={onQuickCheckout}
						goToCourseConsole={goToCourseConsole}
					/>
					<FloatingActions
						floatingHeaderVisible={showFloatingHeader}
						onShowCoursePreview={togglePreviewModal}
						coverImage={courseDetail.cover}
						previewVideoUrl={courseDetail.previewVideoUrl}
						oldPrice={outstandingAmount ? 0 : courseDetail.oldPrice}
						price={outstandingAmount || courseDetail.price}
						courseType={courseDetail.isOnline ? 'Online' : 'Fizic'}
						totalDuration={courseDetail.totalDuration}
						courseValidity={courseDetail.purchaseValidity}
						courseExpiry={courseExpiryText}
						discountExpiry={discountExpiryText}
						allowAddToCart={allowedToCart}
						isCourseInCart={isCourseInCart}
						allowBuyCourse={allowedToBuy}
						allowPayRegistration={allowedToPayRegistration}
						allowPayRemaingAmount={allowedToPayOutstandingAmount}
						allowedToCourseConsole={allowedToCourseConsole}
						onAddToCart={onAddToCart}
						onQuickCheckout={onQuickCheckout}
						goToCourseConsole={goToCourseConsole}
					/>
					<div ref={floatingHeaderPintRef} className={styles.titleSection}>
						<div className='container'>
							<ul
								className={clsx(
									styles.breadcrumbTree,
									styles.leftContentSection
								)}
							>
								<li>
									<Link to={'/courses'}>Courses</Link>
								</li>
								<li className='active'>
									{courseDetail.title.substring(0, 60)}
									{courseDetail.title.length > 60 && <span>...</span>}
								</li>
							</ul>
							<div className={clsx(styles.previewCover, 'my-3 d-md-none')}>
								<img src={courseDetail.cover} />
								{courseDetail.previewVideoUrl && (
									<div
										className={styles.previewOverlay}
										onClick={togglePreviewModal}
									>
										<span className={styles.previewPlayBtn} />
										<div className={styles.PreviewText}>
											<p>Preview this course</p>
										</div>
									</div>
								)}
							</div>
							<div className={styles.leftContentSection}>
								<h1>{courseDetail.title}</h1>
								<div className='d-flex align-items-center gap-1 flex-wrap'>
									<span className='text-primary h5 mb-0'>
										{parseFloat(courseDetail.averageRating).toFixed(1)}
									</span>
									<Rating
										initialValue={courseDetail.averageRating}
										readonly={true}
										allowFraction={true}
										size={20}
										fillClassName='text-primary'
									/>
									<span>({courseDetail.reviews.length} Reviews)</span>
									<span className='h6 mb-0 ms-2'>
										{courseDetail.totalPurchases} absolvenți
									</span>
								</div>
								<p className='h5 fw-normal my-4 text-justify'>
									{courseDetail.description}
								</p>
								<div className='d-flex align-items-center gap-1 flex-wrap'>
									{courseDetail.isAccredited && (
										<span className={styles.acrediationBadge}>Acreditat</span>
									)}
									{selectedClass && (
										<p
											className={clsx({
												'mb-0 h6 ms-2 fw-bold': true,
												'text-danger': selectedClass.availableSeats < 5,
											})}
										>
											{selectedClass.availableSeats}/{selectedClass.totalSeats}{' '}
											Locuri disponibile
										</p>
									)}
								</div>
								{courseDetail.courseLocation && (
									<p className='d-flex align-items-center gap-1 mt-2 h6'>
										<BsGeoAlt className='default' size={14} />
										<span>Locație:</span>
										<span className='fw-bold'>
											{courseDetail.courseLocation}
										</span>
									</p>
								)}
								{courseDetail.startsOn && (
									<p className='d-flex align-items-center gap-1 mt-2 h6'>
										<BsCalendar className='default' size={14} />
										<span>Începe pe:</span>
										<LocalizedDate
											className='fw-bold'
											date={courseDetail.startsOn}
										/>
									</p>
								)}
								{courseDetail.completesOn && (
									<p className='d-flex align-items-center gap-1 mt-2 h6'>
										<BsCalendar className='default' size={14} />
										<span>Se încheie pe:</span>
										<LocalizedDate
											className='fw-bold'
											date={courseDetail.completesOn}
										/>
									</p>
								)}
								{courseDetail.classes && (
									<CourseClassTimeSelection
										courseId={courseId}
										classes={courseDetail.classes}
										selectedClassTime={selectedClassTime}
										onChange={updateSelectedClassTime}
									/>
								)}
								{courseDetail.courseLastUpdatedOn && (
									<p className='d-flex align-items-center gap-1 mt-2 h6'>
										<BsClock className='default' size={14} />
										<span>Updatat la:</span>
										<LocalizedDate
											className='fw-bold'
											date={courseDetail.courseLastUpdatedOn}
										/>
									</p>
								)}
								<div className='d-md-none'>
									<div className=''>
										<p className='mb-2'>
											Tipul cursului:
											<span className='fw-bold ms-1'>
												{courseDetail.isOnline ? 'Online' : 'Fizic'}
											</span>
										</p>
										{courseDetail.isOnline && (
											<div>
												<p className='mb-2'>
													Durată curs:
													<span className='fw-bold ms-1'>
														{courseDetail.totalDuration} zile
													</span>
												</p>
												{courseExpiryText ? (
													<p className='mb-2'>
														Cursul expiră în:
														<span className='fw-bold ms-1'>
															{courseExpiryText}
														</span>
													</p>
												) : (
													<p className='mb-2'>
														Acces curs:
														<span className='fw-bold ms-1'>
															{courseDetail.purchaseValidity} Zile
														</span>
													</p>
												)}
											</div>
										)}
									</div>
									<h5 className='my-2'>
										{courseDetail.oldPrice > 0 && !outstandingAmount && (
											<Currency
												className='small text-decoration-line-through'
												price={courseDetail.oldPrice}
											/>
										)}
										<Currency
											className='fw-bold lead'
											price={outstandingAmount || courseDetail.price}
										/>
									</h5>
									{courseDetail.oldPrice > 0 && outstandingAmount === 0 && (
										<p className='text-muted small'>
											Reducerea se termină în {discountExpiryText}
										</p>
									)}
									<div className='row gap-3 mt-3 mx-0 justify-content-between'>
										{allowedToBuy && (
											<button
												className={clsx(
													allowedToPayRegistration ? 'col-4' : 'col-12',
													'btn btn-light',
													'rounded-0 py-2',
													'text-uppercase fw-bold'
												)}
												onClick={() => onQuickCheckout('full')}
											>
												Plătește Integral
											</button>
										)}
										{allowedToPayRegistration && (
											<button
												className={clsx(
													'col-7',
													'btn btn-dark',
													'rounded-0 py-2',
													'text-uppercase fw-bold'
												)}
												onClick={() => onQuickCheckout('registration')}
											>
												Plătește Avans
											</button>
										)}
										{allowedToPayOutstandingAmount && (
											<button
												className={clsx(
													'col-12',
													'btn btn-dark',
													'rounded-0 py-2',
													'text-uppercase fw-bold'
												)}
												onClick={() => onQuickCheckout('outstanding')}
											>
												Achită Restul de Plată
											</button>
										)}
										{allowedToCart && (
											<button
												className={clsx(
													'col-12',
													'btn btn-primary',
													'mt-2',
													'rounded-0',
													'text-white text-uppercase fw-bold lh-lg'
												)}
												disabled={isCourseInCart}
												onClick={onAddToCart}
											>
												{isCourseInCart ? 'Adăugat în coș' : 'Adaugă în coș'}
											</button>
										)}
										{allowedToCourseConsole && (
											<button
												className={clsx(
													'col-12',
													'btn btn-primary',
													'mt-2',
													'rounded-0',
													'text-white text-uppercase fw-bold lh-lg'
												)}
												onClick={goToCourseConsole}
											>
												Consolă Curs
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='py-5 bg-white'>
						<div className='container'>
							<div className={styles.leftContentSection}>
								{courseDetail.features && courseDetail.features.length > 0 && (
									<ItemsList
										title='Avantajele cursului'
										itemsList={courseDetail.features}
									/>
								)}

								{courseDetail.learningFeatures &&
									courseDetail.learningFeatures.length > 0 && (
										<ItemsList
											title='Obiectivele cursului'
											itemsList={courseDetail.learningFeatures}
										/>
									)}
								{courseDetail.importantNotes &&
									courseDetail.importantNotes.length > 0 && (
										<ItemsList
											title='Informații utile'
											itemsList={courseDetail.importantNotes}
										/>
									)}

								{courseDetail.requiredDocuments &&
									courseDetail.requiredDocuments.length > 0 && (
										<ItemsList
											title='Acte necesare'
											itemsList={courseDetail.requiredDocuments}
										/>
									)}

								{courseDetail.kitIncluded && (
									<ItemsList
										title='Kit Inclus'
										itemsList={courseDetail.kitItems}
									/>
								)}
								{courseDetail.courseContent && (
									<CourseContent
										contentSection={courseDetail.courseContent}
										totalLectures={courseDetail.totalLectures}
										totalDuration={courseDetail.totalDuration}
									/>
								)}
								<div className='mt-4'>
									<h3 className='mb-3'>Descriere</h3>
									<p>{courseDetail.detail}</p>
								</div>
								<div className=' border-top mt-4'>
									<Reviews
										averageRating={courseDetail.averageRating}
										reviews={courseDetail.reviews}
										noReviewMessage='No reviews found currently.'
									/>
								</div>
								<div className='border-top mt-4'>
									<RelatedCourses />
								</div>
							</div>
						</div>
					</div>
					{courseDetail.previewVideoUrl && (
						<CoursePreview
							modelVisible={showPreviewModal}
							previewVideoUrl={courseDetail.previewVideoUrl}
							closePreviewModal={togglePreviewModal}
						/>
					)}
				</section>
			)}
			{courseDetailLoading && (
				<div className='text-center my-5'>
					<span
						className='spinner-border text-primary'
						style={{ width: '3rem', height: '3rem' }}
					/>
					<p className='lead mt-3'>Loading Course Detail</p>
				</div>
			)}
			{courseDetailError && (
				<div className='text-center my-5'>
					<h3>Error!!</h3>
					<p className='lead mt-3'>{courseDetailError}</p>
					<div className=''>
						<Link
							className='btn btn-primary text-white px-5 py-2 text-uppercase'
							to='/courses'
						>
							View All Courses
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

export default CourseDetail;
