const AcountDeletionActions = ({ onAction }) => {
	return (
		<div>
			<div className='alert alert-danger border-danger'>
				<p className=''>
					Vă rugăm să fiți atenți la aceste acțiuni, deoarece acestea nu pot fi
					anulate.
				</p>
				<div className='d-flex gap-3'>
					<button
						className='btn btn-outline-danger'
						onClick={() => onAction('reset')}
					>
						Ștergeți datele
					</button>
					<button
						className='btn btn-outline-danger'
						onClick={() => onAction('delete')}
					>
						Șterge cont
					</button>
				</div>
			</div>
		</div>
	);
};

export default AcountDeletionActions;
