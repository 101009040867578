import React, { useMemo } from 'react';
import { generateAvatarColor } from '../../utils/general.utils';

import styles from './Avatar.module.scss';

const Avatar = ({ profilePhoto, fullName }) => {
	const bgColor = useMemo(() => {
		return generateAvatarColor(fullName || 'User');
	}, [fullName]);

	return (
		<div>
			{profilePhoto ? (
				<img
					className={styles.img}
					src='https://via.placeholder.com/80'
					alt={fullName}
				/>
			) : (
				<div className={styles.avatar} style={{ backgroundColor: bgColor }}>
					{fullName ? fullName[0] : 'U'}
				</div>
			)}
		</div>
	);
};

export default Avatar;
