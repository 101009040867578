import { BsX } from 'react-icons/bs';
import { Link } from 'react-router-dom';


export const EmailVerificationAlert = ({ onCloseAlert }) => {
	return (
		<div className='bg-primary py-2'>
			<div className='container d-flex align-center'>
				<p className='mb-0 fw-bold text-white text-center flex-grow-1'>
					Salut! Pentru a avea acces deplin pe website-ul nostru, te rugăm să iți verifici contul prin accesarea link-ului primit pe adresa de email.<br />
					Dacă nu îl găsești, poți solicita altul din
					<Link className='btn' to='/my-account'>
						Contul meu
					</Link>
					butonul Verifică Email.
					Mulțumim că ai ales Sancos Academy!
				</p>
				<button className='btn btn-sm' onClick={onCloseAlert}>
					<BsX />
				</button>
			</div>
		</div>
	);
};
