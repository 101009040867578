export const calculateCourseSku = (courseId, classTime) => {
	let courseSku = courseId;
	if (classTime) {
		courseSku = courseSku.concat(':', classTime);
	}
	return courseSku;
};

export const decodeCourseSku = (courseSku) => {
	return courseSku.split(':');
};
