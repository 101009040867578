export const calculateProductSku = (
	productId,
	curvature,
	thickness,
	length
) => {
	let sku = productId;
	sku += `_${curvature}_${thickness}_${length}`;
	sku = sku.replace(/ /g, '');
	return sku;
};

export const parseProductVariations = (variationsList) => {
	const curvaturesList = [];
	const thicknessList = {};
	const lengthsList = {};

	for (const variationsRow of variationsList) {
		const [curvature, thickness, length] = variationsRow.split(':');

		const curvatureKey = curvature.replace(/ /g, '');
		const thicknessKey = thickness.replace(/ /g, '');
		const lengthKey = length.replace(/ /g, '');

		const curvatureExists = curvaturesList.some(
			(item) => item.value === curvatureKey
		);
		if (!curvatureExists) {
			curvaturesList.push({
				label: curvature,
				value: curvatureKey,
			});
			thicknessList[curvatureKey] = [
				{
					label: thickness,
					value: thicknessKey,
				},
			];
			lengthsList[`${curvatureKey}_${thicknessKey}`] = [
				{
					label: length,
					value: lengthKey,
				},
			];
		} else {
			// Check if Thickness exists
			const thicknessExists = thicknessList[curvatureKey].some(
				(item) => item.value === thicknessKey
			);
			if (!thicknessExists) {
				thicknessList[curvatureKey].push({
					label: thickness,
					value: thicknessKey,
				});
				lengthsList[`${curvatureKey}_${thicknessKey}`] = [
					{
						label: length,
						value: lengthKey,
					},
				];
			} else {
				lengthsList[`${curvatureKey}_${thicknessKey}`].push({
					label: length,
					value: lengthKey,
				});
			}
		}
	}
	return { curvaturesList, thicknessList, lengthsList };
};
