import styles from './ProductBannerItem.module.scss';
import clsx from 'clsx';

const ProductBannerItem = ({ row1, row2 }) => {
    return (
        <div className={clsx(
            styles.fit,
            'col-md-4'
        )}>
            <h6 className="d-flex justify-content-center">{row1}</h6>
            <p className="d-flex justify-content-center mb-0">{row2}</p>
        </div>
    );
};

export default ProductBannerItem;