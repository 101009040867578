import { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import CourseCard from '../../components/CourseCard';
import SliderHeader from './SliderHeader';
import { BsArrowClockwise } from 'react-icons/bs';

const CoursesSlider = ({
	sliderTitle,
	courses,
	coursesLoading,
	loadError,
	onReload,
}) => {
	const coursesSlider = useRef();

	const handlePrevious = useCallback(() => {
		coursesSlider?.current?.slidePrev();
	}, [coursesSlider]);

	const handleNext = useCallback(() => {
		coursesSlider?.current?.slideNext();
	}, [coursesSlider]);

	return (
		<div className=''>
			<SliderHeader
				title={sliderTitle}
				onArrowLeft={handlePrevious}
				onArrowRight={handleNext}
				showSliderArrows={
					!(coursesLoading || loadError || courses.length === 0)
				}
			/>
			<Swiper
				slidesPerView={4}
				spaceBetween={16}
				onBeforeInit={(swiper) => {
					coursesSlider.current = swiper;
				}}
				modules={[Navigation]}
				breakpoints={{
					320: { slidesPerView: 1 },
					450: { slidesPerView: 2 },
					768: { slidesPerView: 3 },
					1200: { slidesPerView: 4 },
				}}
			>
				{courses.map((item, index) => (
					<SwiperSlide key={item.id} className='h-auto'>
						<CourseCard
							id={item.id}
							cover={item.cover}
							title={item.title}
							rating={item.averageRating}
							reviewCount={item.reviews.length}
							price={item.price}
							oldPrice={item.oldPrice}
							acreditat={item.isAccredited && !item.isOnline}
							startDate={item.startsOn}
							isOnline={item.isOnline}
							courseLocation={item.courseLocation}
							classes={item.classes}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			{coursesLoading && (
				<div className='text-center'>
					<div className='spinner-border text-primary' role='status' />
					<p className='small'>Loading Courses</p>
				</div>
			)}
			{loadError && (
				<div className='text-center lead my-3'>
					<p>{loadError}</p>
					<button className='btn' onClick={onReload}>
						<BsArrowClockwise />
					</button>
				</div>
			)}
		</div>
	);
};

export default CoursesSlider;
