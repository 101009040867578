import { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { BsCheckCircleFill } from 'react-icons/bs';
import CustomSelect from '../../components/CustomSelect';
import {
	PhoneInputField,
	SwitchInputField,
	TextInputField,
} from '../../components/Checkout/InputField';

import {
	resetUserUpdateStatus,
	selectUserPreferences,
	selectUserUpdateStatus,
	updateAddresses,
} from '../../features/User';
import {
	billingAddressValidationSchema,
	shippingAddressValidationSchema,
} from '../CheckoutDetails/validation';
import { extractAndParseValue } from '../../utils/general.utils';
import { AVAILABLE_COUNTRIES } from '../../utils/constants';

import { billingLabels, shippingLabels } from '../../StaticData';
import styles from './MyAccount.module.scss';

const AddressUpdateFrom = ({ addressType, closeModal }) => {
	const storePreferences = useSelector(selectUserPreferences);
	const dispatch = useDispatch();
	const { userUpdateLoading, userUpdateSuccess } = useSelector(
		selectUserUpdateStatus
	);

	const onSubmit = (values) => {
		dispatch(
			updateAddresses({ addressType: addressType, updatedAddress: values })
		);
	};

	const initialValues = useMemo(() => {
		const preferences = storePreferences ? storePreferences[addressType] : null;
		return {
			name: extractAndParseValue(preferences, 'name', ''),
			phone: extractAndParseValue(preferences, 'phone', ''),
			address: extractAndParseValue(preferences, 'address', ''),
			city: extractAndParseValue(preferences, 'city', ''),
			zipCode: extractAndParseValue(preferences, 'zipCode', ''),
			country: extractAndParseValue(preferences, 'country', 'romania'),
			isBussiness: extractAndParseValue(preferences, 'isBussiness', false),
			selfPickup: extractAndParseValue(preferences, 'selfPickup', false),
			vat: extractAndParseValue(preferences, 'vat', ''),
		};
	}, [addressType, storePreferences]);

	const closeModalAfterSucces = () => {
		dispatch(resetUserUpdateStatus());
		closeModal();
	};

	return (
		<div className={clsx(userUpdateSuccess && styles.successContainer)}>
			<Formik
				initialValues={initialValues}
				validationSchema={
					addressType === 'billingAddress'
						? billingAddressValidationSchema
						: shippingAddressValidationSchema
				}
				onSubmit={onSubmit}
			>
				{({ values, dirty }) => (
					<Form>
						<div className='row g-3'>
							<div className='col-md-6'>
								<Field
									name='name'
									component={TextInputField}
									label={billingLabels.fullName}
								/>
							</div>
							<div className='col-md-6'>
								<Field
									name='phone'
									component={PhoneInputField}
									label={billingLabels.phone}
								/>
							</div>
							<div
								className={clsx(
									values.isBussiness ? 'col-lg-9 col-md-7' : 'col-12'
								)}
							>
								<Field
									name='address'
									component={TextInputField}
									label={billingLabels.address}
								/>
							</div>
							{values.isBussiness && addressType === 'billingAddress' && (
								<div className='col-lg-3 col-md-5'>
									<Field
										name='vat'
										component={TextInputField}
										label={billingLabels.vat}
									/>
								</div>
							)}
							<div className='col-lg-5 col-md-12'>
								<Field
									name='city'
									component={TextInputField}
									label={billingLabels.city}
								/>
							</div>
							<div className='col-lg-4 col-md-5'>
								<Field name='country'>
									{({ field }) => (
										<>
											<label className='form-label'>
												{billingLabels.country}
											</label>
											<CustomSelect
												placeholder={billingLabels.country}
												options={AVAILABLE_COUNTRIES}
												selected={field.value}
												onChange={field.onChange}
											/>
										</>
									)}
								</Field>
							</div>
							<div className='col-lg-3 col-md-5'>
								<Field
									name='zipCode'
									component={TextInputField}
									label={billingLabels.zipCode}
								/>
							</div>
							{addressType === 'billingAddress' && (
								<div className='col-12 d-flex'>
									<div className='form-check form-switch form-check-reverse my-3'>
										<Field
											name='isBussiness'
											component={SwitchInputField}
											placeholder={billingLabels.isBusiness}
										/>
									</div>
								</div>
							)}
							{addressType === 'shippingAddress' && (
								<div className='col-12 d-flex'>
									<div className='form-check form-switch form-check-reverse my-3'>
										<Field
											name='selfPickup'
											component={SwitchInputField}
											placeholder={shippingLabels.isPickup}
										/>
									</div>
								</div>
							)}
							<div className='col-12 d-flex gap-3'>
								<button
									className='btn btn-primary text-white'
									type='submit'
									disabled={!dirty}
								>
									Update Address
								</button>
								<button
									className='btn btn-secondary'
									type='button'
									onClick={closeModal}
								>
									Cancel
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			{userUpdateLoading && (
				<div className={styles.loadingOverlay}>
					<div className='spinner-border text-primary' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
					<div className='message'>Processing...</div>
				</div>
			)}
			{!!userUpdateSuccess && (
				<div className={clsx(styles.successOverlay, 'p-0')}>
					<BsCheckCircleFill className='text-primary default' size={50} />
					<div className=''>
						<p>{userUpdateSuccess}</p>
						<button
							className='btn btn-primary text-white py-2 w-50 mt-2'
							onClick={closeModalAfterSucces}
						>
							Close
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default AddressUpdateFrom;
