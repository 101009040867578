import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { Rating } from 'react-simple-star-rating';
import { LocalizedDate } from '../LocalizedDate';
// import Pagination from '../Pagination';
import styles from './Reviews.module.scss';

export const Reviews = ({ averageRating, reviews, noReviewMessage }) => {
	const [ratingsNumbersbyStarts, ratingPercentages] = useMemo(() => {
		const ratingsNumbers = {
			0: 0,
			1: 0,
			2: 0,
			3: 0,
			4: 0,
			5: 0,
		};

		const ratingPercentages = {
			0: '0%',
			1: '0%',
			2: '0%',
			3: '0%',
			4: '0%',
			5: '0%',
		};

		const groupedReviews = groupBy(reviews, (reviewItem) =>
			Math.round(reviewItem.rating)
		);

		for (const group in groupedReviews) {
			ratingsNumbers[group] = groupedReviews[group].length;
			ratingPercentages[group] =
				(groupedReviews[group].length / reviews.length) * 100;
		}
		return [ratingsNumbers, ratingPercentages];
	}, [reviews]);

	return (
		<div className='row g-5'>
			<div className='col-md-4'>
				<div>
					<div className={styles.ratingAvg}>
						<span className='mt-1'>{averageRating}</span>
						<Rating
							initialValue={averageRating}
							readonly={true}
							allowFraction={true}
							size={25}
							SVGclassName='default'
							fillColor='#14a79a'
						/>
					</div>
					<ul className={styles.rating}>
						{[5, 4, 3, 2, 1].map((value) => (
							<li key={value}>
								<Rating
									initialValue={value}
									readonly={true}
									allowFraction={true}
									size={18}
									SVGclassName='default'
									fillColor='#14a79a'
								/>
								<div className={styles.ratingProgress}>
									<div style={{ width: ratingPercentages[value] }} />
								</div>
								<span className={styles.sum}>
									{ratingsNumbersbyStarts[value] || 0}
								</span>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className='col-md-8'>
				<ul className={styles.reviews}>
					{reviews.map((reviewRow) => (
						<li key={reviewRow.reviewId}>
							<div className={styles.reviewHeading}>
								<h5 className={styles.name}>{reviewRow.fullName}</h5>
								<p className={styles.date}>
									<LocalizedDate
										date={reviewRow.date.seconds * 1000}
										dateStyle='medium'
										timeStyle='short'
									/>
								</p>
								<Rating
									initialValue={reviewRow.rating}
									readonly={true}
									allowFraction={true}
									size={20}
									fillColor='#14a79a'
								/>
							</div>
							<div className={styles.reviewBody}>
								<p className={styles.typography}>{reviewRow.message}</p>
							</div>
						</li>
					))}
				</ul>
				{reviews.length === 0 && (
					<p className='text-muted h-100 d-flex justify-content-center align-items-center h5'>
						{noReviewMessage || 'This product has no reviews yet.'}
					</p>
				)}
				{/* TODO: INTEGRATED LATER */}
				{/* <Pagination /> */}
			</div>
		</div>
	);
};
