import { Field } from 'formik';
import { paymentTypeLabels } from '../../StaticData';

export const PaymentTypeSelection = ({ codDisabled }) => (
	<Field name='isPrepaid'>
		{({ field }) => (
			<div className='mt-5 mb-4'>
				<div className='form-check d-flex align-items-center gap-2'>
					<input
						className='form-check-input'
						{...field}
						type='radio'
						name={field.name}
						value={true}
						id='creditCard'
						checked={field.value === 'true'}
					/>
					<label className='form-check-label' htmlFor='creditCard'>
						{paymentTypeLabels.card}
					</label>
				</div>
				{!codDisabled && (
					<div className='form-check d-flex align-items-center gap-2'>
						<input
							className='form-check-input'
							{...field}
							type='radio'
							name={field.name}
							id='cod'
							value={false}
							checked={field.value === 'false'}
						/>
						<label className='form-check-label' htmlFor='cod'>
							{paymentTypeLabels.cod}
						</label>
					</div>
				)}
			</div>
		)}
	</Field>
);
