import { Field, getIn } from 'formik';
import { termsAndConditionsLabels } from '../../StaticData';

export const TermsAcceptance = () => (
	<Field name='termsAccept'>
		{({ field, form: { errors, submitCount } }) => (
			<div className='form-check mb-3'>
				<input
					className='form-check-input mt-1'
					type='checkbox'
					checked={field.value}
					id='terms'
					{...field}
				/>
				<label className='form-check-label' htmlFor='terms'>
					{termsAndConditionsLabels.text} &nbsp;
					<a href='/terms-and-conditions'>{termsAndConditionsLabels.title}</a>
				</label>
				{submitCount > 0 && getIn(errors, field.name) && (
					<p className='invalid-feedback d-block mb-0'>
						{getIn(errors, field.name)}
					</p>
				)}
			</div>
		)}
	</Field>
);
