import React from 'react';
import Select from 'react-select';

import styles from './CustomSelect.module.scss';

const CustomSelect = ({ placeholder, options, selected, onChange }) => {
	return (
		<Select
			placeholder={placeholder}
			className={styles.container}
			options={options}
			value={options.filter((option) => option.value === selected)}
			onChange={onChange}
			components={{ ClearIndicator: null, IndicatorSeparator: null }}
			isSearchable={false}
			menuPortalTarget={document.body}
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: '#14a79a36',
					primary: '#14a79a',
				},
			})}
			noOptionsMessage={() => 'Nicio opțiune'}
		/>
	);
};

export default CustomSelect;
