import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { collection, doc, getDocs } from 'firebase/firestore';
import { sortBy } from 'lodash';
import { fireDB } from '../Firebase';

const initialState = {
	loading: true,
	orders: [],
	error: null,
	activeCourses: [],
	activeCoursesLoading: true,
	activeCoursesError: null,
	pastCourses: [],
	pastCoursesLoading: true,
	pastCoursesError: null,
};

export const loadOrdersHistory = createAsyncThunk(
	'ordersHistory/load',
	async (_, { getState, rejectWithValue }) => {
		const user = getState().user.userInfo;

		if (!user) {
			return rejectWithValue('Please login to view your orders history.');
		}
		const userRef = doc(fireDB, 'users', user.uid);
		const docSnap = await getDocs(collection(userRef, 'ordersHistory'));
		if (!docSnap.docs.length) {
			return rejectWithValue('No orders placed yet.');
		}
		const result = docSnap.docs.map((doc) => {
			return {
				...doc.data(),
				id: doc.id,
			};
		});

		return sortBy(result, 'orderDate').reverse();
	}
);

const slice = createSlice({
	name: 'ordersHistory',
	initialState,
	reducers: {
		resetOrdersList: (state) => {
			state.loading = false;
			state.orders = [];
			state.error = null;
		},
		resetActiveCourses: (state) => {
			state.activeCourses = [];
			state.activeCoursesLoading = false;
			state.activeCoursesError = null;
		},
		setActiveCourses: (state, { payload }) => {
			state.activeCourses = payload;
			state.activeCoursesLoading = false;
			state.activeCoursesError = null;
		},
		setActiveCoursesError: (state, { payload }) => {
			state.activeCourses = [];
			state.activeCoursesLoading = false;
			state.activeCoursesError = payload;
		},
		resetPastCourses: (state) => {
			state.pastCourses = [];
			state.pastCoursesLoading = false;
			state.pastCoursesError = null;
		},
		setPastCourses: (state, { payload }) => {
			state.pastCourses = payload;
			state.pastCoursesLoading = false;
			state.pastCoursesError = null;
		},
		setPastCoursesError: (state, { payload }) => {
			state.pastCourses = [];
			state.pastCoursesLoading = false;
			state.pastCoursesError = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadOrdersHistory.pending, (state, action) => {
			state.orders = [];
			state.loading = true;
			state.error = null;
		});
		builder.addCase(loadOrdersHistory.fulfilled, (state, action) => {
			state.orders = action.payload;
			state.loading = false;
		});
		builder.addCase(loadOrdersHistory.rejected, (state, action) => {
			state.loading = false;
			state.orders = [];
			state.error = action.payload || "Couldn't load the data, please refresh.";
		});
	},
});

export const {
	resetOrdersList,
	resetActiveCourses,
	setActiveCourses,
	setActiveCoursesError,
	resetPastCourses,
	setPastCourses,
	setPastCoursesError,
} = slice.actions;

// SELECTORS
const selectOrdersHistoryData = (state) => {
	return state.ordersHistory;
};
export const selectIsOrdersHistoryLoading = createSelector(
	selectOrdersHistoryData,
	({ loading }) => loading
);
export const selectOrdersHistoryError = createSelector(
	selectOrdersHistoryData,
	({ error }) => error
);
export const selectOrdersList = createSelector(
	selectOrdersHistoryData,
	({ orders }) => orders
);

export const selectActiveCourses = createSelector(
	selectOrdersHistoryData,
	({ activeCourses, activeCoursesLoading, activeCoursesError }) => {
		return {
			activeCourses,
			activeCoursesLoading,
			activeCoursesError,
		};
	}
);

export const selectPastCourses = createSelector(
	selectOrdersHistoryData,
	({ pastCourses, pastCoursesLoading, pastCoursesError }) => {
		return {
			pastCourses,
			pastCoursesLoading,
			pastCoursesError,
		};
	}
);

export default slice.reducer;
