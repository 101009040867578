import { useEffect, useState } from 'react';
export const LocalizedTimeRange = ({ startTime, endTime, className }) => {
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);

	useEffect(() => {
		const startDateTime = new Date(0, 0);
		startDateTime.setMinutes(startTime);
		const startTimeString = startDateTime.toLocaleTimeString('ro-Ro', {
			hour: '2-digit',
			minute: '2-digit',
		});

		const endDateTime = new Date(0, 0);
		endDateTime.setMinutes(endTime);
		const endTimeString = endDateTime.toLocaleTimeString('ro-Ro', {
			hour: '2-digit',
			minute: '2-digit',
		});

		setStart(startTimeString);
		setEnd(endTimeString);
	}, [startTime, endTime]);

	return (
		<span className={className}>
			{start} &mdash; {end}
		</span>
	);
};
