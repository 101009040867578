import clsx from 'clsx';
import { getIn } from 'formik';
import InputMask from 'react-input-mask';

export const TextInputField = ({
	field,
	form: { touched, errors },
	...props
}) => (
	<>
		<label className='form-label'>{props.label}</label>
		<input
			type='text'
			className={clsx({
				'form-control': true,
				'is-invalid': getIn(touched, field.name) && getIn(errors, field.name),
			})}
			{...field}
			{...props}
		/>
		{getIn(touched, field.name) && getIn(errors, field.name) && (
			<div className='invalid-feedback'>{getIn(errors, field.name)}</div>
		)}
	</>
);

export const SwitchInputField = ({ field, ...props }) => (
	<>
		<input
			className='form-check-input'
			type='checkbox'
			checked={field.value}
			{...field}
			{...props}
		/>
		<label className='form-check-label lh-1 small'>{props.placeholder}</label>
	</>
);

export const CheckboxField = ({ field, ...props }) => (
	<div className='form-check'>
		<input
			className='form-check-input'
			type='checkbox'
			checked={field.value}
			{...field}
			{...props}
		/>
		<label className='form-check-label h6'>{props.label}</label>
	</div>
);

export const PhoneInputField = ({
	field,
	form: { touched, errors },
	...props
}) => (
	<>
		<label className='form-label'>{props.label}</label>
		<InputMask
			mask='+40 999 999 999'
			maskChar={null}
			className={clsx({
				'form-control': true,
				'is-invalid': getIn(touched, field.name) && getIn(errors, field.name),
			})}
			onChange={field.onChange}
			{...field}
			{...props}
		/>
		{getIn(touched, field.name) && getIn(errors, field.name) && (
			<div className='invalid-feedback'>{getIn(errors, field.name)}</div>
		)}
	</>
);
