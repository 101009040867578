import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { doc, getDoc } from 'firebase/firestore';
import { groupBy } from 'lodash';
import { fireDB } from '../Firebase';

const initialState = {
	loading: true,
	categories: [],
	error: null,
	selected: 'any',
	selectedCatTitle: 'All Products',
	rawCategories: [],
};

export const loadProductsCategories = createAsyncThunk(
	'productsCategories/load',
	async (_, { rejectWithValue, dispatch }) => {
		const docRef = doc(fireDB, 'categories', 'products');
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			return rejectWithValue('Product categories not available.');
		}

		const { categories } = docSnap.data();
		dispatch(setRawCatgoryes(categories));
		const groupedChildren = groupBy(categories, (category) => category.parent);

		const rootCategories = categories
			.filter((category) => !category.parent)
			.map((category) => {
				return {
					...category,
					subCategories: groupedChildren[category.id] || [],
				};
			});

		return rootCategories;
	}
);

const slice = createSlice({
	name: 'productsCategories',
	initialState,
	reducers: {
		setSelectedProdCat: (state, action) => {
			state.selected = action.payload;
		},
		setSelectedProdCatTitle: (state, action) => {
			state.selectedCatTitle = action.payload;
		},
		setRawCatgoryes: (state, action) => {
			state.rawCategories = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loadProductsCategories.pending, (state, action) => {
				state.categories = [];
				state.loading = true;
				state.error = null;
			})
			.addCase(loadProductsCategories.fulfilled, (state, action) => {
				state.categories = action.payload;
				state.loading = false;
			})
			.addCase(loadProductsCategories.rejected, (state, action) => {
				state.loading = false;
				state.categories = [];
				state.error = action.error;
			});
	},
});

// ACTIONS
export const { setSelectedProdCat, setSelectedProdCatTitle, setRawCatgoryes } =
	slice.actions;

// SELECTORS
const selectProductCatData = (state) => {
	return state.productsCategories;
};
export const selectIsProdCatsLoading = createSelector(
	selectProductCatData,
	(productCatData) => productCatData.loading
);
export const selectProdCatsError = createSelector(
	selectProductCatData,
	(productCatData) => productCatData.error
);
export const selectProdCatsList = createSelector(
	selectProductCatData,
	(productCatData) => productCatData.categories
);
export const selectProdCatSelected = createSelector(
	selectProductCatData,
	(productCatData) => productCatData.selected
);
export const selectProdCatSelectedTitle = createSelector(
	selectProductCatData,
	(productCatData) => {
		const rawCategories = productCatData.rawCategories;
		const selectedCategory = productCatData.selected;
		return (
			rawCategories.find((cat) => cat.id === selectedCategory)?.displayName ||
			'Products'
		);
	}
);

export default slice.reducer;
