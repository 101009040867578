import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { Currency } from '../Currency';
import { LocalizedDate } from '../LocalizedDate';
import { CourseClassTimeSelection } from '../CourseClassTimeSelection';
import {
	useGetActiveCourseById,
	useIsCourseInCart,
	useSelectFirstAvailableClass,
} from '../../hooks/courses';
import { updateCourseClassTimePreference } from '../../features/User';

import styles from './FavoriteCourseItem.module.scss';

const FavoriteCourseItem = ({
	id,
	cover,
	title,
	price,
	registrationFee,
	isAccredited,
	isOnline,
	startsOn,
	onRemoveFromFavorite,
	onPayOutStandingAmout,
	onAddToCart,
	classes,
}) => {
	const [selectedClassTime, setSelectedClassTime] = useState('');
	const preferredClassTime = useSelectFirstAvailableClass(id, classes);
	const activeCourseData = useGetActiveCourseById(id, selectedClassTime);
	const dispatch = useDispatch();
	const isCourseInCart = useIsCourseInCart(id, selectedClassTime);

	useEffect(() => {
		if (preferredClassTime) {
			setSelectedClassTime(preferredClassTime);
		}
	}, [preferredClassTime]);

	const selectedClass = useMemo(() => {
		if (classes && selectedClassTime) {
			return classes.find((item) => item.class === selectedClassTime);
		}
	}, [classes, selectedClassTime]);

	const {
		allowAddToCart,
		allowedToCourseConsole,
		outstandingAmount,
		allowPayRemaingAmount,
	} = useMemo(() => {
		const courseStatus = {
			allowAddToCart: true,
			allowedToCourseConsole: false,
			allowPayRemaingAmount: false,
			outstandingAmount: 0,
		};

		if (activeCourseData) {
			courseStatus.allowAddToCart = false;
			if (activeCourseData.outstandingAmount > 0) {
				courseStatus.outstandingAmount = price - registrationFee;
				courseStatus.allowPayRemaingAmount = true;
			} else {
				courseStatus.allowedToCourseConsole = true;
			}
		} else if (!isOnline && selectedClass?.availableSeats < 1) {
			courseStatus.allowAddToCart = false;
		}

		return courseStatus;
	}, [activeCourseData, isOnline, price, registrationFee, selectedClass]);

	const updateSelectedClassTime = async (classTime) => {
		dispatch(
			updateCourseClassTimePreference({
				courseId: id,
				selectedClassTime: classTime,
			})
		);
		setSelectedClassTime(classTime);
	};

	return (
		<div className='position-relative overflow-hidden'>
			<div
				className={clsx(
					'd-flex flex-column flex-sm-row h-100 py-2 my-2 border-bottom'
				)}
			>
				<LinkContainer to={`/courses/${id}`}>
					<div className={clsx(styles['left-col'], 'position-relative')}>
						{isAccredited && (
							<span className={styles['acreditat']}>acreditat</span>
						)}
						<img
							src={cover}
							className={clsx(styles['fav-img'])}
							height='160px'
							alt={title}
						/>
					</div>
				</LinkContainer>
				<div className='px-sm-3'>
					<Link className='h5 fw-normal text-dark' to={`/courses/${id}`}>
						{title}
					</Link>
					<div className='mt-2'>
						<span className={styles['courseTypeBadge']}>
							Curs{isOnline ? ' Online' : ' Fizic'}
						</span>
						{isAccredited && (
							<span
								className={clsx(styles['courseTypeBadge'], 'ms-2 d-sm-none')}
							>
								Acreditat
							</span>
						)}
						{selectedClass && (
							<span
								className={clsx({
									'mb-0 h6 ms-2 fw-bold': true,
									'text-danger': selectedClass.availableSeats < 5,
								})}
							>
								{selectedClass.availableSeats}/{selectedClass.totalSeats} Locuri
								disponibile
							</span>
						)}
						{!isOnline && (
							<p className='small'>
								Începe pe:
								<LocalizedDate
									date={startsOn}
									className='fw-bold fst-italic ms-1'
								/>
							</p>
						)}
						{classes && (
							<CourseClassTimeSelection
								courseId={id}
								classes={classes}
								selectedClassTime={selectedClassTime}
								onChange={updateSelectedClassTime}
								size='small'
							/>
						)}
					</div>
				</div>
				<div className={styles['right-col']}>
					<Currency
						className='h4 text-danger fw-bold'
						price={allowPayRemaingAmount ? outstandingAmount : price}
					/>
					{allowAddToCart &&
						(selectedClass ? selectedClass.availableSeats > 0 : true) && (
							<button
								className='btn btn-primary text-nowrap mt-2 mt-sm-auto'
								onClick={() => onAddToCart(id, selectedClassTime)}
								disabled={isCourseInCart}
							>
								{isCourseInCart ? 'Adăugat în coș' : 'Adaugă în coș'}
							</button>
						)}
					{allowPayRemaingAmount && (
						<button
							className='btn btn-dark text-nowrap mt-2 mt-sm-auto'
							onClick={() => onPayOutStandingAmout(id, selectedClassTime)}
						>
							Achită Restul de Plată
						</button>
					)}
					{allowedToCourseConsole && (
						<Link
							className='btn btn-primary text-nowrap mt-2 mt-sm-auto'
							to={`/courses/console/${id}${
								selectedClassTime ? '?class=' + selectedClassTime : ''
							}`}
						>
							Consolă Curs
						</Link>
					)}
					<span
						className={styles['fav-remove-item']}
						onClick={() => onRemoveFromFavorite(id)}
					>
						<BsTrash />
						Șterge
					</span>
				</div>
			</div>
		</div>
	);
};

export default FavoriteCourseItem;
