import clsx from 'clsx';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { selectPickupLocation } from '../../Store/checkout';
import CustomSelect from '../CustomSelect';
import {
	CheckboxField,
	PhoneInputField,
	SwitchInputField,
	TextInputField,
} from './InputField';
import { shippingLabels } from '../../StaticData';
import { AVAILABLE_COUNTRIES } from '../../utils/constants';

export const ShippingAddress = ({ isSelfPickup }) => {
	const pickupLocation = useSelector(selectPickupLocation);

	return (
		<div className={clsx('card', 'non-hoverable', 'border-0', 'px-4 pb-4')}>
			<div className='section-title border-bottom d-flex justify-content-between align-items-center'>
				<h3 className='title h5 fw-bold'>
					{isSelfPickup
						? shippingLabels.pickupLocation
						: shippingLabels.displayName}
				</h3>
				<div className='form-check form-switch form-check-reverse'>
					<Field
						name='shippingAddress.selfPickup'
						component={SwitchInputField}
						placeholder={shippingLabels.isPickup}
					/>
				</div>
			</div>
			{!isSelfPickup && (
				<div className='row g-3'>
					<div className='col-md-6'>
						<Field
							name='shippingAddress.name'
							component={TextInputField}
							label={shippingLabels.fullName}
						/>
					</div>
					<div className='col-md-6'>
						<Field
							name='shippingAddress.phone'
							component={PhoneInputField}
							label={shippingLabels.phone}
						/>
					</div>
					<div className='col-12'>
						<Field
							name='shippingAddress.address'
							component={TextInputField}
							label={shippingLabels.address}
						/>
					</div>
					<div className='col-lg-5 col-md-12'>
						<Field
							name='shippingAddress.city'
							component={TextInputField}
							label={shippingLabels.city}
						/>
					</div>
					<div className='col-lg-4 col-md-5'>
						<Field name='shippingAddress.country'>
							{({ field }) => (
								<>
									<label className='form-label'>{shippingLabels.country}</label>
									<CustomSelect
										placeholder={shippingLabels.country}
										options={AVAILABLE_COUNTRIES}
										selected={field.value}
										onChange={field.onChange}
									/>
								</>
							)}
						</Field>
					</div>
					<div className='col-lg-3 col-md-5'>
						<Field
							name='shippingAddress.zipCode'
							component={TextInputField}
							label={shippingLabels.zipCode}
						/>
					</div>
				</div>
			)}
			{isSelfPickup && (
				<div className=''>
					<p className='mb-1 h6'>
						{shippingLabels.address}:
						<a className='ms-2' href={pickupLocation.mapLocation}>
							{pickupLocation.address}, {pickupLocation.city}
						</a>
					</p>
					<p className='h6'>
						{shippingLabels.phone}: <span>{pickupLocation.phone}</span>
					</p>
				</div>
			)}
			<div className='row mt-3'>
				<div className='col-12'>
					<Field
						name='saveShippingPreferences'
						component={CheckboxField}
						label={shippingLabels.save}
					/>
				</div>
			</div>
		</div>
	);
};
