import clsx from 'clsx';
import { LocalizedTimeRange } from '../LocalizedTimeRange';

export const CourseClassTimeSelection = ({
	courseId,
	classes,
	selectedClassTime,
	onChange,
	size,
}) => {
	return (
		<div className='my-2'>
			{classes.map((classSession) => (
				<div className='form-check d-flex align-items-center gap-2'>
					<input
						className='form-check-input small flex-shrink-0'
						type='radio'
						checked={selectedClassTime === classSession.class}
						id={`${courseId}${classSession.class}`}
						onChange={() => onChange(classSession.class)}
					/>
					<label
						className={clsx('form-check-label', size === 'small' && 'small')}
						htmlFor={`${courseId}${classSession.class}`}
					>
						<div
							className={clsx(
								'd-flex align-items-center flex-wrap',
								size !== 'small' && 'small'
							)}
						>
							<span className='me-2'>{classSession.class}</span>
							<div className='mb-1'>
								(
								<LocalizedTimeRange
									className='fw-bold fst-italic'
									startTime={classSession.startTime}
									endTime={classSession.endTime}
								/>
								)
							</div>
						</div>
					</label>
				</div>
			))}
		</div>
	);
};
