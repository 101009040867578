import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { BsTrash, BsSliders } from 'react-icons/bs';
import { SelectedProdVariaties } from '../SelectedProdVariaties';
import { VariationsSelector } from './VariationsSelector';

import { addCartItemAsync } from '../../features/Cart';
import { removeFavoritesItemAsync } from '../../features/Favorites';
import { getProductItem } from '../../features/Products';
import { updateProductVariantPreference } from '../../features/User';
import {
	useSelectProductVarientStockByOptions,
	useSelectFirstAvailableVarient,
} from '../../hooks/productsStock';
import { calculateProductSku } from '../../utils/products.utils';

import './styles.scss';

export default function Favorites({
	productId,
	cover,
	title,
	price,
	stock,
	variations,
}) {
	const { cartItems } = useSelector((state) => state.cart);
	const [availableCurvature, availableThickness, availableLength] =
		useSelectFirstAvailableVarient(productId);
	const [curvature, setCurvature] = useState('');
	const [thickness, setThickness] = useState('');
	const [length, setLength] = useState('');

	const [showModal, setShowModal] = useState(false);

	const dispatch = useDispatch();
	const selectedVariantStock = useSelectProductVarientStockByOptions(
		productId,
		curvature,
		thickness,
		length
	);
	const productStock = selectedVariantStock
		? selectedVariantStock.available
		: stock;

	let badgeText = 'În stoc';
	let style = 'stock';
	if (productStock === 0) {
		style = 'stock active';
		badgeText = 'Stoc epuizat';
	} else if (productStock === 1) {
		badgeText = 'Ultimul produs';
		style = 'stock warning';
	} else if (productStock < 5) {
		badgeText = `Ultimele ${productStock} produse`;
		style = 'stock warning';
	}

	const addToCart = (e) => {
		e.preventDefault();
		// Calculate product sku and compare with cart products sku
		let sku = productId;
		if (variations) {
			sku = calculateProductSku(sku, curvature, thickness, length);
		}

		const cartItem =
			cartItems.length > 0
				? cartItems.some((element) => element.sku === sku)
				: false;
		if (!cartItem) {
			dispatch(getProductItem(productId)).then((result) => {
				console.log(result.payload);
				if (result.payload.stock > 0) {
					dispatch(
						addCartItemAsync({
							id: productId,
							sku: sku,
							variations: variations
								? {
										curvature,
										thickness,
										length,
								  }
								: null,
						})
					);

					dispatch(removeFavoritesItemAsync(productId));
				} else {
					toast.warning('Stoc epuizat');
				}
			});
		} else {
			toast.warning('Produsul se află deja in coșul tău.');
		}
	};

	const handleShowModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	const handleSaveVeriations = ({ newCurvature, newThickness, newLength }) => {
		setCurvature(newCurvature);
		setThickness(newThickness);
		setLength(newLength);
		const variant = [newCurvature, newThickness, newLength]
			.join('_')
			.replace(/ /g, '');
		dispatch(
			updateProductVariantPreference({
				productId: productId,
				productVariant: variant,
			})
		);
		handleCloseModal();
	};

	useEffect(() => {
		if (variations) {
			availableCurvature && setCurvature(availableCurvature);
			availableThickness && setThickness(availableThickness);
			availableLength && setLength(availableLength);
		}
	}, [availableCurvature, availableThickness, availableLength]);

	return (
		<div className='position-relative overflow-hidden'>
			<article className='favorite-item d-flex flex-sm-row h-100 py-2 my-2 border-bottom'>
				<LinkContainer to={`/${productId}`}>
					<img
						src={cover}
						className='fav-img mx-auto mx-sm-0'
						height='160px'
						alt={title}
					/>
				</LinkContainer>
				<div className='favorite-item-title'>
					<Link className='title-text' to={`/${productId}`}>
						{title}
					</Link>
					{variations && (
						<div className='d-flex align-items-center'>
							<SelectedProdVariaties
								curvature={curvature}
								thickness={thickness}
								length={length}
							/>
							<button className='btn btn-sm' onClick={handleShowModal}>
								<BsSliders className='default' size={16} />
							</button>
						</div>
					)}
				</div>
				<div className='right-col'>
					<p className={style}>{badgeText}</p>
					<h4 className='fav-item-price'>{`${price} Lei`}</h4>
					<Button
						className='text-nowrap add-btn'
						disabled={productStock === 0}
						variant='primary'
						onClick={addToCart}
					>
						Adaugă în coș
					</Button>{' '}
					<span
						className='fav-remove-item'
						onClick={() => {
							dispatch(removeFavoritesItemAsync(productId));
						}}
					>
						<BsTrash />
						Șterge
					</span>
				</div>
			</article>
			{variations && (
				<VariationsSelector
					isVisible={showModal}
					variations={variations}
					selectedCurvature={curvature}
					slectedThickness={thickness}
					selectedLength={length}
					onCancel={handleCloseModal}
					onSave={handleSaveVeriations}
				/>
			)}
		</div>
	);
}
