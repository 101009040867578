import clsx from 'clsx';
import { useRef } from 'react';
import { BsX } from 'react-icons/bs';
import ReactPlayer from 'react-player';

import styles from './CourseDetail.module.scss';

const CoursePreview = ({
	modelVisible,
	closePreviewModal,
	previewVideoUrl,
}) => {
	const playerRef = useRef();

	const onClose = () => {
		if (playerRef.current) {
			playerRef.current.seekTo(0);
		}
		closePreviewModal();
	};

	return (
		<div
			className={clsx({
				[styles.videoPreviewModalContainer]: true,
				[styles.open]: modelVisible,
			})}
		>
			<div className={styles['videoPreviewBackdrop']}>
				<div className={styles['previewModalInner']}>
					<ReactPlayer
						url={previewVideoUrl}
						ref={playerRef}
						controls={true}
						playing={modelVisible}
						width='100%'
						height='100%'
						config={{
							file: {
								attributes: {
									disablePictureInPicture: true,
									controlsList: 'nodownload',
								},
							},
						}}
					/>
					<button className={clsx('btn', styles.crossBtn)} onClick={onClose}>
						<BsX strokeWidth={0.5} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default CoursePreview;
