import { useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsEyeFill, BsEyeSlashFill, BsInfoCircleFill } from 'react-icons/bs';
import { auth } from '../../Firebase';
import {
	updatePassword,
	reauthenticateWithCredential,
	EmailAuthProvider,
} from 'firebase/auth';
import { passwordValidations } from './formValidations';
import { generateRandomPassword } from '../../utils/randomPassword';
import { getFirebaseErrorMessageByCode } from '../../utils/firebaseErrors';

import styles from './MyAccount.module.scss';

const PasswordUpdateFrom = ({ closeModal }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [currentPassowrdVisible, setCurrentPassowrdVisible] = useState(false);
	const [passowrdVisible, setPassowrdVisible] = useState(false);
	const [confirmPassowrdVisible, setConfirmPassowrdVisible] = useState(false);

	const submitForm = async ({ currentPassword, newPassword }) => {
		try {
			setIsLoading(true);
			const user = auth.currentUser;

			const credential = EmailAuthProvider.credential(
				user.email,
				currentPassword
			);

			const authResult = await reauthenticateWithCredential(user, credential);
			if (!authResult.user) {
				toast.error('Please provide correct password');
				setIsLoading(false);
				return;
			}

			await updatePassword(user, newPassword);
			toast.success('Password has been updated successfully.');
			closeModal();
		} catch (error) {
			console.log('ERROR', error);
			const errorMessage = getFirebaseErrorMessageByCode(error.code);
			toast.error(errorMessage);
			setIsLoading(false);
		}
	};

	const { values, handleChange, handleSubmit, setFieldValue, touched, errors } =
		useFormik({
			initialValues: {
				currentPassword: '',
				newPassword: '',
				confirmPassword: '',
			},
			validationSchema: passwordValidations,
			onSubmit: submitForm,
		});

	const onGenerateRandomPassword = () => {
		const generatedPass = generateRandomPassword();
		setFieldValue('newPassword', generatedPass);
	};

	const renderPasswordRulesTooltip = (props) => (
		<Tooltip {...props}>
			<div className='text-start'>
				<p className='mb-1 fw-bold text-tiny'>
					Parola trebuie să aibă cel puțin:
				</p>
				<p className='mb-0'>8 personaje</p>
				<p className='mb-0'>1 caracter mic</p>
				<p className='mb-0'>1 caracter mare</p>
				<p className='mb-0'>1 caracter special</p>
				<p className='mb-0'>1 cifră</p>
			</div>
		</Tooltip>
	);

	return (
		<div className=''>
			<form onSubmit={handleSubmit}>
				<div className='form-group'>
					<label>Current Password</label>
					<div className='input-group position-relative mt-1'>
						<input
							name='currentPassword'
							type={confirmPassowrdVisible ? 'text' : 'password'}
							className={clsx('form-control', styles.passwordInput)}
							placeholder='Current Password'
							value={values.currentPassword}
							onChange={handleChange}
						/>
						<button
							className='btn btn-sm position-absolute h-100 top-0 end-0'
							type='button'
							onClick={() => setCurrentPassowrdVisible(!currentPassowrdVisible)}
						>
							{currentPassowrdVisible ? (
								<BsEyeSlashFill className='default' size={16} />
							) : (
								<BsEyeFill className='default' size={16} />
							)}
						</button>
					</div>
					{touched.currentPassword && errors.currentPassword && (
						<span className='text-danger tiny-text'>
							{errors.currentPassword}
						</span>
					)}
				</div>

				<div className='form-group'>
					<label>New Password</label>
					<div className='input-group position-relative mt-1'>
						<input
							name='newPassword'
							type={passowrdVisible ? 'text' : 'password'}
							className={clsx('form-control', styles.passwordInput)}
							placeholder='Parola'
							value={values.newPassword}
							onChange={handleChange}
						/>
						<button
							className='btn btn-sm position-absolute h-100 top-0 end-0'
							type='button'
							onClick={() => setPassowrdVisible(!passowrdVisible)}
						>
							{passowrdVisible ? (
								<BsEyeSlashFill className='default' size={16} />
							) : (
								<BsEyeFill className='default' size={16} />
							)}
						</button>
					</div>
					{touched.newPassword && errors.newPassword ? (
						<span className='tiny-text text-danger'>{errors.newPassword}</span>
					) : (
						<p className='text-muted mb-0 ms-0 mt-1 tiny-text d-flex align-center gap-1'>
							Vă rugăm să alegeți o parolă puternică și sigură.
							<span className=''>
								<OverlayTrigger
									placement='top'
									overlay={renderPasswordRulesTooltip}
								>
									<span>
										<BsInfoCircleFill className='default' size={12} />
									</span>
								</OverlayTrigger>
							</span>
						</p>
					)}
					<div>
						<button
							type='button'
							className='btn btn-sm text-primary px-0 fw-bold'
							onClick={onGenerateRandomPassword}
						>
							Generați parola
						</button>
					</div>
				</div>

				<div className='form-group'>
					<label>Confirmă parola</label>
					<div className='input-group position-relative mt-1'>
						<input
							name='confirmPassword'
							type={confirmPassowrdVisible ? 'text' : 'password'}
							className={clsx('form-control', styles.passwordInput)}
							placeholder='Confirmă parola'
							value={values.confirmPassword}
							onChange={handleChange}
						/>
						<button
							className='btn btn-sm position-absolute h-100 top-0 end-0'
							type='button'
							onClick={() => setConfirmPassowrdVisible(!confirmPassowrdVisible)}
						>
							{confirmPassowrdVisible ? (
								<BsEyeSlashFill className='default' size={16} />
							) : (
								<BsEyeFill className='default' size={16} />
							)}
						</button>
					</div>
					{touched.confirmPassword && errors.confirmPassword && (
						<span className='text-danger tiny-text'>
							{errors.confirmPassword}
						</span>
					)}
				</div>

				<div className='d-flex gap-2 mt-3'>
					<button className='btn btn-primary text-white' type='submit'>
						Update Password
					</button>
					<button
						className='btn btn-secondary'
						type='button'
						onClick={closeModal}
					>
						Cancel
					</button>
				</div>
			</form>
			{isLoading && (
				<div className={styles.loadingOverlay}>
					<div className='spinner-border text-primary' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
					<div className='message'>Processing...</div>
				</div>
			)}
		</div>
	);
};

export default PasswordUpdateFrom;
