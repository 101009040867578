const AcountUpdateActions = ({ onUpdateProfile, onUpdatePassword, onVerificationEmail }) => {
	return (
		<div className='d-flex flex-column gap-2'>
			<button
				className='btn btn-outline-primary btn-sm'
				onClick={onUpdateProfile}
			>
				Modifică Profil
			</button>
			<button
				className='btn btn-outline-primary btn-sm'
				onClick={onUpdatePassword}
			>
				Modifică Parola
			</button>
			<button
				className='btn btn-outline-primary btn-sm'
				onClick={onVerificationEmail}
			>
				Verifică Email
			</button>
		</div>
	);
};

export default AcountUpdateActions;
