import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { LocalizedDate } from '../../components/LocalizedDate';
import { Currency } from '../../components/Currency';
import { LocalizedTimeRange } from '../../components/LocalizedTimeRange';

import styles from './MyCourses.module.scss';

export const MyCourseCard = ({
	courseId,
	cover,
	title,
	orderDate,
	isAccredited,
	isOnline,
	startsOn,
	expiresOn,
	courseExpiryText,
	courseEndsOn,
	completedOn,
	classTime,
	classStartTime,
	classEndTime,
	additionalAccess,
	price,
	paidFullAmount,
	completionPercentage,
	isActiveCourse,
	onPayOutstandingFee,
}) => {
	return (
		<div className='col-12 my-3'>
			<div className={clsx('card', 'non-hoverable', 'border-0', 'p-4')}>
				<div className='row mx-0'>
					<div className='col-sm-3 ps-0 d-none d-sm-block'>
						<img className='img-fluid' src={cover} alt={title} />
					</div>
					<div className='col-sm-9 d-flex flex-column justify-content-between px-0'>
						<div className='d-flex justify-content-between'>
							<div className='flex-grow-1'>
								<p className='text-muted mb-1'>
									Înscris pe:
									<LocalizedDate className='fst-italic ms-1' date={orderDate} />
								</p>
								<p className='h5'>{title}</p>
								<div className=''>
									{isAccredited && (
										<span className={styles.acrediationBadge}>Acridated</span>
									)}
									<span className={styles.courseTypeBadge}>
										{isOnline ? 'Online ' : 'Fizic '}
										Course
									</span>
								</div>
								{expiresOn && (
									<p className='mb-1'>
										{isActiveCourse ? 'Expira la' : 'A expirat pe'}:
										<LocalizedDate
											className='fst-italic ms-1 fw-bold'
											date={expiresOn}
										/>
										{isActiveCourse && (
											<span className='ms-1 small text-muted'>
												({courseExpiryText(expiresOn)} remaining)
											</span>
										)}
									</p>
								)}
								{startsOn && (
									<p className='mb-1'>
										{isActiveCourse ? 'Începe' : 'A început pe'}:
										<LocalizedDate
											className='fst-italic ms-1 fw-bold'
											date={startsOn}
										/>
									</p>
								)}
								{courseEndsOn && (
									<p className='mb-1'>
										Se încheie pe:
										<LocalizedDate
											className='fst-italic ms-1 fw-bold'
											date={courseEndsOn}
										/>
									</p>
								)}
								{completedOn && (
									<p className='mb-1'>
										S-a încheiat pe:
										<LocalizedDate
											className='fst-italic ms-1 fw-bold'
											date={completedOn}
										/>
									</p>
								)}
								{classTime && (
									<div className={clsx('d-flex align-items-center flex-wrap')}>
										<span className='me-2'>Class:</span>
										<span className='me-2 fw-bold fst-italic'>{classTime}</span>
										<div className='mb-1'>
											(
											<LocalizedTimeRange
												className='fw-bold fst-italic'
												startTime={classStartTime}
												endTime={classEndTime}
											/>
											)
										</div>
									</div>
								)}
								{additionalAccess && (
									<p className='mb-1'>
										{isActiveCourse
											? 'Accesul suplimentar expiră'
											: 'Acces suplimentar a expirat'}
										:
										<LocalizedDate
											className='fst-italic ms-1 fw-bold'
											date={additionalAccess}
										/>
										{isActiveCourse && (
											<span className='ms-1 small text-muted'>
												({courseExpiryText(additionalAccess)} remaining)
											</span>
										)}
									</p>
								)}
							</div>
							<div className='text-end flex-shrink-0 ms-2'>
								<Currency className='lead text-primary fw-bold' price={price} />
								<p className='text-muted small'>
									{paidFullAmount ? 'Achitat integral' : 'Achitat avans'}
								</p>
							</div>
						</div>
						<div className='mt-3 text-end'>
							<Link
								className={clsx(
									styles.actionButton,
									'btn btn-outline-primary me-2'
								)}
								to={`/courses/${courseId}${
									classTime ? '?class=' + classTime : ''
								}`}
							>
								Detalii curs
							</Link>
							{isActiveCourse && (
								<>
									{!!paidFullAmount ? (
										<Link
											className={clsx(
												styles.actionButton,
												'btn btn-primary text-white px-4'
											)}
											to={`/courses/console/${courseId}${
												classTime ? '?class=' + classTime : ''
											}`}
										>
											Consolă curs
										</Link>
									) : (
										<button
											className={clsx(
												styles.actionButton,
												'btn btn-dark text-white'
											)}
											onClick={() => onPayOutstandingFee(courseId, classTime)}
										>
											Achită Restul de Plată
										</button>
									)}
								</>
							)}
							{!!completionPercentage && (
								<div className='mt-3 text-start'>
									<p className='mb-0 fw-bold'>
										{completionPercentage}% Complet
									</p>
									<div className='progress rounded-0 flex-grow-1'>
										<div
											className='progress-bar'
											style={{
												width: `${completionPercentage}%`,
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
