import { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';
import { BsHeart, BsHeartFill, BsStarFill } from 'react-icons/bs';
import { Currency } from '../Currency';
import { LocalizedDate } from '../LocalizedDate';
import { CourseClassTimeSelection } from '../CourseClassTimeSelection';
import { toggleCourseItemFavorite } from '../../features/Favorites';
import {
	useIsCourseInFavorites,
	useSelectFirstAvailableClass,
} from '../../hooks/courses';

import styles from './CourseCardFlat.module.scss';
import { updateCourseClassTimePreference } from '../../features/User';

const CourseCardFlat = ({
	id,
	cover,
	title,
	rating,
	reviewCount,
	price,
	oldPrice,
	acreditat,
	startDate,
	isOnline,
	courseLocation,
	classes,
}) => {
	const navigate = useNavigate();
	const [isFavorite, setIsFavorite] = useState(false);
	const [selectedClassTime, setSelectedClassTime] = useState('');
	const isAlreadyInFavourites = useIsCourseInFavorites(id);
	const preferredClassTime = useSelectFirstAvailableClass(id, classes);

	const dispatch = useDispatch();

	useEffect(() => {
		setIsFavorite(isAlreadyInFavourites);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAlreadyInFavourites]);

	useEffect(() => {
		if (preferredClassTime) {
			setSelectedClassTime(preferredClassTime);
		}
	}, [preferredClassTime]);

	const selectedClass = useMemo(() => {
		if (classes && selectedClassTime) {
			return classes.find((item) => item.class === selectedClassTime);
		}
	}, [classes, selectedClassTime]);

	const onViewDetail = () => {
		navigate(`/courses/${id}`);
	};

	const toggleFavorites = async (e) => {
		e.preventDefault();
		const result = await dispatch(
			toggleCourseItemFavorite({
				itemId: id,
				operationType: isFavorite ? 'remove' : 'add',
			})
		);
		if (!result.error) {
			setIsFavorite(!isFavorite);
		}
	};

	const updateSelectedClassTime = async (classTime) => {
		dispatch(
			updateCourseClassTimePreference({
				courseId: id,
				selectedClassTime: classTime,
			})
		);
		setSelectedClassTime(classTime);
	};

	return (
		<div className={clsx(styles.card)}>
			<LinkContainer to={`/courses/${id}`}>
				<div className={styles['cover-wrapper']}>
					{acreditat && (
						<span className={clsx(styles.acreditat, 'text-primary')}>
							acreditat
						</span>
					)}
					<img src={cover} className={clsx(styles['card-image'])} alt={title} />
					{oldPrice > 0 && (
						<div className={clsx(styles['card-badge-price'])}>
							<Currency price={price - oldPrice} />
						</div>
					)}
				</div>
			</LinkContainer>
			<div className={styles['content-main']}>
				<div className={clsx(styles['card-details'])}>
					<div className=''>
						<Link
							className={clsx(styles['card-title'], 'h5')}
							to={`/courses/${id}`}
						>
							{title.substring(0, 70)}
							{title.length > 70 && <span>...</span>}
						</Link>

						<div className='my-2'>
							<p className={styles.courseTypeBadge}>
								Curs {isOnline ? 'Online' : 'Fizic'}
							</p>
							{selectedClass && (
								<p
									className={clsx({
										'fw-bold mb-1': true,
										'text-danger': selectedClass.availableSeats < 5,
									})}
								>
									{selectedClass.availableSeats} Locuri disponibile
								</p>
							)}
							{courseLocation && (
								<p className='small mb-1'>
									<span>Locație:</span>
									<span className='fw-bold ms-1 fst-italic'>
										{courseLocation}
									</span>
								</p>
							)}
							{!isOnline && (
								<p className='small mb-1'>
									Începe pe:{' '}
									<LocalizedDate
										date={startDate}
										className='fw-bold fst-italic'
									/>
								</p>
							)}
							{classes && (
								<CourseClassTimeSelection
									courseId={id}
									classes={classes}
									selectedClassTime={selectedClassTime}
									onChange={updateSelectedClassTime}
									size='small'
								/>
							)}
						</div>
						<div className={clsx(styles['card-stats'])}>
							<BsStarFill
								className={clsx('default text-primary')}
								size={'14px'}
							/>
							<span className='mx-1'>{rating}</span>
							<span>({reviewCount})</span>
						</div>
					</div>
					<div>
						{oldPrice > 0 && (
							<del className={clsx(styles['product-old-price'])}>
								<Currency price={oldPrice} />
							</del>
						)}
						<h4 className={clsx(styles['card-price'])}>
							<Currency price={price} />
						</h4>
					</div>
				</div>
				<div className={clsx(styles['buttons'])}>
					<div className='pe-2'>
						{isFavorite ? (
							<BsHeartFill
								className={clsx(styles['heart'], styles['active'])}
								onClick={toggleFavorites}
							/>
						) : (
							<BsHeart onClick={toggleFavorites} />
						)}
					</div>
					<div>
						<button
							className='btn btn-primary btn-lg text-nowrap text-white'
							onClick={onViewDetail}
						>
							Vezi detalii
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CourseCardFlat;
