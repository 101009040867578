export const LocalizedDate = ({
	date,
	className,
	dateStyle = 'long',
	timeStyle,
}) => {
	return (
		<span className={className}>
			{new Intl.DateTimeFormat('ro-RO', {
				dateStyle: dateStyle,
				timeStyle: timeStyle,
			}).format(new Date(date))}
		</span>
	);
};
