import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { clearCartAsync } from '../features/Cart';
import { fireDB, functions } from '../Firebase';
import { toast } from 'react-toastify';
import { loadProductsVariationsStock } from './productsVariationsStock';
import { extractAndParseValue } from '../utils/general.utils';
import { calculateCourseSku } from '../utils/courses.utils';

const initialState = {
	loading: false,
	error: null,
	checkoutData: null,
	paymentData: null,
	orderSuccessData: null,
	isQuickCheckout: false,
	quickOrderType: null, //null, full, registration, outstanding
	courseToProcess: null,
	courseToProcessClassTime: '',
	pickupLocation: {
		phone: '+40 757 648 448',
		address: 'Bd. Regele Mihai I nr. 23 (Eurohotel)',
		city: 'Baia Mare',
		mapLocation:
			'https://www.google.com/maps/place/Bulevardul+Bucure%C8%99ti+23,+Baia+Mare+430251/@47.6530503,23.559401,18.35z/data=!4m5!3m4!1s0x4737dc4092eb3b89:0x2296c37eb098c907!8m2!3d47.6530724!4d23.560133',
	},
};

export const proceedCheckout = createAsyncThunk(
	'checkout/proceed',
	async (checkoutData, { dispatch, getState }) => {
		dispatch(setCheckoutData(checkoutData));

		const { cartItemsList } = getState().cart;
		const { pickupLocation } = getState().checkout;

		if (checkoutData.isPrepaid === 'true') {
			return {
				nextRoute: './card',
			};
		}

		const processNonPrepaidOrder = httpsCallable(
			functions,
			'processNonPrepaidOrder'
		);

		const { data: result } = await processNonPrepaidOrder({
			checkoutData,
			pickupLocation,
			order: {
				products: cartItemsList,
				courses: [],
			},
		});
		if (!result.success) {
			toast.error(result.message);
			throw result;
		}

		dispatch(clearCartAsync());
		dispatch(setOrderSuccessData(result.orderData));
		dispatch(setCheckoutData(null));
		dispatch(loadProductsVariationsStock());
		return {
			nextRoute: '/order-success',
		};
	}
);

export const generatePaymentIntent = createAsyncThunk(
	'checkout/paymentIntent',
	async (_, { getState, rejectWithValue }) => {
		const { cartItemsList } = getState().cart;
		const { pickupLocation, checkoutData } = getState().checkout;
		const { coursesInCart } = getState().coursesCart;

		const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');

		const { data: result } = await createPaymentIntent({
			checkoutData,
			pickupLocation,
			order: {
				products: cartItemsList,
				courses: coursesInCart,
			},
		});

		if (!result.success) {
			return rejectWithValue(result);
		}
		return result;
	}
);

export const generateQuickCourseOrderPaymentIntent = createAsyncThunk(
	'checkout/quickCoursePaymentIntent',
	async (_, { getState, rejectWithValue }) => {
		const {
			pickupLocation,
			checkoutData,
			quickOrderType,
			courseToProcess,
			courseToProcessClassTime,
		} = getState().checkout;

		if (!courseToProcess || !quickOrderType) {
			toast.error(
				'Niciun curs selectat, vă rugăm să selectați mai întâi un curs.'
			);
			throw null;
		}

		const createQuickCoursePaymentIntent = httpsCallable(
			functions,
			'createQuickCoursePaymentIntent'
		);

		const courseSku = calculateCourseSku(
			courseToProcess.id,
			courseToProcessClassTime
		);

		const { data: result } = await createQuickCoursePaymentIntent({
			checkoutData,
			pickupLocation,
			quickOrderType,
			course: courseSku,
		});

		if (!result.success) {
			return rejectWithValue(result);
		}
		return result;
	}
);

export const fetchOrderByPaymentId = createAsyncThunk(
	'checkout/fetchOrderByPayment',
	async (paymentId, { dispatch }) => {
		dispatch(clearCartAsync());
		dispatch(setCheckoutData(null));

		const docRef = doc(fireDB, 'payments', paymentId);
		const docSnap = await getDoc(docRef);
		const { grandTotal, orderTrackingId } = docSnap.data();

		dispatch(
			setOrderSuccessData({
				total: grandTotal,
				trackingId: orderTrackingId,
			})
		);
		return {
			nextRoute: '/order-success',
		};
	}
);

const slice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		setCheckoutData: (state, { payload }) => {
			state.checkoutData = payload;
		},
		setOrderSuccessData: (state, { payload }) => {
			state.orderSuccessData = payload;
		},
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setQuickCheckoutCourse: (state, { payload }) => {
			state.isQuickCheckout = extractAndParseValue(
				payload,
				'isQuickCheckout',
				false
			);
			state.quickOrderType = extractAndParseValue(
				payload,
				'quickOrderType',
				null
			);
			state.courseToProcess = extractAndParseValue(payload, 'course', null);
			state.courseToProcessClassTime = extractAndParseValue(
				payload,
				'selectedClassTime',
				''
			);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(proceedCheckout.pending, (state, action) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(proceedCheckout.fulfilled, (state, action) => {
			state.loading = false;
		});
		builder.addCase(proceedCheckout.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload || 'Failed to process the checkout.';
		});
		builder.addCase(fetchOrderByPaymentId.rejected, (state, action) => {
			console.error('fetchOrderByPaymentId:::', action);
		});
	},
});

export const {
	setCheckoutData,
	setQuickCheckoutCourse,
	setOrderSuccessData,
	setLoading,
} = slice.actions;

// SELECTORS
const selectCheckout = (state) => {
	return state.checkout;
};
export const selectIsCheckoutLoading = createSelector(
	selectCheckout,
	(checkout) => checkout.loading
);
export const selectIsCheckoutError = createSelector(
	selectCheckout,
	(checkout) => checkout.error
);
export const selectCheckoutData = createSelector(
	selectCheckout,
	(checkout) => checkout.checkoutData
);
export const selectHasShipping = createSelector(
	selectCheckoutData,
	(checkoutData) => {
		if (
			!checkoutData ||
			!checkoutData.shippingAddress ||
			checkoutData.shippingAddress.selfPickup
		) {
			return false;
		}
		return true;
	}
);
export const selectPaymentData = createSelector(
	selectCheckout,
	(checkout) => checkout.paymentData
);
export const selectOrderSuccessData = createSelector(
	selectCheckout,
	(checkout) => checkout.orderSuccessData
);
export const selectPickupLocation = createSelector(
	selectCheckout,
	(checkout) => checkout.pickupLocation
);

export const selectQuickCheckout = createSelector(
	selectCheckout,
	(checkout) => {
		return {
			isQuickCheckout: checkout.isQuickCheckout,
			quickOrderType: checkout.quickOrderType,
			courseToProcess: checkout.courseToProcess,
			courseToProcessClassTime: checkout.courseToProcessClassTime,
		};
	}
);

export default slice.reducer;
