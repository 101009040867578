export const bannerItems = [
	{
		id: 'pw43t2gp9j',
		row1: 'Sancos Academy',
		row2: 'Marcă înregistrată',
	},
	{
		id: 'p3m20slp9j',
		row1: 'Cost livrare 19 Lei',
		row2: 'GRATUITĂ la comenzi de 299 lei',
	},
	{
		id: 'p3mot2gp9j',
		row1: '14 zile drept de retur',
	},
];

export const shippingDelayMessage = "Comenzile plasate în perioada 30 Martie - 5 Aprilie vor fi procesate începând cu data de 6 Aprilie.";

export const filterBy = {
	displayName: 'Ordonează după:',
	ascendingPrice: 'Preț ascendent',
	descendingPrice: 'Preț descendent',
};

export const billingLabels = {
	displayName: 'Adresă facturare',
	isBusiness: 'Persoană juridică',
	fullName: 'Nume',
	phone: 'Telefon',
	address: 'Adresă',
	city: 'Oraș',
	country: 'Țară',
	zipCode: 'Cod poștal',
	save: 'Salvează datele',
	vat: 'CUI',
};

export const orderSummaryLabels = {
	title: 'Sumar comandă',
	subTitle: 'Produse',
	total: 'Total',
	shippingCost: 'Cost transport:',
	cod: 'Cost plată ramburs:',
	next: 'Pasul următor',
	pay: 'Comandă',
};

export const shippingLabels = {
	displayName: 'Adresă livrare',
	isPickup: 'Ridicare personală',
	pickupLocation: 'Punct colectare',
	fullName: 'Nume',
	phone: 'Telefon',
	address: 'Adresă',
	city: 'Oraș',
	country: 'Țară',
	zipCode: 'Cod poștal',
	save: 'Salvează datele',
	vat: 'CUI',
};

export const paymentTypeLabels = {
	card: 'Card online',
	cod: 'Ramburs la curier',
	pos: 'POS la sediu'
};

export const orderPaymentLabels = {
	displayName: 'Detalii plată',
	test: 'Introdu datele cardului în câmpurile de mai jos.',
	cardNumber: 'Numărul cardului',
	expDate: 'Data de expirare',
	cvc: 'CVC',
	pay: 'Plătește',
};

export const termsAndConditionsLabels = {
	text: 'Am citit și accept',
	title: 'termenii și condițiile',
};
export const checkoutLabels = {
	home: 'Acasă',
	orderDetails: 'Detalii comandă',
};

export const footerLabels = {
	contactDisplayName: 'Date de contact',
	contactTitle: 'Sancos Academy',
	contactAddress: 'Bd. Regele Mihai I nr. 23, Baia Mare',
	contactPhone: '+40 757 648 448',
	contactEmail: 'office.sancosacademy@gmail.com',
	vatLableAndNo: 'CUI: 39953215',
	ONRCLabelAndNo: 'Reg. Com.: J24/1501/2018',
	infoDisplayName: 'Informații',
	infoAbout: 'Despre noi',
	infoGDPR: 'GDPR',
	infoTermsAndConditions: 'Termeni și condiții',
	infoANPC: 'ANPC',
	infoSAL: 'SAL',
	optioonsDisplayName: 'Opțiuni utilizatori',
	optioonsMyAccount: 'Contul meu',
	optioonsMyCart: 'Coșul meu',
	optioonsFavorites: 'Favorite',
	optioonsOrders: 'Istoric comenzi',
	optioonsHelp: 'Ajutor',
	businessName: 'S.C. Biomaser Sancos S.R.L.',
	businessSchedule: 'Program de lucru: Luni - Vineri 10:00 - 17:00',
};

export const messages = {
	allStockAvailed: 'You have added all the stock items.',
	maxStockAvailed:
		'You have reached the maximum orders limit for this product.',
	noVariantSelected:
		'Please select the product variation before adding to cart.',
};
