import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { functions } from '../../Firebase';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';

// const localItems = localStorage.getItem('cart-items')
// const localItemsList = localStorage.getItem('cart-itemsList')
const initialState = {
	// cartItems: localItems ? JSON.parse(localItems) : [],
	// cartItemsList: localItemsList ? JSON.parse(localItemsList) : [],
	cartItems: [],
	cartItemsList: [],
	amount: 0,
	total: 0,
	shipping: 19,
	codCharges: 5,
	syncCart: false,
	isLoading: false,
	postCartItemsLoading: false,
	addToCart: {
		isLoading: false,
	},
	increaseQty: {
		isLoading: false,
	},
};
const newItem = (
	productId,
	cover,
	title,
	price,
	stock,
	maxLimitOrder,
	amount,
	sku,
	variations
) => {
	return {
		productId,
		cover,
		title,
		price,
		stock,
		maxLimitOrder,
		amount,
		sku,
		variations,
	};
};
export const generateCartListAsync = createAsyncThunk(
	'cart/generateCartListAsync',
	async (data, { getState, dispatch }) => {
		const productsObj = getState().products.productItemsObj;
		const list = getState().cart.cartItems;
		const { variationsStocks } = getState().productsVariationsStock;

		let cartList = [];
		for (const item of list) {
			const productItem = productsObj[item.productId];

			// Get Stock By the SKU if it has variations
			const hasVariations = !!item.variations;
			const variationStocks = variationsStocks[item.sku];

			const productStock =
				hasVariations && variationStocks
					? variationStocks.available
					: productItem.stock;

			const amount = productStock > item.amount ? item.amount : productStock;
			const newitem = newItem(
				productItem.id,
				productItem.cover,
				productItem.title,
				productItem.price,
				productItem.stock,
				productItem.maxLimitOrder,
				amount,
				item.sku,
				item.variations
			);
			if (productStock > 0) {
				cartList.push(newitem);
			}
		}
		// dispatch(setCartItemsList(cartList))
		dispatch(calculateTotals(JSON.stringify(cartList)));
		return cartList;
	}
);
export const getCartItemsAsync = createAsyncThunk(
	'cart/getCartItemsAsync',
	async (data, thunkAPI) => {
		const getCart = httpsCallable(functions, 'getCart');
		return getCart()
			.then((result) => {
				thunkAPI.dispatch(generateCartListAsync());
				return result.data;
			})
			.catch((err) => {
				console.log(err);
				return err;
			});
	}
);
export const addCartItemAsync = createAsyncThunk(
	'cart/addCartItemAsync',
	async (data, thunkAPI) => {
		const user = thunkAPI.getState().user.userInfo;
		const item = { productId: data.id, amount: 1, sku: data.sku };
		if (data.variations) {
			item.variations = data.variations;
		}
		if (user) {
			const addCartItem = httpsCallable(functions, 'addCartItem');
			return addCartItem(item)
				.then((result) => {
					toast.success('Produsul a fost adăugat în coș');
					thunkAPI.dispatch(addItem(item));
					return result;
				})
				.catch((err) => {
					console.log(err);
					toast.error(
						'Produsul nu a putut fi adăugat în coș. Încearcă mai târziu.'
					);
					return err;
				});
		} else {
			toast.success('Produsul a fost adăugat în coș');
			thunkAPI.dispatch(addItem(item));
			return;
		}
	}
);

export const addMutltipleQuantityToCart = createAsyncThunk(
	'cart/addMutltipleQuantityToCart',
	async (data, thunkAPI) => {
		const state = thunkAPI.getState();
		const user = state.user.userInfo;
		const cartProductsList = [...state.cart.cartItems];

		let item = { productId: data.productId, amount: data.quantity };
		if (data.variations) {
			item.variations = data.variations;

			let sku = `${data.productId}_${data.variations.curvature}_${data.variations.thickness}_${data.variations.length}`;
			sku = sku.replace(/ /g, '');
			item.sku = sku;
		} else {
			item.sku = data.productId;
		}

		const cartProductIndex = cartProductsList.findIndex(
			(cartItem) => cartItem.sku === item.sku
		);

		// If product already in cart, update the quantity
		// else add the product with quantity
		if (cartProductIndex !== -1) {
			cartProductsList[cartProductIndex] = {
				...cartProductsList[cartProductIndex],
				amount: cartProductsList[cartProductIndex].amount + data.quantity,
			};
			item.amount = cartProductsList[cartProductIndex].amount;
		} else {
			cartProductsList.push(item);
		}

		if (user) {
			const addCartItems = httpsCallable(functions, 'addCartItems');
			return addCartItems([item])
				.then((result) => {
					thunkAPI.dispatch(setCartItems(cartProductsList));
					return result;
				})
				.catch((err) => {
					console.log(err);
					return err;
				});
		} else {
			thunkAPI.dispatch(setCartItems(cartProductsList));
			toast.success('Produsul a fost adăugat în coș');
			return;
		}
	}
);

export const increaseQtyAsync = createAsyncThunk(
	'cart/incrementQtyAsync',
	async (data, thunkAPI) => {
		const user = thunkAPI.getState().user.userInfo;
		if (user) {
			const increaseItemQty = httpsCallable(functions, 'increaseItemQty');
			return increaseItemQty(data)
				.then((result) => {
					thunkAPI.dispatch(increase(data));
					return result;
				})
				.catch((err) => {
					console.log(err);
					toast.error(
						'Cantitatea produsului nu a putut fi modificată. Încercați mai târziu sau contactați-ne.'
					);
					return err;
				});
		} else thunkAPI.dispatch(increase(data));
	}
);
export const decreaseQtyAsync = createAsyncThunk(
	'cart/decreaseQtyAsync',
	async (data, thunkAPI) => {
		const user = thunkAPI.getState().user.userInfo;
		thunkAPI.dispatch(decrease(data));
		if (user) {
			const decreaseItemQty = httpsCallable(functions, 'decreaseItemQty');
			return decreaseItemQty(data)
				.then((result) => {
					return result;
				})
				.catch((err) => {
					console.log(err);
					return err;
				});
		}
		return;
	}
);
export const removeCartItemAsync = createAsyncThunk(
	'cart/removeCartItemAsync',
	async (data, thunkAPI) => {
		const user = thunkAPI.getState().user.userInfo;
		thunkAPI.dispatch(removeItem(data));
		if (user) {
			const removeCartItem = httpsCallable(functions, 'removeCartItem');
			return removeCartItem(data)
				.then((result) => {
					return result;
				})
				.catch((err) => {
					console.log(err);
					return err;
				});
		}
		return;
	}
);
export const clearCartAsync = createAsyncThunk(
	'cart/clearCartAsync',
	async (data, { getState, dispatch }) => {
		const user = getState().user.userInfo;
		dispatch(clearCart());
		if (user) {
			const clearCart = httpsCallable(functions, 'clearCart');
			return clearCart(data)
				.then((result) => {
					return result;
				})
				.catch((err) => {
					console.log(err);
					return err;
				});
		}
		return;
	}
);
export const postCartItemsAsync = createAsyncThunk(
	'cart/postCartItemsAsync',
	async (data, thunkAPI) => {
		const addCartItems = httpsCallable(functions, 'addCartItems');
		const list = thunkAPI.getState().cart.cartItems;
		return addCartItems(list)
			.then((result) => {
				thunkAPI.dispatch(getCartItemsAsync());
				return result;
			})
			.catch((err) => {
				console.log(err);
				return err;
			});
	}
);
const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		clearCart: (state) => {
			state.cartItems = [];
			// localStorage?.removeItem('cart-items')
		},
		removeItem: (state, action) => {
			const itemSku = action.payload;
			state.cartItems = state.cartItems.filter((item) => item.sku !== itemSku);
			// localStorage?.setItem('cart-items', JSON.stringify(state.cartItems))
		},
		increase: (state, action) => {
			const cartItem = state.cartItems.find(
				(item) => item.sku === action.payload
			);
			cartItem.amount = cartItem.amount + 1;
			// localStorage?.setItem('cart-items', JSON.stringify(state.cartItems))
		},
		decrease: (state, action) => {
			const cartItem = state.cartItems.find(
				(item) => item.sku === action.payload
			);
			cartItem.amount = cartItem.amount - 1;
			// localStorage?.setItem('cart-items', JSON.stringify(state.cartItems))
		},
		addItem: (state, { payload }) => {
			const data = payload;
			state.cartItems.push(data);
			// localStorage?.setItem('cart-items', JSON.stringify(state.cartItems))
		},
		setCartItems: (state, { payload }) => {
			state.cartItems = payload;
		},
		// setCartItemsList: (state, action) => {
		//     const data = action.payload
		//     state.cartItemsList = data
		//     localStorage?.setItem('cart-itemsList', JSON.stringify(data))
		// },
		updateCart: (state, { payload }) => {
			const productsObj = JSON.parse(payload);
			let newCartList = [];
			state.cartItems.forEach((item) => {
				const productItem = productsObj[item.productId];
				if (productItem.stock !== item.stock) {
					newCartList.push({ ...item, stock: productItem.stock });
				} else newCartList.push(item);
			});
			state.cartItems = newCartList;
			// localStorage?.setItem('cart-items', JSON.stringify(newCartList))
		},
		calculateTotals: (state, { payload }) => {
			const list = JSON.parse(payload);
			let amount = 0;
			let total = 0;
			list.length > 0 &&
				list.forEach((item) => {
					amount += item.amount;
					total += item.amount * item.price;
				});
			state.amount = amount;
			state.total = total;
		},
	},
	extraReducers: {
		[getCartItemsAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[getCartItemsAsync.fulfilled]: (state, action) => {
			state.cartItems = action.payload;
			// localStorage?.setItem('cart-items', JSON.stringify(action.payload))
			state.isLoading = false;
		},
		[getCartItemsAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[addCartItemAsync.pending]: (state) => {
			state.addToCart.isLoading = true;
		},
		[addCartItemAsync.fulfilled]: (state, action) => {
			state.addToCart.isLoading = false;
		},
		[addCartItemAsync.rejected]: (state) => {
			state.addToCart.isLoading = false;
		},
		[addMutltipleQuantityToCart.pending]: (state) => {
			state.addToCart.isLoading = true;
		},
		[addMutltipleQuantityToCart.fulfilled]: (state, action) => {
			state.addToCart.isLoading = false;
		},
		[addMutltipleQuantityToCart.rejected]: (state) => {
			state.addToCart.isLoading = false;
		},
		[postCartItemsAsync.pending]: (state) => {
			state.postCartItemsLoading = true;
		},
		[postCartItemsAsync.fulfilled]: (state, action) => {
			state.postCartItemsLoading = false;
		},
		[postCartItemsAsync.rejected]: (state) => {
			state.postCartItemsLoading = false;
		},
		[increaseQtyAsync.pending]: (state) => {
			state.increaseQty.isLoading = true;
		},
		[increaseQtyAsync.fulfilled]: (state, action) => {
			state.increaseQty.isLoading = false;
		},
		[increaseQtyAsync.rejected]: (state) => {
			state.increaseQty.isLoading = false;
		},
		[decreaseQtyAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[decreaseQtyAsync.fulfilled]: (state, action) => {
			state.isLoading = false;
		},
		[decreaseQtyAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[removeCartItemAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[removeCartItemAsync.fulfilled]: (state, action) => {
			state.isLoading = false;
		},
		[removeCartItemAsync.rejected]: (state) => {
			state.isLoading = false;
		},
		[generateCartListAsync.pending]: (state) => {
			state.isLoading = true;
		},
		[generateCartListAsync.fulfilled]: (state, action) => {
			const data = action.payload;
			state.cartItemsList = data;
			// localStorage?.setItem('cart-itemsList', JSON.stringify(data))
			state.isLoading = false;
		},
		[generateCartListAsync.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});
export const {
	clearCart,
	removeItem,
	increase,
	decrease,
	calculateTotals,
	addItem,
	updateCart,
	setCartItems,
} = cartSlice.actions;

export default cartSlice.reducer;
