import clsx from 'clsx';
import { Field, Form } from 'formik';
import CustomSelect from '../CustomSelect';
import {
	CheckboxField,
	PhoneInputField,
	SwitchInputField,
	TextInputField,
} from './InputField';
import { billingLabels } from '../../StaticData';
import { AVAILABLE_COUNTRIES } from '../../utils/constants';

export const BillingAddress = ({ isBusinessAddress }) => {
	return (
		<Form>
			<div className={clsx('card', 'non-hoverable', 'border-0', 'px-4 pb-4')}>
				<div
					className={clsx(
						'section-title',
						'border-bottom',
						'd-flex justify-content-between align-items-center'
					)}
				>
					<h3 className='title h5 fw-bold'>{billingLabels.displayName}</h3>
					<div className='form-check form-switch form-check-reverse'>
						<Field
							name='billingAddress.isBussiness'
							component={SwitchInputField}
							placeholder={billingLabels.isBusiness}
						/>
					</div>
				</div>
				<div className='row g-3'>
					<div className='col-md-6'>
						<Field
							name='billingAddress.name'
							component={TextInputField}
							label={billingLabels.fullName}
						/>
					</div>
					<div className='col-md-6'>
						<Field
							name='billingAddress.phone'
							component={PhoneInputField}
							label={billingLabels.phone}
						/>
					</div>
					<div
						className={clsx({
							'col-12': !isBusinessAddress,
							'col-lg-9 col-md-7': isBusinessAddress,
						})}
					>
						<Field
							name='billingAddress.address'
							component={TextInputField}
							label={billingLabels.address}
						/>
					</div>
					{isBusinessAddress && (
						<div className='col-lg-3 col-md-5'>
							<Field
								name='billingAddress.vat'
								component={TextInputField}
								label={billingLabels.vat}
							/>
						</div>
					)}
					<div className='col-lg-5 col-md-12'>
						<Field
							name='billingAddress.city'
							component={TextInputField}
							label={billingLabels.city}
						/>
					</div>
					<div className='col-lg-4 col-md-5'>
						<Field name='billingAddress.country'>
							{({ field }) => (
								<>
									<label className='form-label'>{billingLabels.country}</label>
									<CustomSelect
										placeholder={billingLabels.country}
										options={AVAILABLE_COUNTRIES}
										selected={field.value}
										onChange={field.onChange}
									/>
								</>
							)}
						</Field>
					</div>
					<div className='col-lg-3 col-md-5'>
						<Field
							name='billingAddress.zipCode'
							component={TextInputField}
							label={billingLabels.zipCode}
						/>
					</div>
					<div className='col-12'>
						<Field
							name='saveBillingPreferences'
							component={CheckboxField}
							label={billingLabels.save}
						/>
					</div>
				</div>
			</div>
		</Form>
	);
};
