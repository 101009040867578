import { useMemo } from 'react';
import clsx from 'clsx';
import { PaymentTypeSelection } from './PaymentTypeSelection';
import { TermsAcceptance } from './TermsAcceptance';
import { Currency } from '../Currency';
import { SelectedProdVariaties } from '../SelectedProdVariaties';
import styles from './OrderSummary.module.scss';
import { orderSummaryLabels } from '../../StaticData';

export const OrderSummary = ({
	hidePaymentTypeSelection,
	hideTermsAcceptance,
	btnTitle,
	cartItems,
	cartCourses,
	hasShipping,
	shippingCharges,
	codCharges,
	total,
	totalCoursesAmount,
	isActionLoading,
	actionDisabled,
	isQuickCheckout,
	quickCheckoutTitle,
	courseClassTime,
	quickOrderType,
}) => {
	const grandTotoal = useMemo(() => {
		let totalCharges = total + codCharges + totalCoursesAmount;
		if (totalCharges < 299 && hasShipping && shippingCharges > 0) {
			totalCharges += shippingCharges;
		}
		return totalCharges;
	}, [total, codCharges, hasShipping, shippingCharges, totalCoursesAmount]);

	return (
		<div
			className={clsx(
				styles['order-details'],
				'card',
				'non-hoverable',
				'border-0'
			)}
		>
			<div className='section-title border-bottom'>
				<h3 className='title h5 fw-bold'>{orderSummaryLabels.title}</h3>
			</div>
			<div>
				{isQuickCheckout ? (
					<div className=''>
						<div className={styles.orderListRow}>
							<p className='fw-bold'>Course</p>
							<p className='fw-bold'>{orderSummaryLabels.total}</p>
						</div>
						<div className={styles.orderListRow}>
							<div className=''>
								<p>
									{quickCheckoutTitle}
									{quickOrderType !== 'full' && (
										<span className='text-capitalize small fst-italic text-muted ms-1'>
											({quickOrderType})
										</span>
									)}
								</p>
								{courseClassTime && (
									<p className='small text-capitalize fst-italic'>
										{`(${courseClassTime})`}
									</p>
								)}
							</div>
							<Currency className='fw-bold' price={total} />
						</div>
					</div>
				) : (
					<div className={styles.orderListRow}>
						<p className='fw-bold'>{orderSummaryLabels.subTitle}</p>
						<p className='fw-bold'>{orderSummaryLabels.total}</p>
					</div>
				)}
				<div className={styles['order-products']}>
					{cartItems.map((cart, index) => (
						<div key={index} className={styles.orderListRow}>
							<div className=''>
								<p>
									{cart.amount}x {cart.title}
								</p>
								{cart.variations && (
									<SelectedProdVariaties
										className='small'
										curvature={cart.variations.curvature}
										thickness={cart.variations.thickness}
										length={cart.variations.length}
										showBrackets={true}
									/>
								)}
							</div>
							<p className='fw-bold'>
								<Currency price={cart.price * cart.amount} />
							</p>
						</div>
					))}
				</div>
				{hasShipping && (
					<div className={styles.orderListRow}>
						<p>{orderSummaryLabels.shippingCost}</p>
						<p className='fw-bold'>
							{shippingCharges > 0 ? (
								<Currency
									price={
										total + totalCoursesAmount + codCharges < 299 &&
										shippingCharges
									}
								/>
							) : (
								'FREE'
							)}
						</p>
					</div>
				)}
				{codCharges > 0 && (
					<div className={styles.orderListRow}>
						<p>{orderSummaryLabels.cod}</p>
						<p className='fw-bold'>
							<Currency price={codCharges} />
						</p>
					</div>
				)}
				{cartCourses.length > 0 && (
					<div className='border-top'>
						<div className={styles.orderListRow}>
							<p className='fw-bold'>Courses</p>
						</div>
						<div className={styles['order-products']}>
							{cartCourses.map((courseItem, index) => (
								<div key={index} className={styles.orderListRow}>
									<div className=''>
										<p>{courseItem.title}</p>
										{courseItem.selectedClassTime && (
											<p className='small text-capitalize fst-italic'>
												{`(${courseItem.selectedClassTime})`}
											</p>
										)}
									</div>
									<p className='fw-bold'>
										<Currency price={courseItem.price} />
									</p>
								</div>
							))}
						</div>
					</div>
				)}
				<div className={styles.orderListRow}>
					<p className='fw-bold'>{orderSummaryLabels.total}</p>
					<p className='fw-bold text-primary h4'>
						<Currency price={grandTotoal} />
					</p>
				</div>
			</div>
			{!hidePaymentTypeSelection &&
				<PaymentTypeSelection
					codDisabled={cartCourses.length > 0 || isQuickCheckout || !hasShipping}
				/>
			}
			{!hideTermsAcceptance && <TermsAcceptance />}
			<button
				type='submit'
				className={clsx(styles.submitButton, 'btn btn-primary')}
				disabled={isActionLoading || actionDisabled}
			>
				{btnTitle || 'Place order'}
				{isActionLoading && (
					<div className='ms-2 spinner-border spinner-border-sm'></div>
				)}
			</button>
		</div>
	);
};
