import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Currency } from '../../components/Currency';
import { LocalizedDate } from '../../components/LocalizedDate';
import { useSelectFirstAvailableClass } from '../../hooks/courses';
import { CourseClassTimeSelection } from '../../components/CourseClassTimeSelection';
import { updateCourseClassTimePreference } from '../../features/User';

import styles from './Courses.module.scss';

const FeaturedCourse = ({ featuredCourse, featuredCourseLoading }) => {
	const navigate = useNavigate();
	const [selectedClassTime, setSelectedClassTime] = useState('');
	const dispatch = useDispatch('');
	const preferredClassTime = useSelectFirstAvailableClass(
		featuredCourse?.id,
		featuredCourse?.classes
	);

	const onViewCourseDetail = () => {
		navigate(`/courses/${featuredCourse.id}`);
	};

	const updateSelectedClassTime = async (classTime) => {
		dispatch(
			updateCourseClassTimePreference({
				courseId: featuredCourse.id,
				selectedClassTime: classTime,
			})
		);
		setSelectedClassTime(classTime);
	};

	useEffect(() => {
		if (preferredClassTime) {
			setSelectedClassTime(preferredClassTime);
		}
	}, [preferredClassTime]);

	const selectedClass = useMemo(() => {
		if (featuredCourse?.classes && selectedClassTime) {
			return featuredCourse?.classes.find(
				(item) => item.class === selectedClassTime
			);
		}
	}, [featuredCourse?.classes, selectedClassTime]);

	return (
		<div className={clsx(styles.heroSection, 'py-5')}>
			<div className='container'>
				{featuredCourseLoading && (
					<div className='text-center'>
						<div className='spinner-border text-primary' role='status' />
						<h1 className='text-primary'>Featured Course</h1>
					</div>
				)}
				{featuredCourse && (
					<div className='row'>
						<div className='col-sm-5'>
							<div className='position-relative'>
								<img
									className={clsx('img-fluid', styles.heroImage)}
									src={featuredCourse.cover}
									alt=''
								/>
								{featuredCourse.oldPrice > 0 && (
									<p className={styles.discountBadge}>
										<Currency
											price={featuredCourse.price - featuredCourse.oldPrice}
										/>
									</p>
								)}
							</div>
						</div>
						<div className='col-sm-7 col-lg-5 mt-4 mt-md-0'>
							<h1>{featuredCourse.title}</h1>
							<div className=''>
								{featuredCourse.isAccredited && !featuredCourse.isOnline && (
									<span className={clsx(styles.badge, 'me-2')}>acreditat</span>
								)}
								<span className={styles.badge}>
									Curs {featuredCourse.isOnline ? 'Online' : 'Fizic'}
								</span>
								{selectedClass && (
									<span
										className={clsx({
											'mb-0 h6 ms-2 fw-bold': true,
											'text-danger': selectedClass.availableSeats < 5,
										})}
									>
										{selectedClass.availableSeats}/{selectedClass.totalSeats}{' '}
										Locuri disponibile
									</span>
								)}
							</div>
							<p className='my-4'>{featuredCourse.description}</p>
							<div className=''>
								<p className='mb-1'>
									<span className='me-2'>Preț:</span>
									{featuredCourse.oldPrice > 0 && (
										<del className='me-2 text-muted'>
											<Currency price={featuredCourse.oldPrice} />
										</del>
									)}
									<span className='text-primary lead fw-bold'>
										<Currency price={featuredCourse.price} />
									</span>
								</p>
								{featuredCourse.courseLocation && (
									<p className='mb-1'>
										<span>Locație:</span>
										<span className='fw-bold ms-1'>
											{featuredCourse.courseLocation}
										</span>
									</p>
								)}
								{!featuredCourse.isOnline && (
									<p className='mb-1'>
										Începe pe:
										<LocalizedDate
											className='fw-bold ms-1'
											date={featuredCourse.startsOn}
										/>
									</p>
								)}
								{featuredCourse.classes && (
									<CourseClassTimeSelection
										courseId={featuredCourse.id}
										classes={featuredCourse.classes}
										selectedClassTime={selectedClassTime}
										onChange={updateSelectedClassTime}
									/>
								)}
							</div>
							<button
								className='btn btn-primary text-white py-2 px-5 rounded-0 text-uppercase'
								onClick={onViewCourseDetail}
							>
								Vezi detalii
							</button>
						</div>
					</div>
				)}
				{!featuredCourse && !featuredCourseLoading && (
					<h1 className='text-center text-primary'>Cursuri</h1>
				)}
			</div>
		</div>
	);
};

export default FeaturedCourse;
