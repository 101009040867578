export const getHumanReadableTimeDifference = (dateString) => {
	const date = new Date(dateString);
	const diffInMs = date - new Date();

	const minuteInMs = 1000 * 60;
	const hourInMs = minuteInMs * 60;
	const dayInMs = hourInMs * 24;
	const monthInMs = dayInMs * 30;
	const yearInMs = monthInMs * 12;

	if (diffInMs < 0) {
		return 'just now';
	} else if (diffInMs < minuteInMs) {
		return 'less than a minute';
	} else if (diffInMs < hourInMs) {
		const diffInMinutes = Math.round(diffInMs / minuteInMs);
		return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'}`;
	} else if (diffInMs < dayInMs) {
		const diffInHours = Math.round(diffInMs / hourInMs);
		return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}`;
	} else if (diffInMs < monthInMs) {
		const diffInDays = Math.round(diffInMs / dayInMs);
		return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`;
	} else if (diffInMs < yearInMs) {
		const diffInMonths = Math.round(diffInMs / monthInMs);
		return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'}`;
	} else {
		const diffInYears = Math.round(diffInMs / yearInMs);
		return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'}`;
	}
};

/**
 * @description Returns end of today according to Romanian Timezone
 * @returns {number}
 */
export const getEndOfToday = () => {
	const now = new Date();
	const endOfToday = new Date(
		now.toLocaleString('en-US', {
			timeZone: 'Europe/Bucharest',
			dateStyle: 'short',
		})
	);
	endOfToday.setHours(23, 59, 59, 999);

	return endOfToday.valueOf();
};
