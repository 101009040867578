import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import {
	collection,
	doc,
	documentId,
	getDoc,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { fireDB } from '../Firebase';

const initialState = {
	loading: true,
	product: null,
	error: null,
	relatedProductsLoading: true,
	relatedProductsList: [],
	relatedProductsError: null,
};

export const loadProductDetail = createAsyncThunk(
	'productDetail/load',
	async (id, { rejectWithValue }) => {
		const docRef = doc(fireDB, 'products', id);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			return rejectWithValue('Could not find product with this ID.');
		}
		const productData = docSnap.data();
		return productData;
	}
);

export const loadRelatedProducts = createAsyncThunk(
	'productDetail/relatedProducts',
	async (_, { getState, rejectWithValue }) => {
		const { product } = getState().productDetail;
		if ((!product || !product.productId, !product.category)) {
			return rejectWithValue('Product Id and category not found.');
		}
		const { productId, category } = product;
		const docRef = doc(fireDB, 'productsByCategory', category.id);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			return rejectWithValue('Product list not available for this category.');
		}
		let { products: categoryProducts } = docSnap.data();
		categoryProducts = categoryProducts.filter((prod) => prod.id !== productId);
		categoryProducts = categoryProducts.slice(0, 4);
		const prodRefs = collection(fireDB, 'products');
		const docsQuery = query(
			prodRefs,
			where(
				documentId(),
				'in',
				categoryProducts.map(({ id }) => id)
			)
		);
		const productsDocsSnap = await getDocs(docsQuery);
		const products = productsDocsSnap.docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
		}));
		return products.filter((product) => product.id !== productId);
	}
);

const slice = createSlice({
	name: 'productDetail',
	initialState,
	reducers: {
		resetProduct: (state) => {
			state = initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadProductDetail.pending, (state, action) => {
			state.product = null;
			state.loading = true;
			state.error = null;
		});
		builder.addCase(loadProductDetail.fulfilled, (state, action) => {
			state.product = action.payload;
			state.loading = false;
		});
		builder.addCase(loadProductDetail.rejected, (state, action) => {
			state.loading = false;
			state.product = null;
			state.error = action.payload || "Couldn't load the data, please refresh.";
		});
		builder.addCase(loadRelatedProducts.pending, (state, action) => {
			state.relatedProductsList = [];
			state.relatedProductsLoading = true;
			state.relatedProductsError = null;
		});
		builder.addCase(loadRelatedProducts.fulfilled, (state, action) => {
			state.relatedProductsList = action.payload;
			state.relatedProductsLoading = false;
		});
		builder.addCase(loadRelatedProducts.rejected, (state, action) => {
			state.relatedProductsList = false;
			state.relatedProductsLoading = [];
			state.relatedProductsError = action.payload || "Couldn't load the data.";
		});
	},
});

export const { resetProduct } = slice.actions;

// SELECTORS
const selectProductDetailData = (state) => {
	return state.productDetail;
};
export const selectIsProdDetailLoading = createSelector(
	selectProductDetailData,
	(productDetailData) => productDetailData.loading
);
export const selectProdDetailError = createSelector(
	selectProductDetailData,
	(productDetailData) => productDetailData.error
);
export const selectProdDetail = createSelector(
	selectProductDetailData,
	({ product }) => product
);
export const selectIsRelatedProdsLoading = createSelector(
	selectProductDetailData,
	(productDetailData) => productDetailData.relatedProductsLoading
);
export const selectRelatedProdsError = createSelector(
	selectProductDetailData,
	(productDetailData) => productDetailData.relatedProductsError
);
export const selectRelatedProdsList = createSelector(
	selectProductDetailData,
	({ relatedProductsList }) => relatedProductsList
);
export default slice.reducer;
