import clsx from 'clsx';
import { Currency } from '../../components/Currency';
import styles from './CourseDetail.module.scss';

const FloatingActions = ({
	floatingHeaderVisible,
	onShowCoursePreview,
	coverImage,
	previewVideoUrl,
	oldPrice,
	price,
	courseType,
	totalDuration,
	courseValidity,
	courseExpiry,
	discountExpiry,
	allowAddToCart,
	isCourseInCart,
	allowBuyCourse,
	allowPayRegistration,
	allowPayRemaingAmount,
	allowedToCourseConsole,
	onAddToCart,
	onQuickCheckout,
	goToCourseConsole,
}) => {
	return (
		<div className='container position-relative'>
			<div
				className={clsx({
					[styles.floatingPriceSection]: true,
					[styles.stickFloating]: floatingHeaderVisible,
				})}
			>
				<div className='container px-0'>
					<div className={clsx(styles.priceSectionInner, 'shadow')}>
						<div
							className={clsx({
								[styles.previewCover]: true,
								'd-none': floatingHeaderVisible,
							})}
						>
							<img src={coverImage} alt='Course Preview Cover' />
							{previewVideoUrl && (
								<div
									className={styles.previewOverlay}
									onClick={onShowCoursePreview}
								>
									<span className={styles.previewPlayBtn} />
									<div className={styles.PreviewText}>
										<p>Preview this course</p>
									</div>
								</div>
							)}
						</div>
						<div className='p-3 border'>
							<h5 className='my-2'>
								{oldPrice > 0 && (
									<Currency
										className='small text-decoration-line-through text-danger me-1'
										price={oldPrice}
									/>
								)}
								<Currency className='fw-bold h4 text-primary' price={price} />
							</h5>
							{oldPrice > 0 && (
								<p className='text-muted small'>
									Reducerea se termină în {discountExpiry}
								</p>
							)}
							<div className='row gap-3 my-3 mx-0 justify-content-between'>
								{allowBuyCourse && (
									<button
										className={clsx(
											'col-12',
											allowPayRegistration && 'col-lg-4',
											'btn btn-outline-primary',
											'rounded-0 py-2',
											'text-uppercase fw-bold'
										)}
										onClick={() => onQuickCheckout('full')}
									>
										Plătește integral
									</button>
								)}
								{allowPayRegistration && (
									<button
										className={clsx(
											'col-12 col-lg-7',
											'btn btn-dark',
											'rounded-0 py-2',
											'text-uppercase fw-bold'
										)}
										onClick={() => onQuickCheckout('registration')}
									>
										Plătește avans
									</button>
								)}
								{allowPayRemaingAmount && (
									<button
										className={clsx(
											'col-12',
											'btn btn-dark',
											'rounded-0 py-2',
											'text-uppercase fw-bold'
										)}
										onClick={() => onQuickCheckout('outstanding')}
									>
										Achită Restul de Plată
									</button>
								)}
								{allowAddToCart && (
									<button
										className={clsx(
											'col-12',
											'btn btn-primary',
											'rounded-0',
											'text-white text-uppercase fw-bold lh-lg'
										)}
										disabled={isCourseInCart}
										onClick={onAddToCart}
									>
										{isCourseInCart ? 'Adăugat în coș' : 'Adaugă în coș'}
									</button>
								)}
								{allowedToCourseConsole && (
									<button
										className={clsx(
											'col-12',
											'btn btn-primary',
											'rounded-0',
											'text-white text-uppercase fw-bold lh-lg'
										)}
										onClick={goToCourseConsole}
									>
										Consolă Curs
									</button>
								)}
							</div>
							<div className=''>
								<p className='mb-2'>
									Tipul cursului:
									<span className='fw-bold ms-1'>{courseType}</span>
								</p>
								{courseType === 'Online' && (
									<div>
										<p className='mb-2'>
											Durata cursului:
											<span className='fw-bold ms-1'>{totalDuration}</span>
										</p>
										{courseExpiry ? (
											<p className='mb-2'>
												Cursul expiră în:
												<span className='fw-bold ms-1'>{courseExpiry}</span>
											</p>
										) : (
											<p className='mb-2'>
												Acces curs:
												<span className='fw-bold ms-1'>
													{courseValidity} Zile
												</span>
											</p>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FloatingActions;
