import { useRef } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { BsChevronLeft } from 'react-icons/bs';

import 'swiper/css';
import 'swiper/css/navigation';

import styles from './CourseConsole.module.scss';

const LessonImages = ({
	lessonImages,
	isCompleted,
	onCompleteLesson,
	onNextLesson,
}) => {
	const sliderRef = useRef();

	const handleSlideButton = (direction) => {
		if (sliderRef.current) {
			if (direction === 'next') {
				sliderRef.current.slideNext();
			} else {
				sliderRef.current.slidePrev();
			}
		}
	};

	return (
		<div className='my-4 border p-3'>
			<Swiper
				onBeforeInit={(swiper) => {
					sliderRef.current = swiper;
				}}
				className='position-relative'
				modules={[Navigation]}
			>
				{lessonImages.map((imageUrl, index) => (
					<SwiperSlide key={index}>
						<img className={styles.slideImage} src={imageUrl} />
					</SwiperSlide>
				))}
				<div
					className={styles.sliderButton}
					onClick={() => handleSlideButton('prev')}
				>
					<BsChevronLeft />
				</div>
				<div
					className={clsx(styles.sliderButton, styles.nextButton)}
					onClick={() => handleSlideButton('next')}
				>
					<BsChevronLeft />
				</div>
			</Swiper>
			<div className='text-end mt-3'>
				{isCompleted ? (
					<button
						className='btn btn-outline-primary fw-bold text-uppercase rounded-0 px-4 py-2'
						onClick={onNextLesson}
					>
						Urmatoarea lectie
					</button>
				) : (
					<button
						className='btn btn-primary text-white fw-bold text-uppercase rounded-0 px-4 py-2'
						onClick={onCompleteLesson}
					>
						Marcați ca fiind complet
					</button>
				)}
			</div>
		</div>
	);
};

export default LessonImages;
