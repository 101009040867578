import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CourseCardFlat from '../../components/CourseCardFlat';
import { loadRelatedCourses, selectRelatedCourses } from '../../Store/courses';

const RelatedCourses = ({ courseId }) => {
	const dispatch = useDispatch();
	const { relatedCourses, relatedCoursesLoading, relatedCoursesError } =
		useSelector(selectRelatedCourses);

	useEffect(() => {
		dispatch(loadRelatedCourses());
	}, [courseId]);

	return (
		<div className='row'>
			<h3 className='my-3'>Cursuri similare</h3>
			{relatedCoursesLoading || relatedCoursesError ? (
				<div className='text-center my-5'>
					{relatedCoursesLoading && (
						<div className='spinner-border text-primary' role='status'></div>
					)}
					{relatedCoursesError && (
						<p className='h5 fw-normal text-muted'>
							{relatedCoursesError || 'Course loading failed.'}
						</p>
					)}
				</div>
			) : (
				<>
					{relatedCourses.map((courseItem) => (
						<div key={courseItem.id} className='mb-3'>
							<CourseCardFlat
								id={courseItem.id}
								cover={courseItem.cover}
								title={courseItem.title}
								rating={courseItem.averageRating}
								reviewCount={courseItem.reviews.length}
								price={courseItem.price}
								oldPrice={courseItem.oldPrice}
								acreditat={courseItem.isAccredited && !courseItem.isOnline}
								startDate={courseItem.startsOn}
								isOnline={courseItem.isOnline}
								courseLocation={courseItem.courseLocation}
								classes={courseItem.classes}
							/>
						</div>
					))}
					{relatedCourses.length === 0 && (
						<p className='col-12 h5 text-center mb-2 text-muted'>
							No course found in this category at the moment.
						</p>
					)}
				</>
			)}
		</div>
	);
};

export default RelatedCourses;
