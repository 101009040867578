const getHashOfString = (string) => {
	let hash = 0;
	for (let i = 0; i < string.length; i++) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}
	hash = Math.abs(hash);
	return hash;
};

const normalizeHash = (hash, min, max) => {
	return Math.floor((hash % (max - min)) + min);
};

export const generateAvatarColor = (userName) => {
	const nameHash = getHashOfString(userName);
	const h = normalizeHash(nameHash, 0, 360);
	const s = normalizeHash(nameHash, 50, 75);
	const l = normalizeHash(nameHash, 25, 60);
	return `hsl(${h}, ${s}%, ${l}%)`;
};

export const splitFullName = (fullName) => {
	// Trim any leading or trailing white spaces
	const trimmedFullName = fullName.trim();
	// Find the last space in the full name
	const lastSpaceIndex = trimmedFullName.lastIndexOf(' ');
	let firstName, lastName;
	if (lastSpaceIndex !== -1) {
		// If there is a space, split the full name into first name and last name
		firstName = trimmedFullName.slice(0, lastSpaceIndex);
		lastName = trimmedFullName.slice(lastSpaceIndex + 1);
	} else {
		// If there is no space, assume the whole name is the first name and no last name
		firstName = trimmedFullName;
		lastName = '';
	}

	return {
		firstName,
		lastName,
	};
};

export const extractAndParseValue = (
	obj,
	key,
	defaultValue,
	parseFunction = (value) => value
) => {
	if (!obj) {
		return defaultValue;
	}

	const keys = key.split('.');
	let value = obj;

	for (const nestedKey of keys) {
		if (!Object.prototype.hasOwnProperty.call(value, nestedKey)) {
			value = undefined;
			break;
		}
		value = value[nestedKey];
	}

	if (!value && value !== 0 && value !== false) {
		return defaultValue;
	}
	return parseFunction(value);
};
