import React from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import {
	BsPinMapFill,
	BsFillTelephoneFill,
	BsFillEnvelopeFill,
	BsFacebook,
	BsInstagram,
} from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import styles from './Footer.module.scss';
import { footerLabels } from '../../StaticData';

const Footer = () => {
	return (
		<footer className={styles.footerContainer}>
			<div className={styles.section}>
				<div className='container'>
					<div className='row'>
						<div className='col-md-4 col-sm-12'>
							<div className={styles.footerColumn}>
								<h3 className={styles.footerColumnTitle}>
									{footerLabels.contactDisplayName}
								</h3>
								<h5>{footerLabels.contactTitle}</h5>
								<ul className={styles.footerLinks}>
									<li>
										<BsPinMapFill className={styles.footerIcon} />
										<a href='https://www.google.com/maps/place/Bulevardul+Bucure%C8%99ti+23,+Baia+Mare+430251/@47.653062,23.5576941,17z/data=!3m1!4b1!4m5!3m4!1s0x4737dc4092eb3b89:0x2296c37eb098c907!8m2!3d47.6530584!4d23.5598828'>
											<span>{footerLabels.contactAddress}</span>
										</a>
									</li>
									<li>
										<BsFillTelephoneFill className={styles.footerIcon} />
										<a href='tel:+40 757 648 448'>
											<span>{footerLabels.contactPhone}</span>
										</a>
									</li>
									<li>
										<p>{footerLabels.businessSchedule}</p>
									</li>
									<li>
										<BsFillEnvelopeFill className={styles.footerIcon} />
										<a href='mailto:office.sancosacademy@gmail.com'>
											{footerLabels.contactEmail}
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div className='col-md-4 col-sm-6'>
							<div className={styles.footerColumn}>
								<h3 className={styles.footerColumnTitle}>
									{footerLabels.infoDisplayName}
								</h3>
								<ul className={styles.footerLinks}>
									<li>
										<Link to={'about-us'}>{footerLabels.infoAbout}</Link>
									</li>
									<li>
										<Link to={'privacy-policy'}>{footerLabels.infoGDPR}</Link>
									</li>
									<li>
										<Link to={'terms-and-conditions'}>
											{footerLabels.infoTermsAndConditions}
										</Link>
									</li>
									<li>
										<a href='https://anpc.ro/ce-este-sal/'>
											{footerLabels.infoANPC}
										</a>
									</li>
									<li>
										<a href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN'>
											{footerLabels.infoSAL}
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div className='col-md-4 col-sm-6'>
							<div className={styles.footerColumn}>
								<h3 className={styles.footerColumnTitle}>
									{footerLabels.optioonsDisplayName}
								</h3>
								<ul className={styles.footerLinks}>
									<li>
										<Link to={'/my-account'}>
											{footerLabels.optioonsMyAccount}
										</Link>
									</li>
									<li>
										<Link to={'/cart'}>{footerLabels.optioonsMyCart}</Link>
									</li>
									<li>
										<Link to={'/favorites'}>
											{footerLabels.optioonsFavorites}
										</Link>
									</li>
									<li>
										<Link to={'/orders-history'}>
											{footerLabels.optioonsOrders}
										</Link>
									</li>
									<li>
										<Link to={'/help'}>{footerLabels.optioonsHelp}</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={clsx(styles.section, styles.bottomFooter)}>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-7 text-sm-start text-center'>
							<p className={styles.copyright}>
								Copyright &copy; {'2018-' + new Date().getFullYear()}{' '}
								{footerLabels.businessName}
							</p>
							<p className='m-0'>
								{footerLabels.vatLableAndNo} , {footerLabels.ONRCLabelAndNo}
							</p>
						</div>
						<div className='col-sm-5 text-sm-end text-center mt-4 mt-sm-0'>
							<div className=''>
								<a href='https://facebook.com/sancosacademy' target='_blank'>
									<BsFacebook className={styles.socalIcon} />
								</a>
								<a
									href='https://www.instagram.com/sancosacademy/'
									className='mx-4'
									target='_blank'
								>
									<BsInstagram className={styles.socalIcon} />
								</a>
								<a href='https://www.tiktok.com/@sancosacademy' target='_blank'>
									<FaTiktok className={styles.socalIcon} />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
