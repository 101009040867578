import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCoursesInCart } from '../../Store/coursesCart';
import { selectFavouritesCoursesList } from '../../features/Favorites';
import { logoutUser } from '../../features/User';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsPerson, BsCart2, BsHeart } from 'react-icons/bs';
import NavItem from 'react-bootstrap/NavItem';
import DropdownItem from 'react-bootstrap/DropdownItem';

import { LinkContainer } from 'react-router-bootstrap';

import Logo from '../../assets/sancosTop.jpg';

import './styles.scss';

export default function Header() {
	const { amount } = useSelector((state) => state.cart);
	const cartCourseList = useSelector(selectCoursesInCart);
	const { favAmount } = useSelector((state) => state.favorites);
	const favCoursesList = useSelector(selectFavouritesCoursesList);
	const { userInfo } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const onLogout = () => {
		dispatch(logoutUser());
	};

	const totalFavItems = useMemo(() => {
		return favAmount + favCoursesList.length;
	}, [favAmount, favCoursesList]);

	const totalCartItems = useMemo(() => {
		return amount + cartCourseList.length;
	}, [amount, cartCourseList]);

	return (
		<Navbar collapseOnSelect expand='md' className='navbar shadow-sm'>
			<Container>
				<LinkContainer to='/'>
					<NavItem>
						<img
							className='logo'
							src={Logo}
							height='60px'
							alt='Sancos Academy Logo'
						/>
					</NavItem>
				</LinkContainer>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='me-auto nav1'>
						<LinkContainer to='/'>
							<NavItem className='margine-left item'>Produse</NavItem>
						</LinkContainer>
						<LinkContainer to='/courses'>
							<NavItem className='margine-left item'>Cursuri</NavItem>
						</LinkContainer>
						{/* <LinkContainer to='/cosmetics'>
							<NavItem className='margine-left item no-click' title="În lucru...">Cosmetică</NavItem>
						</LinkContainer> */}
					</Nav>
					<Nav>
						{!userInfo ? (
							<LinkContainer className='' to='/login'>
								<div className='align-center item-right'>
									<BsPerson className='user-icon-connect' />
									<NavItem className='register'>Intră în cont</NavItem>
								</div>
							</LinkContainer>
						) : (
							<div className='align-center item-right'>
								<BsPerson className='user-icon' />
								<NavDropdown
									title={userInfo.displayName}
									id='collasible-nav-dropdown'
								>
									<LinkContainer to='/my-account'>
										<DropdownItem className='dropdown-item'>
											Contul meu
										</DropdownItem>
									</LinkContainer>
									<LinkContainer to='/orders-history'>
										<DropdownItem className='dropdown-item'>
											Comenzile mele
										</DropdownItem>
									</LinkContainer>
									<LinkContainer to='/my-courses'>
										<DropdownItem className='dropdown-item'>
											Cursurile mele
										</DropdownItem>
									</LinkContainer>
									<NavDropdown.Divider />
									<DropdownItem className='dropdown-item' onClick={onLogout}>
										Log out
									</DropdownItem>
								</NavDropdown>
							</div>
						)}
						<LinkContainer to='/favorites'>
							<div className='align-center item-right'>
								<BsHeart className=' favorite-icon' />
								<span className='badge badge-warning' id='lblFavoriteCount'>
									{totalFavItems}
								</span>
								<NavItem className='favorite'>Favorite</NavItem>
							</div>
						</LinkContainer>
						<LinkContainer to='/cart'>
							<div className='align-center item-right'>
								<BsCart2 className='cart-icon' />
								<span className='badge badge-warning' id='lblCartCount'>
									{totalCartItems}
								</span>
								<NavItem className='cart'>Coșul meu</NavItem>
							</div>
						</LinkContainer>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
