export const Currency = ({ price, className }) => {
	return (
		<span className={className}>
			{new Intl.NumberFormat('ro-RO', {
				style: 'currency',
				currency: 'RON',
				currencyDisplay: 'name',
			})
				.format(price)
				.replace('românești', '')}
		</span>
	);
};
