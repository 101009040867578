import { BsCheck } from 'react-icons/bs';

const ItemsList = ({ title, itemsList }) => {
	return (
		<div className='border p-3 mb-4'>
			<h3>{title}</h3>
			<div className='row'>
				{itemsList.map((item, index) => (
					<div key={index} className='col-sm-6 p-0 my-2 d-flex gap-1'>
						<BsCheck className='default text-primary flex-shrink-0' size={18} />
						<span className=''>{item}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default ItemsList;
