import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import {
	deleteMyAccount,
	resetPreferences,
	selectUserUpdateStatus,
} from '../../features/User';

import styles from './MyAccount.module.scss';

const modalTitles = {
	delete: {
		title: 'Șterge cont',
		description: 'Sunteți sigur că doriți să vă ștergeți contul?',
		buttonText: 'Da, șterge contul',
	},
	reset: {
		title: 'Ștergeți preferințele',
		description: 'Sigur doriți să vă ștergeți preferințele',
		buttonText: 'Da, ștergeți preferințele',
	},
};

const AcountConfirmationModal = ({
	handleCloseFormModal,
	acountActionType,
}) => {
	const dispatch = useDispatch();
	const { userUpdateLoading, userUpdateSuccess, userUpdateError } = useSelector(
		selectUserUpdateStatus
	);

	const onCloseModal = () => {
		handleCloseFormModal('');
	};

	const onHandleAction = () => {
		if (acountActionType === 'reset') {
			dispatch(resetPreferences());
		} else if (acountActionType === 'delete') {
			dispatch(deleteMyAccount());
		}
	};

	useEffect(() => {
		if (userUpdateSuccess) {
			toast.success(userUpdateSuccess);
			onCloseModal();
		}
		if (userUpdateError) {
			toast.error(userUpdateError);
		}
	}, [userUpdateSuccess, userUpdateError]);

	return (
		<Modal show={!!acountActionType} onHide={onCloseModal} backdrop='static'>
			<div className='bg-white rounded position-relative'>
				<div className='d-flex align-items-center justify-content-between px-3 pt-3 border-bottom'>
					<h5>{modalTitles[acountActionType]?.title}</h5>
					<button className='btn btn-sm' type='button' onClick={onCloseModal}>
						<BsX />
					</button>
				</div>
				<div className='p-3'>
					<p className='text-center h5 my-3'>
						{modalTitles[acountActionType]?.description}
					</p>
					<div className='text-end'>
						<button
							className='btn btn-link text-secondary'
							onClick={onCloseModal}
						>
							Anulare
						</button>
						<button className='btn btn-danger ms-2' onClick={onHandleAction}>
							{modalTitles[acountActionType]?.buttonText}
						</button>
					</div>
				</div>
				{userUpdateLoading && (
					<div className={styles.loadingOverlay}>
						<div className='spinner-border text-primary' role='status'>
							<span className='visually-hidden'>Loading...</span>
						</div>
						<div className='message'>Processing...</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default AcountConfirmationModal;
