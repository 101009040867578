import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { collection, getDocs } from 'firebase/firestore';
import { fireDB } from '../Firebase';

const initialState = {
	loading: true,
	variationsStocks: {},
	error: null,
};

export const loadProductsVariationsStock = createAsyncThunk(
	'productsVariationsStock/load',
	async () => {
		const querySnapshot = await getDocs(
			collection(fireDB, 'productsVariationsStock')
		);

		const variationsStocks = {};
		querySnapshot.forEach((doc) => {
			const variations = doc.data();
			for (const variety in variations) {
				const parsedVariety = variety.replace(/:DOT:/g, '.');
				const skuId = `${doc.id}_${parsedVariety}`;
				variationsStocks[skuId] = variations[variety];
			}
		});

		return variationsStocks;
	}
);

const slice = createSlice({
	name: 'productsVariationsStock',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loadProductsVariationsStock.pending, (state, action) => {
			state.variationsStocks = {};
			state.loading = true;
			state.error = null;
		});
		builder.addCase(loadProductsVariationsStock.fulfilled, (state, action) => {
			state.variationsStocks = action.payload;
			state.loading = false;
		});
		builder.addCase(loadProductsVariationsStock.rejected, (state, action) => {
			state.variationsStocks = {};
			state.loading = false;
			state.error =
				action.payload ||
				action.error.message ||
				"Couldn't load the data, please refresh.";
		});
	},
});

// ACTIONS

// SELECTORS
const selectProductsVariationsStockData = (state) => {
	return state.productsVariationsStock;
};

export const selectProductsVariationsStock = createSelector(
	selectProductsVariationsStockData,
	(productsVariationsStock) => {
		return productsVariationsStock.variationsStocks;
	}
);

export default slice.reducer;
