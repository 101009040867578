import ReactQuill from 'react-quill';

import styles from './CourseConsole.module.scss';

const LessonText = ({
	lessonText,
	isCompleted,
	onCompleteLesson,
	onNextLesson,
}) => {
	return (
		<div className={styles.textLessonContainer}>
			<div className='my-3'>
				<ReactQuill
					theme='snow'
					readOnly={true}
					modules={{
						toolbar: false,
					}}
					value={lessonText}
					className={styles.textRTE}
				/>
			</div>
			<div className='text-end'>
				{isCompleted ? (
					<button
						className='btn btn-outline-primary fw-bold text-uppercase rounded-0 px-4 py-2'
						onClick={onNextLesson}
					>
						Următoarea lecție
					</button>
				) : (
					<button
						className='btn btn-primary text-white fw-bold text-uppercase rounded-0 px-4 py-2'
						onClick={onCompleteLesson}
					>
						Marcați ca fiind complet
					</button>
				)}
			</div>
		</div>
	);
};

export default LessonText;
