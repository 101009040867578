import * as Yup from 'yup';

export const passwordValidations = Yup.object().shape({
	currentPassword: Yup.string().required('Acest câmp este obligatoriu'),
	newPassword: Yup.string()
		.min(8, 'Parola trebuie să fie de cel puțin 8 caractere')
		.max(50, 'Parola nu trebuie să depășească 50 de caractere')
		.matches(/[a-z]/, 'Parola trebuie să conțină cel puțin o literă mică')
		.matches(/[A-Z]/, 'Parola trebuie să conțină cel puțin o literă mare')
		.matches(/\d/, 'Parola trebuie să conțină cel puțin o cifră')
		.matches(
			/[*@!#%&()^~{}]+/,
			'Parola trebuie să conțină cel puțin un caracter special'
		)
		.required('Acest câmp este obligatoriu'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('newPassword'), null], 'Parolele nu sunt identice.')
		.required('Acest câmp este obligatoriu'),
});

export const profileUpdateFormSchema = Yup.object({
	firstName: Yup.string().trim().required('Acest câmp este obligatoriu'),
	lastName: Yup.string().trim().required('Acest câmp este obligatoriu'),
	email: Yup.string()
		.trim()
		.email('Vă rugăm să furnizați un e-mail valid')
		.required('Acest câmp este obligatoriu'),
	phone: Yup.string()
		.matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number')
		.optional(),
});
