import React from 'react';
import CustomSelect from '../CustomSelect';
import { filterBy } from '../../StaticData';

const options = [
	{ value: 'price:asc', label: filterBy.ascendingPrice },
	{ value: 'price:desc', label: filterBy.descendingPrice },
];

const ProductFiltersBar = ({
	selectedCatTitle,
	productsCount,
	selectedFilter,
	onChangeFilter,
}) => {
	return (
		<div className='card non-hoverable  border-0 w-100 mb-3'>
			<div className='card-body d-flex justify-content-between align-items-center'>
				<p className='h4 fw-bold mb-0'>
					{selectedCatTitle}{' '}
					{productsCount && (
						<span className='fw-light h6'>{`(${productsCount} Items)`}</span>
					)}
				</p>
				<div className='filter-section'>
					<CustomSelect
						placeholder={filterBy.displayName}
						options={options}
						selected={selectedFilter}
						onChange={onChangeFilter}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductFiltersBar;
