import { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
	loadOrdersHistory,
	resetOrdersList,
	selectIsOrdersHistoryLoading,
	selectOrdersHistoryError,
	selectOrdersList,
} from '../../Store/ordersHistory';
import { Currency } from '../../components/Currency';
import { SelectedProdVariaties } from '../../components/SelectedProdVariaties';
import { LocalizedDate } from '../../components/LocalizedDate';

const OrdersHistory = () => {
	const dispatch = useDispatch();
	const isLoading = useSelector(selectIsOrdersHistoryLoading);
	const loadError = useSelector(selectOrdersHistoryError);
	const orderList = useSelector(selectOrdersList);

	useEffect(() => {
		dispatch(loadOrdersHistory());
		return () => {
			dispatch(resetOrdersList());
		};
	}, []);

	return (
		<div className='row my-5'>
			{isLoading || loadError ? (
				<div className='text-center my-5'>
					{isLoading && (
						<div className='spinner-border text-primary' role='status'></div>
					)}
					{loadError && (
						<p className='h5 fw-normal text-muted'>
							{loadError || 'Products loading failed.'}
						</p>
					)}
				</div>
			) : (
				<>
					{orderList.map((orderItem) => (
						<div key={orderItem.id} className='col-12 my-3'>
							<div className={clsx('card', 'non-hoverable', 'border-0', 'p-4')}>
								<div className='row'>
									<div className='col-8 p-2'>
										{orderItem.orderNumber && (
											<p className='fw-bold h4 mb-0'>
												<span className='me-2'>AWB:</span>
												<a
													href='https://www.fancourier.ro/en/awb-tracking/'
													target='_blank'
												>
													{orderItem.orderNumber}
												</a>
											</p>
										)}
										<p className='fw-bold text-muted'>
											<LocalizedDate
												date={orderItem.orderDate}
												dateStyle='medium'
												timeStyle='short'
											/>
										</p>
									</div>
									{orderItem.status && (
										<div className='col-4 p-2 text-end'>
											<span
												className={clsx({
													'fw-bold text-uppercase': true,
													'text-success':
														['delivered', 'completed'].includes(
															orderItem.status
														) || false,
													'text-danger': ['cancelled'].includes(
														orderItem.status
													),
													'text-info': ['in progress'].includes(
														orderItem.status
													),
												})}
											>
												{orderItem.status}
											</span>
										</div>
									)}
									<div className='col-6 p-2'>
										{orderItem.products.length > 0 && (
											<div className=''>
												<p className='fw-bold text-uppercase mb-2'>Produse</p>
												{orderItem.products.map((product, index) => (
													<div
														key={`${orderItem.id}_${product.sku || product.id}`}
														className={clsx({
															'mb-3': index + 1 < orderItem.products.length,
														})}
													>
														<p className='mb-0'>
															{product.quantity}x {product.name}
														</p>
														{product.variations && (
															<SelectedProdVariaties
																className='small'
																curvature={product.variations.curvature}
																thickness={product.variations.thickness}
																length={product.variations.length}
															/>
														)}
													</div>
												))}
											</div>
										)}
										{orderItem.courses && orderItem.courses.length > 0 && (
											<div className='mt-3'>
												<p className='fw-bold text-uppercase mb-2'>Cursuri</p>
												{orderItem.courses.map((courseItem, index) => (
													<div
														key={`${orderItem.id}_${courseItem.id}`}
														className={clsx({
															'mb-3': index + 1 < orderItem.courses.length,
														})}
													>
														<p className='mb-0'>
															{courseItem.name}
															{courseItem.orderType && (
																<span className='text-capitalize small fst-italic text-muted ms-1'>
																	({courseItem.orderType})
																</span>
															)}
														</p>
													</div>
												))}
											</div>
										)}
									</div>
									<div className='col-6 p-2 text-end'>
										<p className='mb-1 h3 fw-bold ms-2'>
											<Currency price={orderItem.total} />
										</p>
										<p className='mb-1 fw-bold ms-2 text-muted'>
											{orderItem.isPrepaid === 'true' ? 'Card' : 'Numerar'}
										</p>
									</div>
									<div className='col-sm-6 p-2'>
										<p className='fw-bold small text-muted border-bottom'>
											Adresa de Facturare
											{orderItem.billingAddress.isBusinessAddress && (
												<span className='ms-2'>&mdash; Persoană Juridică</span>
											)}
										</p>
										<div className=''>
											<p className='mb-1'>
												{orderItem.billingAddress.name}
												<span className='ms-2'>
													{orderItem.billingAddress.phone}
												</span>
											</p>
											<p className='mb-1'>{orderItem.billingAddress.street}</p>
											<p className='mb-1'>
												{orderItem.billingAddress.city},{' '}
												{orderItem.billingAddress.zipCode}
											</p>
											<p className='mb-1'>{orderItem.billingAddress.country}</p>
										</div>
									</div>
									{!orderItem.skipShipping && (
										<div className='col-sm-6 p-2'>
											<p className='fw-bold small text-muted border-bottom'>
												{orderItem.isSelfPickup
													? 'Punct Colectare'
													: 'Adresa de Livrare'}
											</p>
											{orderItem.shippingAddress && (
												<div className=''>
													<p className='mb-1'>
														{orderItem.shippingAddress.name}
														<span className='ms-2'>
															{orderItem.shippingAddress.phone}
														</span>
													</p>
													<p className='mb-1'>
														{orderItem.shippingAddress.street}
													</p>
													<p className='mb-1'>
														{orderItem.shippingAddress.city},{' '}
														{orderItem.shippingAddress.zipCode}
													</p>
													<p className='mb-1'>
														{orderItem.shippingAddress.country}
													</p>
												</div>
											)}
											{orderItem.isSelfPickup && orderItem.pickupLocation && (
												<div className=''>
													<p className='mb-1'>
														{orderItem.pickupLocation.phone}
													</p>
													<a href={orderItem.pickupLocation.mapLocation}>
														{orderItem.pickupLocation.address} ,
														{orderItem.pickupLocation.city}
													</a>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					))}
					{orderList.length === 0 && (
						<p className='col-12 h5 text-center my-5'>
							Momentan nu ai nici o comandă plasată.
						</p>
					)}
				</>
			)}
		</div>
	);
};

export default OrdersHistory;
