import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../Firebase';

const initialState = {
	courseConsole: null,
	courseConsoleLoading: true,
	courseConsoleError: null,
	activeLessonId: null,
	completedLessons: [],
	videoPlayPoint: 0,
};

export const loadCourseConsole = createAsyncThunk(
	'courseConsole/loadCourseConsole',
	async (courseSku, { fulfillWithValue, rejectWithValue, dispatch }) => {
		const getCourseConsole = httpsCallable(functions, 'getCourseConsole');
		const { data: result } = await getCourseConsole(courseSku);
		if (result.success) {
			if (result.courseProgress) {
				dispatch(setCourseProgressData(result.courseProgress));
			}
			return fulfillWithValue(result);
		} else {
			return rejectWithValue(result.message);
		}
	}
);

export const syncCourseProgress = createAsyncThunk(
	'courseConsole/syncCourseProgress',
	async (_, { getState, dispatch }) => {
		const { courseConsole, activeLessonId, completedLessons, videoPlayPoint } =
			getState().courseConsole;
		const syncCourseProgress = httpsCallable(functions, 'syncCourseProgress');
		syncCourseProgress({
			subscriptionId: courseConsole.subscriptionId,
			totalLessons: courseConsole.totalLectures || 0,
			activeLessonId,
			completedLessons,
			videoPlayPoint,
		});
		dispatch(resetCourseConsole());
	}
);

const slice = createSlice({
	name: 'courseConsole',
	initialState,
	reducers: {
		setCourseProgressData: (state, { payload }) => {
			state.activeLessonId = payload.activeLessonId;
			state.completedLessons = payload.completedLessons;
			state.videoPlayPoint = payload.videoPlayPoint;
		},
		resetCourseConsole: (state) => {
			state.courseConsole = null;
			state.courseConsoleLoading = true;
			state.courseConsoleError = null;
			state.activeLessonId = null;
			state.completedLessons = [];
			state.videoPlayPoint = 0;
		},
		setActiveLessonId: (state, { payload }) => {
			state.activeLessonId = payload;
		},
		addLessonToCompletedList: (state, { payload }) => {
			const lessons = [...state.completedLessons];
			lessons.push(payload);
			state.completedLessons = lessons;
		},
		setVideoPlayPoint: (state, { payload }) => {
			state.videoPlayPoint = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadCourseConsole.pending, (state, action) => {
			state.courseConsole = null;
			state.courseConsoleError = null;
			state.courseConsoleLoading = true;
		});
		builder.addCase(loadCourseConsole.fulfilled, (state, { payload }) => {
			state.courseConsole = payload;
			state.courseConsoleLoading = false;
		});
		builder.addCase(loadCourseConsole.rejected, (state, action) => {
			state.courseConsole = null;
			state.courseConsoleError =
				action.payload ||
				action.error.message ||
				"Couldn't load the data, please refresh.";
			state.courseConsoleLoading = false;
		});
	},
});

// ACTIONS
export const {
	resetCourseConsole,
	setCourseProgressData,
	setActiveLessonId,
	setVideoPlayPoint,
	addLessonToCompletedList,
} = slice.actions;

// SELECTORS
const selectCourseConsoleData = (state) => {
	return state.courseConsole;
};

export const selectCourseConsole = createSelector(
	selectCourseConsoleData,
	(coursesData) => ({
		courseConsole: coursesData.courseConsole,
		courseConsoleLoading: coursesData.courseConsoleLoading,
		courseConsoleError: coursesData.courseConsoleError,
		activeLessonId: coursesData.activeLessonId,
		completedLessons: coursesData.completedLessons,
		videoPlayPoint: coursesData.videoPlayPoint,
	})
);

export default slice.reducer;
