import clsx from 'clsx';
import { useMemo, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { BsPlayCircle, BsFileEarmarkText } from 'react-icons/bs';

import styles from './CourseDetail.module.scss';

const CourseContent = ({ contentSection, totalLectures, totalDuration }) => {
	const [activeKeys, setActiveKeys] = useState([0]);

	const handleSelect = (eventKey) => setActiveKeys(eventKey);
	const handleActionForAll = () => {
		if (activeKeys.length !== contentSection.length) {
			const allKeys = Array.from(Array(contentSection.length).keys());
			setActiveKeys(allKeys);
		} else {
			setActiveKeys([]);
		}
	};

	const actionAllBtnText = useMemo(() => {
		return activeKeys.length !== contentSection.length
			? 'Expand All'
			: 'Collapse All';
	}, [activeKeys.length, contentSection.length]);

	return (
		<div className='mt-4'>
			<h3>Course Content</h3>
			<div className='d-flex justify-content-between align-items-center flex-wrap'>
				<p className={styles.contentSummary}>
					<span>{contentSection.length} Secțiuni</span>
					<span>{totalLectures} Lecții</span>
					<span>{totalDuration} Durata totală</span>
				</p>
				<div className=''>
					<button
						className='btn btn-link text-primary fw-bold mb-0 px-0'
						onClick={handleActionForAll}
					>
						{actionAllBtnText}
					</button>
				</div>
			</div>
			<Accordion
				className={styles.courseContentAccordian}
				alwaysOpen={true}
				activeKey={activeKeys}
				onSelect={handleSelect}
			>
				{contentSection.map((courseGroup, index) => (
					<Accordion.Item key={index} eventKey={index}>
						<Accordion.Header>
							<div className='d-flex justify-content-between align-items-center w-100 ps-4 flex-wrap'>
								<span className='fw-bold text-uppercase text-muted'>
									{courseGroup.groupTitle.substring(0, 40)}
									{courseGroup.groupTitle.length > 40 && '...'}
								</span>
								<div className={clsx(styles.contentSummary, 'text-dark')}>
									<span>{courseGroup.totalLectures} Lecții</span>
									<span>{courseGroup.duration}</span>
								</div>
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<ul className='mb-0 ps-0'>
								{courseGroup.lectures.map((lecture, index) => (
									<li className='d-flex justify-content-between align-items-center py-2'>
										<div className='d-flex align-items-center'>
											{lecture.lectureType === 'video' ? (
												<BsPlayCircle className='default' size={15} />
											) : (
												<BsFileEarmarkText className='default' size={15} />
											)}
											<span className='ms-2 fw-normal'>
												{lecture.lectureTitle.substring(0, 70)}
												{courseGroup.groupTitle.length > 70 && '...'}
											</span>
										</div>
										<span className=''>{lecture.duration}</span>
									</li>
								))}
							</ul>
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
		</div>
	);
};

export default CourseContent;
