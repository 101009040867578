import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { BsChevronDown, BsList } from 'react-icons/bs';

import styles from './SidbarCategories.module.scss';

const SidbarCategories = ({
	isLoading,
	error,
	categoriesList,
	selected,
	onCategorySelected,
}) => {
	const [isSectionCollapsed, setIsSectionCollapsed] = useState(false);
	const [openedCategory, setOpenedCategory] = useState(selected);

	const onSectionToggler = useCallback(() => {
		setIsSectionCollapsed(!isSectionCollapsed);
	}, [isSectionCollapsed]);

	const onToggleCategoryPanel = useCallback(
		(event, categoryId) => {
			event.stopPropagation();
			event.preventDefault();
			if (categoryId === openedCategory) {
				setOpenedCategory('');
			} else {
				setOpenedCategory(categoryId);
			}
		},
		[openedCategory]
	);

	const showSubcategories = useCallback(
		(categoryId, subCatList) => {
			if (openedCategory === categoryId) {
				return true;
			}
			const selectedSubCategory = subCatList.find(
				(subCat) => subCat.id === openedCategory
			);
			if (selectedSubCategory && selectedSubCategory.parent === categoryId) {
				return true;
			}
			return false;
		},
		[openedCategory]
	);

	useEffect(() => {
		setOpenedCategory(selected);
	}, [selected]);

	return (
		<div
			className={clsx(
				styles.leftSidebar,
				'card',
				'my-3 my-md-4',
				'non-hoverable'
			)}
		>
			<div className={styles.panelHeader}>
				<h2>Categories</h2>
				<BsList
					className={clsx(styles.collapseIcon, 'd-md-none')}
					onClick={onSectionToggler}
				/>
			</div>
			<div
				className={clsx({
					[styles.collapsableSidebar]: true,
					[styles.sectionCollapsed]: isSectionCollapsed,
				})}
			>
				<div className='p-4'>
					{isLoading || error ? (
						<div className='text-center my-5'>
							{isLoading && (
								<div
									className='spinner-border text-primary'
									role='status'
								></div>
							)}
							{error && (
								<p className='h5 fw-normal text-muted'>
									{error || 'Categories loading failed.'}
								</p>
							)}
						</div>
					) : (
						categoriesList.map((category) => (
							<div key={category.id} className={styles.panel}>
								<div className={styles.panelHeading}>
									<h4>
										<span
											className={clsx({
												[styles.categoryItemTitle]: true,
												[styles.active]: selected === category.id,
											})}
											onClick={() =>
												onCategorySelected(category.id, category.displayName)
											}
										>
											<span>{category.displayName}</span>
											{category.subCategories.length > 0 && (
												<BsChevronDown
													className={clsx({
														[styles.categoryBadge]: true,
														[styles.upsideArrow]: showSubcategories(
															category.id,
															category.subCategories
														),
													})}
													onClick={(e) => onToggleCategoryPanel(e, category.id)}
												/>
											)}
										</span>
									</h4>
								</div>
								{category.subCategories.length > 0 && (
									<div
										className={clsx({
											'panel-collapse': true,
											[styles.collapsable]: true,
											[styles.show]: showSubcategories(
												category.id,
												category.subCategories
											),
										})}
									>
										<div className='panel-body'>
											<ul className={styles.subList}>
												{category.subCategories.map((subCategory) => (
													<li key={subCategory.id}>
														<span
															className={clsx({
																[styles.categoryItemTitle]: true,
																[styles.categorySubItemTitle]: true,
																[styles.active]: selected === subCategory.id,
															})}
															onClick={() =>
																onCategorySelected(
																	subCategory.id,
																	subCategory.displayName
																)
															}
														>
															{subCategory.displayName}
														</span>
													</li>
												))}
											</ul>
										</div>
									</div>
								)}
							</div>
						))
					)}
				</div>
			</div>
		</div>
	);
};

export default SidbarCategories;
