import {
	createAsyncThunk,
	createSelector,
	createSlice,
	isAnyOf,
} from '@reduxjs/toolkit';
import { doc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { signOut, sendEmailVerification, deleteUser } from 'firebase/auth';
import { toast } from 'react-toastify';
import { fireDB, functions, auth } from '../../Firebase';
import { setShowEmailVerificationAlert } from '../../Store/appConfigs';
import { clearCart } from '../Cart';
import { clearFavorites } from '../Favorites';

const initialState = {
	userInfo: null,
	userToken: '',
	isLoading: false,
	preferences: null,
	userUpdateLoading: false,
	userUpdateSuccess: null,
	userUpdateError: null,
};

export const updateProductVariantPreference = createAsyncThunk(
	'user/updateProductVarient',
	async (data, { getState, rejectWithValue, fulfillWithValue }) => {
		const user = getState().user.userInfo;
		if (!user) {
			return rejectWithValue('Please login to view your orders history.');
		}
		const userRef = doc(fireDB, 'users', user.uid);
		await updateDoc(userRef, {
			[`preferences.selectedVarients.${data.productId}`]: data.productVariant,
		});
		return fulfillWithValue(data);
	}
);

export const updateCourseClassTimePreference = createAsyncThunk(
	'user/updateCourseClassTimePreference',
	async (data, { getState, rejectWithValue, fulfillWithValue }) => {
		const user = getState().user.userInfo;
		if (!user) {
			return rejectWithValue('Please login update your preferences.');
		}
		const userRef = doc(fireDB, 'users', user.uid);
		await updateDoc(userRef, {
			[`preferences.preferedCoursesClasses.${data.courseId}`]:
				data.selectedClassTime,
		});
		return fulfillWithValue(data);
	}
);

export const setUser = createAsyncThunk(
	'user/setUser',
	(data, { dispatch, fulfillWithValue }) => {
		const parsedData = JSON.parse(data);
		dispatch(setShowEmailVerificationAlert(!parsedData.user.emailVerified));
		return fulfillWithValue(parsedData);
	}
);

export const updateProfileData = createAsyncThunk(
	'user/updateProfileData',
	async (data, { dispatch }) => {
		const updateMyProfileFunc = httpsCallable(functions, 'updateMyProfile');
		const { data: result } = await updateMyProfileFunc(data);
		if (result.success) {
			dispatch(setUserUpdatedData(data));
			if (data.emailChanged) {
				toast.success('Please re-authenticate to continue.');
				dispatch(logoutUser());
			}
		}
		return result;
	}
);

export const updateAddresses = createAsyncThunk(
	'user/updateAddresses',
	async (data, { getState, dispatch }) => {
		try {
			const { userInfo } = getState().user;
			const { addressType, updatedAddress } = data;
			if (!userInfo) {
				return {
					success: false,
					message: 'Please login to update your address.',
				};
			}
			const userRef = doc(fireDB, 'users', userInfo.uid);
			await updateDoc(userRef, {
				[`preferences.${addressType}`]: updatedAddress,
			});

			dispatch(setPrefferedAddress(data));

			return {
				success: true,
				message: 'Your address has been updated successfully.',
			};
		} catch (error) {
			console.log('ADDRESS UPDATE ERROR::', error);
			return {
				success: false,
				message: 'Could not update your address, please try again.',
			};
		}
	}
);

export const resetPreferences = createAsyncThunk(
	'user/resetPreferences',
	async (_, { getState, dispatch }) => {
		try {
			const { userInfo } = getState().user;
			if (!userInfo) {
				return {
					success: false,
					message: 'Please login to reset your data.',
				};
			}
			const userRef = doc(fireDB, 'users', userInfo.uid);
			await updateDoc(userRef, {
				preferences: {},
			});
			dispatch(setUserPreferences({}));

			return {
				success: true,
				message: 'Your preferences have been updated successfully.',
			};
		} catch (error) {
			console.log('ACCOUNT RESET::', error);
			return {
				success: false,
				message: 'Could not reset your preferences, please try again.',
			};
		}
	}
);

export const deleteMyAccount = createAsyncThunk(
	'user/deleteMyAccount',
	async (_, { getState, dispatch }) => {
		try {
			const { userInfo } = getState().user;
			if (!userInfo) {
				return {
					success: false,
					message: 'Please login to delete your account.',
				};
			}

			await deleteUser(auth.currentUser);

			dispatch(logoutUser());

			return {
				success: true,
				message: 'Your account has been deleted.',
			};
		} catch (error) {
			console.log('ACCOUNT DELETION FAILED::', error);
			return {
				success: false,
				message: 'Could not delete your account, please try again.',
			};
		}
	}
);

export const logoutUser = createAsyncThunk(
	'user/logoutUser',
	async (_, { dispatch }) => {
		await signOut(auth);

		// Sign-out successful.
		toast.success('Ai fost delogat(ă) cu succes.');
		dispatch(logout());
		dispatch(setShowEmailVerificationAlert(false));
		dispatch(clearCart());
		dispatch(clearFavorites());
		dispatch(setUserPreferences(null));
	}
);

export const sendEmailForVerification = createAsyncThunk(
	'user/sendEmailForVerification',
	async (authUser) => {
		try {
			await sendEmailVerification(authUser);
			toast.success('Un email de verificare a fost trimis.');
			const userRef = doc(fireDB, 'users', authUser.uid);
			await updateDoc(userRef, {
				shouldSendVerficationEmail: false,
			});
		} catch (error) {
			toast.error('Email-ul nu a putut fi trimis. Încearcă mai târziu.');
		}
	}
);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logout: (state) => {
			state.userInfo = null;
			state.userToken = '';
		},
		setUserPreferences: (state, { payload }) => {
			state.preferences = payload;
		},
		setUserUpdatedData: (state, { payload }) => {
			state.userInfo = { ...state.userInfo, ...payload };
		},
		resetUserUpdateStatus: (state) => {
			state.userUpdateLoading = false;
			state.userUpdateError = null;
			state.userUpdateSuccess = null;
		},
		setPrefferedAddress: (state, { payload }) => {
			state.preferences = {
				...state.preferences,
				[payload.addressType]: payload.updatedAddress,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			updateProductVariantPreference.fulfilled,
			(state, { payload }) => {
				state.preferences = state.preferences
					? {
						...state.preferences,
						selectedVarients: {
							...state.preferences.selectedVarients,
							[payload.productId]: payload.productVariant,
						},
					}
					: {
						selectedVarients: {
							[payload.productId]: payload.productVariant,
						},
					};
			}
		);
		builder.addCase(
			updateCourseClassTimePreference.fulfilled,
			(state, { payload }) => {
				state.preferences = state.preferences
					? {
						...state.preferences,
						preferedCoursesClasses: {
							...state.preferences.preferedCoursesClasses,
							[payload.courseId]: payload.selectedClassTime,
						},
					}
					: {
						preferedCoursesClasses: {
							[payload.courseId]: payload.selectedClassTime,
						},
					};
			}
		);
		builder.addCase(setUser.fulfilled, (state, { payload }) => {
			state.userInfo = payload.user;
			state.userToken = payload.user.stsTokenManager.accessToken;
		});
		builder.addMatcher(
			isAnyOf(
				updateProfileData.pending,
				updateAddresses.pending,
				resetPreferences.pending
			),
			(state) => {
				state.userUpdateLoading = true;
				state.userUpdateError = null;
				state.userUpdateSuccess = null;
			}
		);
		builder.addMatcher(
			isAnyOf(
				updateProfileData.fulfilled,
				updateAddresses.fulfilled,
				resetPreferences.fulfilled
			),
			(state, { payload }) => {
				state.userUpdateLoading = false;
				state.userUpdateError = payload.success ? null : payload.message;
				state.userUpdateSuccess = payload.success ? payload.message : null;
			}
		);
	},
});

export const {
	logout,
	setUserPreferences,
	setUserUpdatedData,
	resetUserUpdateStatus,
	setPrefferedAddress,
} = userSlice.actions;

const selectUserData = (state) => {
	return state.user;
};

export const selectLoggedinUser = createSelector(
	selectUserData,
	({ userInfo }) => userInfo
);

export const selectIsUserEmailVerified = createSelector(
	selectUserData,
	({ userInfo }) => userInfo?.emailVerified
);

export const selectUserPreferences = createSelector(
	selectUserData,
	({ preferences }) => preferences
);

export const selectUserUpdateStatus = createSelector(
	selectUserData,
	({ userUpdateLoading, userUpdateSuccess, userUpdateError }) => ({
		userUpdateLoading,
		userUpdateSuccess,
		userUpdateError,
	})
);

export default userSlice.reducer;
