import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const SliderHeader = ({
	title,
	onArrowLeft,
	onArrowRight,
	showSliderArrows,
}) => {
	return (
		<div className='row mb-1'>
			<h4 className='col-6 text-uppercase mb-0'>{title}</h4>
			{showSliderArrows && (
				<div className='col-6 text-end'>
					<button className='btn p-1 border-0' onClick={onArrowLeft}>
						<BsChevronLeft />
					</button>
					<button className='btn p-1 border-0' onClick={onArrowRight}>
						<BsChevronRight />
					</button>
				</div>
			)}
		</div>
	);
};

export default SliderHeader;
