import clsx from 'clsx';
import { BsStarFill } from 'react-icons/bs';
import { Currency } from '../../components/Currency';
import styles from './CourseDetail.module.scss';

const FloatingHeader = ({
	showFloatingHeader,
	isAccredited,
	title,
	averageRating,
	totalReviews,
	oldPrice,
	price,
	totalPurchases,
	allowBuyCourse,
	allowPayRemaingAmount,
	allowedToCourseConsole,
	onQuickCheckout,
	goToCourseConsole,
}) => {
	return (
		<div
			className={clsx({
				[styles.floatingHeader]: true,
				shadow: true,
				[styles.show]: showFloatingHeader,
			})}
		>
			<div className={clsx(styles.floatingHeaderInner, 'container')}>
				<div className=''>
					<h1>{title}</h1>
					<div className='d-flex gap-3'>
						<p className='d-flex gap-1 align-items-center mb-0'>
							{isAccredited && (
								<span className={styles.acrediationBadge}>Acreditat</span>
							)}
							<span className='fw-bold text-primary'>{averageRating}</span>
							<BsStarFill
								className={clsx('default text-primary')}
								size={'14px'}
							/>
							<span>({totalReviews})</span>
						</p>
						<p className='mb-0'>{totalPurchases} studenți absolvenți</p>
					</div>
				</div>
				<div className={styles.headerButtons}>
					<p className='d-flex flex-column mb-0'>
						{oldPrice > 0 && (
							<Currency
								className='small text-decoration-line-through'
								price={oldPrice}
							/>
						)}
						<Currency className='fw-bold lead' price={price} />
					</p>
					{allowBuyCourse && (
						<button
							className='btn btn-primary text-white px-4 text-no-wrap text-uppercase fw-bold'
							onClick={() => onQuickCheckout('full')}
						>
							Plătește integral
						</button>
					)}
					{allowPayRemaingAmount && (
						<button
							className='btn btn-primary text-white px-2 text-no-wrap text-uppercase fw-bold'
							onClick={() => onQuickCheckout('outstanding')}
						>
							Achită Restul de Plată
						</button>
					)}
					{allowedToCourseConsole && (
						<button
							className='btn btn-primary text-white px-4 text-no-wrap text-uppercase fw-bold'
							onClick={goToCourseConsole}
						>
							Consolă Curs
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default FloatingHeader;
