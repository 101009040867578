import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsEyeFill, BsEyeSlashFill, BsInfoCircleFill } from 'react-icons/bs';
import { auth } from '../../Firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import * as Yup from 'yup';
import { getFirebaseErrorMessageByCode } from '../../utils/firebaseErrors';
import { generateRandomPassword } from '../../utils/randomPassword';

import styles from './AuthForm.module.scss';

const registrationSchema = Yup.object({
	firstName: Yup.string().required('Acest câmp este obligatoriu'),
	lastName: Yup.string().required('Acest câmp este obligatoriu'),
	email: Yup.string()
		.email('Vă rugăm să furnizați un e-mail valid')
		.required('Acest câmp este obligatoriu'),
	password: Yup.string()
		.min(8, 'Parola trebuie să fie de cel puțin 8 caractere')
		.max(50, 'Parola nu trebuie să depășească 50 de caractere')
		.matches(/[a-z]/, 'Parola trebuie să conțină cel puțin o literă mică')
		.matches(/[A-Z]/, 'Parola trebuie să conțină cel puțin o literă mare')
		.matches(/\d/, 'Parola trebuie să conțină cel puțin o cifră')
		.matches(
			/[*@!#%&()^~{}]+/,
			'Parola trebuie să conțină cel puțin un caracter special'
		)

		.required('Acest câmp este obligatoriu'),
	cPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Parolele nu sunt identice.')
		.required('Acest câmp este obligatoriu'),
});

export const RegisterFrom = ({ onActivateLoginTab, activeTab }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [passowrdVisible, setPassowrdVisible] = useState(false);
	const [confirmPassowrdVisible, setConfirmPassowrdVisible] = useState(false);

	const submitForm = async ({ firstName, lastName, email, password }) => {
		setIsLoading(true);
		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				toast.success('Contul a fost creat cu succes!');
				setIsLoading(false);
				const user = userCredential.user;
				updateProfile(user, {
					displayName: `${firstName} ${lastName}`,
				}).then(
					function () {
						onActivateLoginTab();
					},
					function (error) {
						console.log(error.message);
					}
				);
			})
			.catch((error) => {
				setIsLoading(false);
				console.error(error.message);
				const errorMessage = getFirebaseErrorMessageByCode(error.code);
				toast.error(errorMessage);
			});
	};

	const {
		values,
		handleChange,
		handleSubmit,
		setFieldValue,
		resetForm,
		touched,
		errors,
	} = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			cPassword: '',
		},
		validationSchema: registrationSchema,
		onSubmit: submitForm,
	});

	const onGenerateRandomPassword = () => {
		const generatedPass = generateRandomPassword();
		setFieldValue('password', generatedPass);
	};

	useEffect(() => {
		resetForm();
	}, [activeTab]);

	const renderPasswordRulesTooltip = (props) => (
		<Tooltip {...props}>
			<div className='text-start'>
				<p className='mb-1 fw-bold text-tiny'>
					Parola trebuie să aibă cel puțin:
				</p>
				<p className='mb-0'>8 personaje</p>
				<p className='mb-0'>1 caracter mic</p>
				<p className='mb-0'>1 caracter mare</p>
				<p className='mb-0'>1 caracter special</p>
				<p className='mb-0'>1 cifră</p>
			</div>
		</Tooltip>
	);

	return (
		<form onSubmit={handleSubmit}>
			<div>
				<h3 className={styles['Auth-form-title']}>Crează cont</h3>
				<div className='text-center style-text'>Ai deja cont?</div>
				<div className='row mt-2'>
					<div className='col-12'>
						<div className='form-group'>
							<label>Nume</label>
							<input
								name='lastName'
								type='text'
								className='form-control mt-1'
								placeholder='Nume'
								value={values.lastName}
								onChange={handleChange}
							/>
							{touched.lastName && errors.lastName && (
								<span className='text-danger tiny-text'>{errors.lastName}</span>
							)}
						</div>
					</div>
					<div className='col-12'>
						<div className='form-group'>
							<label>Prenume</label>
							<input
								name='firstName'
								type='text'
								className='form-control mt-1'
								placeholder='Prenume'
								value={values.firstName}
								onChange={handleChange}
							/>
							{touched.firstName && errors.firstName && (
								<span className='text-danger tiny-text'>
									{errors.firstName}
								</span>
							)}
						</div>
					</div>
				</div>
				<div className='col-12'>
					<div className='form-group'>
						<label>Email</label>
						<input
							name='email'
							type='email'
							className='form-control mt-1'
							placeholder='Adresă Email'
							value={values.email}
							onChange={handleChange}
						/>
						{touched.email && errors.email && (
							<span className='text-danger tiny-text'>{errors.email}</span>
						)}
					</div>
				</div>
				<div className='col-12'>
					<div className='form-group'>
						<label>Parola</label>
						<div className='input-group position-relative mt-1'>
							<input
								name='password'
								type={passowrdVisible ? 'text' : 'password'}
								className={clsx('form-control', styles.passwordInput)}
								placeholder='Parola'
								value={values.password}
								onChange={handleChange}
							/>
							<button
								className='btn btn-sm position-absolute h-100 top-0 end-0'
								type='button'
								onClick={() => setPassowrdVisible(!passowrdVisible)}
							>
								{passowrdVisible ? (
									<BsEyeSlashFill className='default' size={16} />
								) : (
									<BsEyeFill className='default' size={16} />
								)}
							</button>
						</div>
						{touched.password && errors.password ? (
							<span className='tiny-text text-danger'>{errors.password}</span>
						) : (
							<p className='text-muted mb-0 ms-0 mt-1 tiny-text d-flex align-center gap-1'>
								Vă rugăm să alegeți o parolă puternică și sigură.
								<span className=''>
									<OverlayTrigger
										placement='top'
										overlay={renderPasswordRulesTooltip}
									>
										<span>
											<BsInfoCircleFill className='default' size={12} />
										</span>
									</OverlayTrigger>
								</span>
							</p>
						)}
						<div>
							<button
								type='button'
								className='btn btn-sm text-primary px-0 fw-bold'
								onClick={onGenerateRandomPassword}
							>
								Generați parola
							</button>
						</div>
					</div>
				</div>
				<div className='col-12'>
					<div className='form-group'>
						<label>Confirmă parola</label>
						<div className='input-group position-relative mt-1'>
							<input
								name='cPassword'
								type={confirmPassowrdVisible ? 'text' : 'password'}
								className={clsx('form-control', styles.passwordInput)}
								placeholder='Confirmă parola'
								value={values.cPassword}
								onChange={handleChange}
							/>
							<button
								className='btn btn-sm position-absolute h-100 top-0 end-0'
								type='button'
								onClick={() =>
									setConfirmPassowrdVisible(!confirmPassowrdVisible)
								}
							>
								{confirmPassowrdVisible ? (
									<BsEyeSlashFill className='default' size={16} />
								) : (
									<BsEyeFill className='default' size={16} />
								)}
							</button>
						</div>
						{touched.cPassword && errors.cPassword && (
							<span className='text-danger tiny-text'>{errors.cPassword}</span>
						)}
					</div>
				</div>

				<div className='d-grid gap-2 mt-4'>
					<button
						type='submit'
						className='btn btn-primary submit text-white py-2 rounded-0'
						disabled={isLoading}
					>
						{isLoading ? 'Trimiterea' : 'Trimite'}
					</button>
				</div>
			</div>
		</form>
	);
};
