import { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { BsCalendar, BsClock, BsExclamationCircle } from 'react-icons/bs';
import LessonImages from './LessonImages';
import LessonText from './LessonText';
import LessonPlayer from './LessonPlayer';
import CourseContent from './CourseContent';
import { LocalizedDate } from '../../components/LocalizedDate';
import {
	addLessonToCompletedList,
	loadCourseConsole,
	selectCourseConsole,
	setActiveLessonId,
	setVideoPlayPoint,
	syncCourseProgress,
} from '../../Store/courseConsole';
import { getHumanReadableTimeDifference } from '../../utils/date.utils';
import { useSearchParam } from '../../hooks/general';
import { calculateCourseSku } from '../../utils/courses.utils';

import 'react-quill/dist/quill.snow.css';
import styles from './CourseConsole.module.scss';

const CourseConsole = () => {
	const dispatch = useDispatch();
	const { courseId } = useParams();
	const queryClassValue = useSearchParam('class');

	const [activeLessonLoading, setActiveLessonLoading] = useState(true);
	const [activeLesson, setActiveLesson] = useState(null);

	const {
		courseConsole,
		courseConsoleLoading,
		courseConsoleError,
		activeLessonId,
		completedLessons,
		videoPlayPoint,
	} = useSelector(selectCourseConsole);

	const courseExpiryText = useMemo(() => {
		if (courseConsole && courseConsole.expiresOn) {
			return getHumanReadableTimeDifference(courseConsole.expiresOn);
		}
		return null;
	}, [courseConsole]);

	const loadActiveLesson = (lessonGroupId, lessonIndex, lesson) => {
		const newLessonId = `${lessonGroupId}_${lessonIndex}`;
		const isLessonCompleted = completedLessons.some(
			(lessonId) => lessonId === newLessonId
		);
		setActiveLesson({ ...lesson, isCompleted: isLessonCompleted });
		setActiveLessonLoading(false);
		dispatch(setActiveLessonId(newLessonId));
	};

	const loadNextLesson = () => {
		// Reset the current lesson
		dispatch(setVideoPlayPoint(0));
		setActiveLessonLoading(true);
		setActiveLesson(null);

		const [groupId, lessonIndex] = activeLessonId.split('_');
		// Find Group Index
		const currentGroupIndex = courseConsole.courseContent.findIndex(
			(item) => item.contentGroupId === groupId
		);
		const currentGroup = courseConsole.courseContent[currentGroupIndex];
		let nextLessonIndex = parseInt(lessonIndex) + 1;
		if (currentGroup.lectures[nextLessonIndex]) {
			loadActiveLesson(
				groupId,
				nextLessonIndex,
				currentGroup.lectures[nextLessonIndex]
			);
			return;
		}

		// Get next group Id and make the first lecture active
		const nextGroup = courseConsole.courseContent[currentGroupIndex + 1];
		if (!nextGroup) {
			setActiveLessonLoading(false);
			return;
		}
		const nextGroupId = nextGroup.contentGroupId;
		loadActiveLesson(nextGroupId, 0, nextGroup.lectures[0]);
	};

	const loadActiveLessonById = (lessonId) => {
		const lessonsGroups = courseConsole.courseContent;
		if (!lessonsGroups) {
			setActiveLessonLoading(false);
			return;
		}
		// If no lessonId, then select the first one as active
		if (lessonId) {
			const [groupId, lessonIndex] = lessonId.split('_');
			const group = courseConsole.courseContent.find(
				(item) => item.contentGroupId === groupId
			);

			// If a) group not found, b) group have no lectures, c) no lecture with the index
			// Then call the function again to set the first lesson active
			if (!group || !group.lectures?.length || !group.lectures[lessonIndex]) {
				loadActiveLessonById(null);
			} else {
				loadActiveLesson(groupId, lessonIndex, group.lectures[lessonIndex]);
			}
		} else {
			const group = lessonsGroups[0];
			loadActiveLesson(group.contentGroupId, 0, group.lectures[0]);
		}
	};

	const markLessonAsCompleted = () => {
		dispatch(addLessonToCompletedList(activeLessonId));
		// load Next Lesson
		loadNextLesson();
	};

	const onChangeLesson = (nextLessonId) => {
		dispatch(setVideoPlayPoint(0));
		setActiveLessonLoading(true);
		setActiveLesson(null);
		// Heck for making it slightly delyed so that the state gets reset properly
		setTimeout(() => loadActiveLessonById(nextLessonId), 0);
	};

	const saveVideoPlayedProgress = (playedSeconds) => {
		dispatch(setVideoPlayPoint(playedSeconds));
	};

	const onVideoEnd = () => {
		activeLesson.isCompleted ? loadNextLesson() : markLessonAsCompleted();
	};

	useEffect(() => {
		const courseSku = calculateCourseSku(courseId, queryClassValue);
		dispatch(loadCourseConsole(courseSku));
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [courseId, queryClassValue]);

	useEffect(() => {
		if (courseConsole) {
			if (courseConsole.courseProgress) {
				loadActiveLessonById(courseConsole.courseProgress.activeLessonId);
			} else {
				loadActiveLessonById(null);
			}
		}
		return () => {
			if (courseConsole) {
				// Sync the course progress with activeCourses document
				dispatch(syncCourseProgress());
			}
		};
	}, [courseConsole]);

	return (
		<div className=''>
			{courseConsole && (
				<section className='position-relative'>
					<div className={styles.titleSection}>
						<div className='container'>
							<ul className={styles.breadcrumbTree}>
								<li>
									<Link to={'/courses'}>Cursuri</Link>
								</li>
								<li>
									<Link to={`/courses/${courseId}`}>
										{courseConsole.title.substring(0, 60)}
										{courseConsole.title.length > 60 && <span>...</span>}
									</Link>
								</li>
								<li className='active'>Consolă</li>
							</ul>
							<div>
								<h1>{courseConsole.title}</h1>
							</div>
						</div>
					</div>

					<div className='pb-5 bg-white'>
						<div className='container'>
							<div className='row'>
								{courseConsole.courseContent && (
									<div className='col-lg-4 order-lg-2'>
										<CourseContent
											contentSection={courseConsole.courseContent}
											activeLessonId={activeLessonId}
											completedLessons={completedLessons}
											onChangeLesson={onChangeLesson}
										/>
									</div>
								)}
								<div
									className={clsx({
										'col-lg-8': !!courseConsole.courseContent,
									})}
								>
									{activeLessonLoading ? (
										<div className='text-center my-5'>
											<span
												className='spinner-border text-primary'
												style={{ width: '3rem', height: '3rem' }}
											/>
											<p className='lead mt-3'>Se încarcă lecția</p>
										</div>
									) : activeLesson ? (
										<div className='pt-3'>
											{activeLesson.lectureType === 'video' && (
												<LessonPlayer
													videoUrl={activeLesson.videoUrl}
													videoPlayPoint={videoPlayPoint}
													saveVideoProgress={saveVideoPlayedProgress}
													onVideoEnd={onVideoEnd}
												/>
											)}
											<h4 className='mt-3'>{activeLesson.lectureTitle}</h4>
											{activeLesson.lectureType === 'text' && (
												<LessonText
													lessonText={activeLesson.lectureText}
													isCompleted={activeLesson.isCompleted}
													onCompleteLesson={markLessonAsCompleted}
													onNextLesson={loadNextLesson}
												/>
											)}
											{activeLesson.lectureType === 'images' && (
												<LessonImages
													lessonImages={activeLesson.lectureImages}
													isCompleted={activeLesson.isCompleted}
													onCompleteLesson={markLessonAsCompleted}
													onNextLesson={loadNextLesson}
												/>
											)}
										</div>
									) : (
										<div className='my-5 text-center lead'>
											Nicio lecție selectată.
										</div>
									)}
									<div className='pt-2 border-top'>
										<h3 className='mb-3'>Despre curs</h3>
										<div className=''>
											<div className=''>
												<span className='courseTypeBadge'>
													Curs {courseConsole.isOnline ? 'Online' : 'Fizic'}
												</span>
												{courseConsole.isAccredited && (
													<span className='accreditationBadge ms-1'>
														Acreditat
													</span>
												)}
											</div>
											<div className='my-3'>
												<p className='d-flex align-items-center gap-1 mt-2 h6'>
													<span>Cumpărat pe:</span>
													<LocalizedDate
														className='fw-bold'
														date={courseConsole.orderDate}
													/>
												</p>
												{courseConsole.isOnline && (
													<div>
														<p className='mb-2'>
															Durată curs:
															<span className='fw-bold ms-1'>
																{courseConsole.totalDuration}
															</span>
														</p>
														{courseExpiryText ? (
															<p className='mb-2'>
																<BsExclamationCircle
																	className='default me-1'
																	size={14}
																/>
																Cursul expiră în:
																<span className='fw-bold ms-1'>
																	{courseExpiryText}
																</span>
															</p>
														) : (
															<p className='mb-2'>
																Acces cur:
																<span className='fw-bold ms-1'>
																	{courseConsole.purchaseValidity} Zile
																</span>
															</p>
														)}
													</div>
												)}
												{courseConsole.startsOn && (
													<p className='d-flex align-items-center gap-1 mt-2 h6'>
														<BsCalendar className='default' size={14} />
														<span>Începe pe:</span>
														<LocalizedDate
															className='fw-bold'
															date={courseConsole.startsOn}
														/>
													</p>
												)}
												{courseConsole.completesOn && (
													<p className='d-flex align-items-center gap-1 mt-2 h6'>
														<BsCalendar className='default' size={14} />
														<span>Se încheie pe:</span>
														<LocalizedDate
															className='fw-bold'
															date={courseConsole.completesOn}
														/>
													</p>
												)}
												{courseConsole.courseLastUpdatedOn && (
													<p className='d-flex align-items-center gap-1 mt-2 h6'>
														<BsClock className='default' size={14} />
														<span>Updatat:</span>
														<LocalizedDate
															className='fw-bold'
															date={courseConsole.courseLastUpdatedOn}
														/>
													</p>
												)}
											</div>
										</div>
										<p>{courseConsole.detail}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			{courseConsoleLoading && (
				<div className='text-center my-5'>
					<span
						className='spinner-border text-primary'
						style={{ width: '3rem', height: '3rem' }}
					/>
					<p className='lead mt-3'>Se încarcă Consola de curs</p>
				</div>
			)}
			{courseConsoleError && (
				<div className='text-center my-5'>
					<h3>Error!!</h3>
					<p className='lead mt-3'>{courseConsoleError}</p>
					<div className=''>
						<Link
							className='btn btn-primary text-white px-5 py-2 text-uppercase'
							to='/courses'
						>
							View All Courses
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

export default CourseConsole;
