import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { EmailVerificationAlert } from '../../components/EmailVerificationAlert';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import {
	selectIfShowEmailVerificationAlert,
	setShowEmailVerificationAlert,
} from '../../Store/appConfigs';

import 'react-toastify/dist/ReactToastify.css';

export default function SharedLayout(props) {
	const location = useLocation();
	const dispatch = useDispatch();
	const showEmailAlert = useSelector(selectIfShowEmailVerificationAlert);

	const onCloseEmailVerificationAlert = () => {
		dispatch(setShowEmailVerificationAlert(false));
	};

	return (
		<div className='d-flex flex-column justify-content-between h-100'>
			<div>
				{showEmailAlert && (
					<EmailVerificationAlert
						onCloseAlert={onCloseEmailVerificationAlert}
					/>
				)}
				<Navbar />
				<ToastContainer />
				<div
					className={
						location.pathname.startsWith('/courses') ? '' : 'container'
					}
				>
					<Outlet />
				</div>
			</div>
			<Footer />
		</div>
	);
}
