import { useEffect, useMemo, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Rating } from 'react-simple-star-rating';
import { Tab, Tabs } from 'react-bootstrap';
import { BsHeart, BsHeartFill, BsCart3 } from 'react-icons/bs';
import { toast } from 'react-toastify';
import RelatedProducts from './RelatedProducts';
import ProductImagesSlider from './ProductImagesSlider';
import { Currency } from '../../components/Currency';
import { Reviews } from '../../components/Reviews';
import {
	loadProductDetail,
	selectIsProdDetailLoading,
	selectProdDetailError,
	selectProdDetail,
	resetProduct,
} from '../../Store/productDetail';
import CustomSelect from '../../components/CustomSelect';
import YoutubeEmbed from '../../components/YouTube';
import {
	addFavoriteItemAsync,
	removeFavoritesItemAsync,
} from '../../features/Favorites';
import { addMutltipleQuantityToCart } from '../../features/Cart';
import { updateProductVariantPreference } from '../../features/User';
import {
	useSelectFirstAvailableVarient,
	useSelectProductVarientStockByOptions,
} from '../../hooks/productsStock';
import {
	calculateProductSku,
	parseProductVariations,
} from '../../utils/products.utils';
import { getHumanReadableTimeDifference } from '../../utils/date.utils';
import { messages } from '../../StaticData';

import styles from './SingleProduct.module.scss';

const SingleProduct = () => {
	const dispatch = useDispatch();
	const { productId } = useParams();

	const isProdDetailLoading = useSelector(selectIsProdDetailLoading);
	const productDetailLoadError = useSelector(selectProdDetailError);
	const productDetail = useSelector(selectProdDetail);
	const { favItems } = useSelector((state) => state.favorites);
	const { cartItems, addToCart } = useSelector((state) => state.cart);
	const [availableCurvature, availableThickness, availableLength] =
		useSelectFirstAvailableVarient(productId);

	const [selectedQuantity, setSelectedQuantity] = useState(1);
	const [curvature, setCurvature] = useState('');
	const [thickness, setThickness] = useState('');
	const [length, setLength] = useState('');

	const [selectableCurvatures, setSelectableCurvatures] = useState([]);
	const [selectableThicknesse, setSelectableThicknesses] = useState({});
	const [selectableLengths, setSelectableLengths] = useState({});

	const selectedVariantStock = useSelectProductVarientStockByOptions(
		productId,
		curvature,
		thickness,
		length
	);

	const isFavourite = useMemo(() => {
		return (
			favItems.length > 0 &&
			favItems.some((item) => item.productId === productId)
		);
	}, [favItems, productId]);

	const productCarted = useMemo(() => {
		let sku = productId;
		if (productDetail?.variations) {
			sku = calculateProductSku(sku, curvature, thickness, length);
		}

		const cartPorduct = cartItems.find((item) => item.sku === sku);
		return cartPorduct ? cartPorduct.amount : 0;
	}, [
		cartItems,
		productId,
		curvature,
		thickness,
		length,
		productDetail?.variations,
	]);

	const discountExpiryText = useMemo(() => {
		if (
			productDetail &&
			productDetail.discountEndsAt &&
			productDetail.oldPrice
		) {
			return getHumanReadableTimeDifference(productDetail.discountEndsAt);
		}
		return null;
	}, [productDetail]);

	const productStock = productDetail?.variations
		? selectedVariantStock?.available || 0
		: productDetail?.stock;

	const productMaxLimit = productDetail?.variations
		? selectedVariantStock?.max || 0
		: productDetail?.maxLimitOrder;

	const onChangeCurvature = (value) => {
		setCurvature(value);
		setThickness('');
		setLength('');
	};
	const onChangeThickness = (value) => {
		setThickness(value);
		setLength('');
	};
	const onChangeLength = (value) => {
		setLength(value);
	};

	const decrementQuantity = () => {
		if (productStock === 0 || selectedQuantity === 1) {
			return;
		}
		setSelectedQuantity(selectedQuantity - 1);
	};

	const incrementQuantity = () => {
		if (productStock === 0) {
			return;
		}
		if (selectedQuantity + productCarted >= productStock) {
			toast.warning(messages.allStockAvailed);
			return;
		}
		if (selectedQuantity + productCarted >= productMaxLimit) {
			toast.warning(messages.maxStockAvailed);
			return;
		}

		setSelectedQuantity(selectedQuantity + 1);
	};

	const addProductToCart = async () => {
		try {
			if (productDetail.variations && !(curvature && thickness && length)) {
				toast.warning(messages.noVariantSelected);
				return;
			}
			if (
				selectedQuantity + productCarted > productStock ||
				selectedQuantity + productCarted > productMaxLimit
			) {
				toast.warning(messages.allStockAvailed);
				return;
			}
			await dispatch(
				addMutltipleQuantityToCart({
					productId: productId,
					quantity: selectedQuantity,
					variations: productDetail.variations
						? {
								curvature,
								thickness,
								length,
						  }
						: null,
				})
			);
			setSelectedQuantity(1);
		} catch (error) {}
	};

	const toggleFavorites = () => {
		if (!isFavourite) {
			dispatch(addFavoriteItemAsync(productId));
		} else {
			dispatch(removeFavoritesItemAsync(productId));
		}
	};

	useEffect(() => {
		dispatch(loadProductDetail(productId));
		setSelectedQuantity(1);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		return () => {
			dispatch(resetProduct());
		};
	}, [productId]);

	useEffect(() => {
		if (productDetail && productDetail.variations) {
			setCurvature(availableCurvature);
			setThickness(availableThickness);
			setLength(availableLength);
			const { curvaturesList, thicknessList, lengthsList } =
				parseProductVariations(productDetail.variations);

			setSelectableCurvatures(curvaturesList);
			setSelectableThicknesses(thicknessList);
			setSelectableLengths(lengthsList);
		} else {
			setCurvature('');
			setThickness('');
			setLength('');

			setSelectableCurvatures([]);
			setSelectableThicknesses({});
			setSelectableLengths({});
		}
	}, [productDetail]);

	useEffect(() => {
		if (curvature && thickness && length) {
			const variant = [curvature, thickness, length]
				.join('_')
				.replace(/ /g, '');
			dispatch(
				updateProductVariantPreference({
					productId: productId,
					productVariant: variant,
				})
			);
		}
	}, [curvature, thickness, length]);

	return (
		<div className={styles.pageContainer}>
			{isProdDetailLoading || productDetailLoadError ? (
				<div className='text-center my-5'>
					{isProdDetailLoading && (
						<div className='spinner-border text-primary' role='status'></div>
					)}
					{productDetailLoadError && (
						<p className='h5 fw-normal text-muted'>
							{productDetailLoadError || 'Product loading failed.'}
						</p>
					)}
				</div>
			) : (
				<>
					<div className={clsx('section', styles.breadcrumb)}>
						<div className='row w-100'>
							<div className='col-md-12'>
								<ul className={styles.breadcrumbTree}>
									<li>
										<Link to={'/'}>Home</Link>
									</li>
									<li>
										<Link to={'/'}>Products</Link>
									</li>
									<li>
										<Link to={`/?category=${productDetail.category.id}`}>
											{productDetail.category.displayName}
										</Link>
									</li>
									<li className='active'>{productDetail.title}</li>
								</ul>
							</div>
						</div>
					</div>

					<div className='section'>
						<div className='row'>
							<div className='col-md-6'>
								<ProductImagesSlider images={productDetail.images} />
							</div>
							<div className='col-md-6'>
								<div className={styles.productDetails}>
									<h2 className={styles['product-name']}>
										{productDetail.title}
									</h2>
									{productDetail.averageRating > 0 &&
										productDetail.reviews.length > 0 && (
											<div className={styles.productReviewContainer}>
												<Rating
													initialValue={productDetail.averageRating}
													readonly={true}
													allowFraction={true}
													size={18}
													SVGclassName='default'
													fillColor='#14a79a'
												/>
												<span className={styles['review-link']}>
													{productDetail.reviews.length} Review
												</span>
											</div>
										)}
									<div>
										<h3 className={styles['product-price']}>
											<Currency price={productDetail.price} />
											{productDetail.oldPrice && (
												<del className={styles['product-old-price']}>
													<Currency price={productDetail.oldPrice} />
												</del>
											)}
										</h3>
										<span
											className={clsx({
												[styles['product-available']]: productStock > 0,
												[styles['product-unavailable']]: productStock < 1,
											})}
										>
											{productStock > 0 ? 'In Stoc' : 'Stoc epuizat'}
										</span>
									</div>
									{discountExpiryText && (
										<p className='text-danger small'>
											Reducerea se termină în{' '}
											<span className='fw-bold'>{discountExpiryText}</span>
										</p>
									)}
									<p className={styles.typography}>
										{productDetail.description}
									</p>

									{productDetail.variations && (
										<div className='my-4 d-flex'>
											<div className=''>
												<label className='form-label'>curbura</label>
												<CustomSelect
													placeholder={'curbura'}
													options={selectableCurvatures}
													selected={curvature}
													onChange={({ value }) => onChangeCurvature(value)}
												/>
											</div>
											<div className='mx-2'>
												<label className='form-label'>grosime</label>
												<CustomSelect
													placeholder={'grosime'}
													options={selectableThicknesse[curvature] || []}
													selected={thickness}
													onChange={({ value }) => onChangeThickness(value)}
												/>
											</div>
											<div className=''>
												<label className='form-label'>lungime</label>
												<CustomSelect
													placeholder={'lungime'}
													options={
														selectableLengths[`${curvature}_${thickness}`] || []
													}
													selected={length}
													onChange={({ value }) => onChangeLength(value)}
												/>
											</div>
										</div>
									)}

									<div className={styles['add-to-cart']}>
										<div className={styles['qty-label']}>
											<span className='me-2'>Qty</span>
											<div className={styles['input-number']}>
												<span
													className={styles['qty-down']}
													onClick={decrementQuantity}
												>
													-
												</span>
												<input
													type='number'
													value={selectedQuantity}
													disabled
												/>
												<span
													className={styles['qty-up']}
													onClick={incrementQuantity}
												>
													+
												</span>
											</div>
										</div>
										<button
											className={styles['add-to-cart-btn']}
											disabled={productStock === 0 || addToCart.isLoading}
											onClick={addProductToCart}
										>
											<BsCart3 className={styles.cartIcon} /> add to cart
										</button>
									</div>

									<div className={styles['product-btns']}>
										<button
											className='btn btn-link p-0'
											onClick={toggleFavorites}
										>
											{isFavourite ? (
												<BsHeartFill className='heart active' />
											) : (
												<BsHeart />
											)}
											<span className='text-uppercase ms-2'>
												{isFavourite
													? 'Remove from wishlist'
													: 'add to wishlist'}
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						{productDetail.videoLinks && (
							<div className='row'>
								<div className='col'>
									<YoutubeEmbed embedId={productDetail.videoLinks.part1} />
								</div>
								<div className='col'>
									<YoutubeEmbed embedId={productDetail.videoLinks.part2} />
								</div>
							</div>
						)}
						<div className='row'>
							<div className='col-md-12'>
								<div className={styles.productTab}>
									<Tabs
										defaultActiveKey='details'
										className={clsx(styles.tabNav)}
									>
										<Tab
											tabClassName={styles.tabButton}
											eventKey='details'
											title='Detalii'
										>
											<div className='row'>
												<div className='col-md-12'>
													<p className={styles.typography}>
														{productDetail.detail}
													</p>
												</div>
											</div>
										</Tab>
										<Tab
											tabClassName={styles.tabButton}
											eventKey='reviews'
											title={`Recenzii (${productDetail.reviews.length})`}
										>
											<Reviews
												averageRating={productDetail.averageRating}
												reviews={productDetail.reviews}
											/>
										</Tab>
									</Tabs>
								</div>
							</div>

							<div className='col-md-12'>
								<RelatedProducts productId={productDetail.productId} />
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default SingleProduct;
