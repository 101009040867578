import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CoursesSlider from './CoursesSlider';
import FeaturedCourse from './FeaturedCourse';
import {
	selectFeaturedCourses,
	selectInPersonCourses,
	selectOnlineCourses,
	loadFeaturedCourse,
	loadOnlineCourses,
	loadInPersonCourses,
} from '../../Store/courses';

// import styles from './Courses.module.scss';

const Courses = () => {
	const dispatch = useDispatch();
	// Currently we are displaying only one featured course
	// But have designed schema to mark multiple courses as featured
	// So might switch to show multiple featured courses
	const { featuredCourses, featuredCoursesLoading } = useSelector(
		selectFeaturedCourses
	);
	const { onlineCourses, onlineCoursesLoading, onlineCoursesError } =
		useSelector(selectOnlineCourses);
	const { inPersonCourses, inPersonCoursesLoading, inPersonCoursesError } =
		useSelector(selectInPersonCourses);

	const fetchFeaturedCourses = () => {
		dispatch(loadFeaturedCourse());
	};
	const fetchOnlineCourses = () => {
		dispatch(loadOnlineCourses());
	};
	const fetchInPersonCourses = () => {
		dispatch(loadInPersonCourses());
	};

	useEffect(() => {
		fetchFeaturedCourses();
		fetchOnlineCourses();
		fetchInPersonCourses();
	}, []);

	return (
		<div className=''>
			<FeaturedCourse
				featuredCourse={
					featuredCourses[0] || onlineCourses[0] || inPersonCourses[0]
				}
				featuredCourseLoading={featuredCoursesLoading}
			/>
			<div>
				<div className='sectionHeader py-5'>
					<div className='container'>
						<CoursesSlider
							sliderTitle='Cursuri Online'
							courses={onlineCourses}
							coursesLoading={onlineCoursesLoading}
							loadError={onlineCoursesError}
							onReload={fetchOnlineCourses}
						/>
						<div className='mt-5 pt-4 border-top'>
							<CoursesSlider
								sliderTitle='Cursuri Fizice'
								courses={inPersonCourses}
								coursesLoading={inPersonCoursesLoading}
								loadError={inPersonCoursesError}
								onReload={fetchInPersonCourses}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Courses;
