import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	loadRelatedProducts,
	selectIsRelatedProdsLoading,
	selectRelatedProdsError,
	selectRelatedProdsList,
} from '../../Store/productDetail';
import Product from '../../components/Product';

const RelatedProducts = ({ productId }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(selectIsRelatedProdsLoading);
	const loadError = useSelector(selectRelatedProdsError);
	const relatedProducts = useSelector(selectRelatedProdsList);

	useEffect(() => {
		dispatch(loadRelatedProducts());
	}, [productId]);

	return (
		<div className='row'>
			<div className='col-md-12'>
				<div className='section-title text-center'>
					<h3 className='title'>Related Products</h3>
				</div>
			</div>
			{isLoading || loadError ? (
				<div className='text-center my-5'>
					{isLoading && (
						<div className='spinner-border text-primary' role='status'></div>
					)}
					{loadError && (
						<p className='h5 fw-normal text-muted'>
							{loadError || 'Product loading failed.'}
						</p>
					)}
				</div>
			) : (
				<>
					{relatedProducts.map((product) => (
						<div key={product.id} className='col-lg-3 col-sm-6'>
							<Product
								id={product.id}
								cover={product.cover}
								title={product.title}
								price={product.price}
								stock={product.stock}
								rating={product.averageRating}
								reviewCount={product.reviews?.length || 0}
								oldPrice={product.oldPrice}
								variations={product.variations}
							/>
						</div>
					))}
					{relatedProducts.length === 0 && (
						<p className='col-12 h5 text-center mb-2 text-muted'>
							No products found in this category at the moment.
						</p>
					)}
				</>
			)}
		</div>
	);
};

export default RelatedProducts;
