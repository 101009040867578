import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearCartAsync } from '../../features/Cart';
import { selectUserPreferences } from '../../features/User';
import { resetCoursesCart, toggleCourseCart } from '../../Store/coursesCart';
import { setQuickCheckoutCourse } from '../../Store/checkout';
import { useCoursesInCart } from '../../hooks/courses';

import CartItem from '../../components/CartItem';
import CartCourseItem from './CartCourseItem';
import Button from 'react-bootstrap/Button';
import CartSummary from '../../components/CartSummary';

import './styles.scss';

export default function Cart() {
	const dispatch = useDispatch();
	const [coupon, setCoupon] = useState('');
	const { cartItemsList, amount: totalProductsInCarts } = useSelector(
		(state) => state.cart
	);
	const storePreferences = useSelector(selectUserPreferences);
	const coursesInCart = useCoursesInCart();

	const isShippingApplicable = useMemo(() => {
		// If user have selfPickup preffered method
		if (storePreferences?.shippingAddress?.selfPickup) {
			return false;
		}
		// if have products, or one of the course have tools to dispatch: Return true
		if (cartItemsList.length > 0) {
			return true;
		}
		return coursesInCart.some((courseItem) => courseItem.kitIncluded);
	}, [cartItemsList, coursesInCart, storePreferences]);

	useEffect(() => {
		dispatch(setQuickCheckoutCourse());
	}, []);

	if (totalProductsInCarts < 1 && coursesInCart.length < 1) {
		return (
			<div className='cart-empty'>
				<h2>Coșul tău</h2>
				<h4>momentan este gol</h4>
			</div>
		);
	}

	const onRemoveFromCart = (courseId, classTime) => {
		dispatch(
			toggleCourseCart({ courseId, classTime, operationType: 'remove' })
		);
	};

	const emptyCart = () => {
		dispatch(clearCartAsync());
		dispatch(resetCoursesCart());
	};
	return (
		<div className='section'>
			<header>
				<h2>Coșul tău</h2>
			</header>
			<div className='bg-white shadow p-3'>
				<p className='lead fw-bold border-bottom'>Produse</p>
				{cartItemsList &&
					cartItemsList.map((item) => <CartItem key={item.sku} {...item} />)}
				{cartItemsList?.length === 0 && (
					<p className='lead text-center my-3'>
						Momentan nu aveți nici un produs în coș
					</p>
				)}
				<p className='lead fw-bold border-bottom mt-4'>Cursuri</p>
				{coursesInCart.map((course) => (
					<CartCourseItem
						key={course.id}
						{...course}
						onRemoveFromCart={onRemoveFromCart}
					/>
				))}

				{coursesInCart.length === 0 && (
					<p className='lead text-center my-3'>
						Momentan nu aveți nici un curs în coș.
					</p>
				)}
			</div>
			<div className='summary shadow'>
				<div className='cart-total'>
					<h3>Aplică cupon</h3>
					<input
						type='text'
						className='form-input mt-1'
						placeholder='Cupon'
						value={coupon}
						onChange={(e) => setCoupon(e.target.value)}
					/>
				</div>
				<div className='vertical'></div>
				<div className='clear-cart'>
					<Button
						className='btn-lg text-nowrap'
						variant='secondary'
						onClick={emptyCart}
					>
						Golește coșul
					</Button>
				</div>
			</div>
			<CartSummary shippingApplicable={isShippingApplicable} />
		</div>
	);
}
