import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from './components/Fallback';
import { store } from './Store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { functions } from './Firebase';
import App from './App';

const errorHandler = (error, errorInfo) => {
	console.log('Logging', error, errorInfo);
};

(async () => {
	const paymentConfig = httpsCallable(functions, 'paymentConfig');
	const publishableKey = await paymentConfig()
		.then((result) => {
			const data = result.data;
			return data.publishableKey;
		})
		.catch((e) => console.log(e));
	const stripePromise = loadStripe(publishableKey);

	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(
		<ErrorBoundary FallbackComponent={Fallback} onError={errorHandler}>
			<BrowserRouter>
				<Provider store={store}>
					<Elements stripe={stripePromise}>
						<App />
					</Elements>
				</Provider>
			</BrowserRouter>
		</ErrorBoundary>
	);
})();
