import * as Yup from 'yup';

export const billingAddressValidationSchema = Yup.object({
	isBussiness: Yup.boolean(),
	name: Yup.string().trim().required('Name can not be empty'),
	phone: Yup.string()
		.transform((value) => {
			return value.replace(/ /g, '');
		})
		.matches(/^\+40\d{9}$/, {
			message: 'Please provide a valid phone number',
		})
		.required('Please provide phone number.'),
	address: Yup.string().trim().required('Please provide a valid address.'),
	vat: Yup.string()
		.matches(/^\d{10}$/, 'Please provide a valid VAT number')
		.when('isBussiness', {
			is: true,
			then: (schema) => schema.required('Please provide VAT.'),
			otherwise: (schema) => schema.notRequired(),
		}),
	city: Yup.string().trim().required('Please provide a valid city.'),
	zipCode: Yup.string()
		.matches(/^\d{6}$/, 'Please provide the postal code.')
		.required('Please provide the postal code.'),
	country: Yup.string(),
});

export const shippingAddressValidationSchema = Yup.object().shape({
	name: Yup.string().trim().required('Name can not be empty'),
	phone: Yup.string()
		.transform((value) => {
			return value.replace(/ /g, '');
		})
		.matches(/^\+40\d{9}$/, {
			message: 'Please provide a valid phone number',
		})
		.required('Please provide phone number.'),
	address: Yup.string().trim().required('Please provide a valid address.'),
	city: Yup.string().trim().required('Please provide a valid city.'),
	zipCode: Yup.string()
		.matches(/^\d{6}$/, 'Please provide valid postal code.')
		.required('Please provide the postal code.'),
	country: Yup.string(),
	selfPickup: Yup.boolean(),
});

export const validationSchema = Yup.object().shape({
	billingAddress: billingAddressValidationSchema.required(),
	shippingAddress: Yup.lazy((values) => {
		const { selfPickup } = values;
		if (selfPickup) {
			return Yup.mixed().notRequired();
		} else {
			return shippingAddressValidationSchema;
		}
	}),
	saveBillingPreferences: Yup.boolean(),
	saveShippingPreferences: Yup.boolean(),
	isPrepaid: Yup.string(),
	termsAccept: Yup.boolean().isTrue('Please accept the terms and conditions.'),
});

export const nonShippingValidationSchema = Yup.object().shape({
	billingAddress: billingAddressValidationSchema.required(),
	saveBillingPreferences: Yup.boolean(),
	isPrepaid: Yup.string(),
	termsAccept: Yup.boolean().isTrue('Please accept the terms and conditions.'),
});
