import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './CourseConsole.module.scss';

const LessonPlayer = ({
	videoUrl,
	videoPlayPoint,
	onVideoEnd,
	saveVideoProgress,
}) => {
	const playerRef = useRef();
	const [videoPlaying, setVideoPlaying] = useState(false);

	const onProgress = ({ playedSeconds }) => {
		saveVideoProgress(Math.floor(playedSeconds));
	};

	useEffect(() => {
		let intervalRef;
		if (playerRef.current) {
			intervalRef = setInterval(() => {
				if (playerRef.current.getDuration() >= videoPlayPoint) {
					playerRef.current.seekTo(videoPlayPoint || 0);
					setVideoPlaying(true);
					clearInterval(intervalRef);
					intervalRef = null;
				}
			}, 500);
		}

		return () => {
			clearInterval(intervalRef);
			intervalRef = null;
		};
	}, []);

	return (
		<div className={styles.playerContainer}>
			<ReactPlayer
				ref={playerRef}
				className={styles.player}
				url={videoUrl}
				controls={true}
				playing={videoPlaying}
				progressInterval={10000}
				onProgress={onProgress}
				onEnded={onVideoEnd}
				width='100%'
				height='100%'
				config={{
					file: {
						attributes: {
							disablePictureInPicture: true,
							controlsList: 'nodownload',
						},
					},
				}}
			/>
		</div>
	);
};

export default LessonPlayer;
