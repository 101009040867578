import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FavoriteCourseItem from '../../components/FavoriteCourseItem';
import FavoritesItem from '../../components/FavoritesItem';
import {
	selectFavouritesCoursesList,
	toggleCourseItemFavorite,
} from '../../features/Favorites';
import { selectAllCoursesObjectified } from '../../Store/courses';
import { toggleCourseCart } from '../../Store/coursesCart';
import { setQuickCheckoutCourse } from '../../Store/checkout';

import './styles.scss';

export default function Favorites() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { favItemsList, favAmount } = useSelector((state) => state.favorites);
	const favouritesCoursesIds = useSelector(selectFavouritesCoursesList);
	const allCourses = useSelector(selectAllCoursesObjectified);

	const getCourseMap = useCallback(
		(courseID) => {
			let course = { ...allCourses[courseID] };
			if (!course.isOnline) {
				const classes = Object.keys(course.classes)
					.map((key) => ({ ...course.classes[key], class: key }))
					.sort((a, b) => a.startTime - b.startTime);
				course.classes = classes;
			}

			return {
				course,
			};
		},
		[allCourses]
	);

	const onPayOutStandingAmout = async (courseId, classTime) => {
		await dispatch(
			setQuickCheckoutCourse({
				isQuickCheckout: true,
				quickOrderType: 'outstanding',
				course: allCourses[courseId],
				selectedClassTime: classTime,
			})
		);
		navigate('/checkout-details');
	};

	const onRemoveFromFavorite = (courseId) => {
		dispatch(
			toggleCourseItemFavorite({
				itemId: courseId,
				operationType: 'remove',
			})
		);
	};

	const onAddToCart = (courseId, classTime) => {
		dispatch(toggleCourseCart({ courseId, classTime, operationType: 'add' }));
		onRemoveFromFavorite(courseId);
	};

	return (
		<div className='page-container'>
			<div className='container'>
				<div className=''>
					<h2>Favorite</h2>
					<div className='mt-2'>
						<p className='lead fw-bold border-bottom'>
							{favAmount} Produs{favAmount !== 1 && 'e'}
						</p>
					</div>
					{!!favItemsList &&
						favItemsList?.map((item) => (
							<FavoritesItem key={item.productId} {...item} />
						))}
				</div>

				<div className='mt-4'>
					<p className='lead fw-bold border-bottom'>
						Cursuri
						{favouritesCoursesIds.length > 0 && (
							<span className='ms-1'>({favouritesCoursesIds.length})</span>
						)}
					</p>
					{favouritesCoursesIds.length > 0 &&
						favouritesCoursesIds.map((courseId) => {
							const { course } = getCourseMap(courseId);
							return (
								<FavoriteCourseItem
									key={courseId}
									{...course}
									onAddToCart={onAddToCart}
									onRemoveFromFavorite={onRemoveFromFavorite}
									onPayOutStandingAmout={onPayOutStandingAmout}
								/>
							);
						})}

					{favouritesCoursesIds.length === 0 && (
						<p className='lead text-center my-3'>
							No course in favourites list yet.
						</p>
					)}
				</div>
			</div>
		</div>
	);
}
