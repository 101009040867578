import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { Currency } from '../Currency';
import { useCoursesInCartTotalAmount } from '../../hooks/courses';

import './styles.scss';

export default function CartSummary({ shippingApplicable }) {
	const navigate = useNavigate();
	const { userInfo } = useSelector((state) => state.user);
	const { total, shipping } = useSelector((state) => state.cart);
	const coursesPayableAmount = useCoursesInCartTotalAmount();

	const { totalPayable, shippingCharges } = useMemo(() => {
		const orderTotal = total + coursesPayableAmount;
		const shippingCharges =
			orderTotal >= 299 || !shippingApplicable ? 0 : shipping;
		return {
			totalPayable: orderTotal + shippingCharges,
			shippingCharges: shippingCharges,
		};
	}, [total, shipping, shippingApplicable, coursesPayableAmount]);

	const toCheckout = (e) => {
		e.preventDefault();
		if (!userInfo) {
			navigate('/login');
		} else {
			navigate('/checkout-details');
		}
	};

	return (
		<div className='summary shadow'>
			<div className='cart-total'>
				<h3>Sumar comandă </h3>
				<div className='text-price'>
					<h4 className='font-style'>Cost produse:</h4>
					<h4 className='price font-style'>
						<Currency price={total} />
					</h4>
				</div>
				<div className='text-price'>
					<h4 className='font-style'>Cost cursuri:</h4>
					<h4 className='price font-style'>
						<Currency price={coursesPayableAmount} />
					</h4>
				</div>
				{shippingApplicable && (
					<div className='text-price'>
						<h4 className='font-style'>Cost livrare:</h4>
						<h4 className='price font-style'>
							<Currency price={shippingCharges} />
						</h4>
					</div>
				)}
			</div>
			<div className='vertical'></div>
			<div className='total-price-col'>
				<h3 className='total-price'>Total:</h3>
				<h3 className='total-price'>
					<Currency price={totalPayable} />
				</h3>
				<Button
					className='text-nowrap mt-3 text-uppercase text-white fw-bold py-2 lh-lg'
					variant='primary'
					onClick={toCheckout}
				>
					Continuă
				</Button>
			</div>
		</div>
	);
}
