import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../Firebase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import styles from './ResetPassword.module.scss';
import clsx from 'clsx';

export default function ResetPassword() {
	const [email, setEmail] = useState('');
	const navigate = useNavigate();

	const sendEmailWithLink = (e) => {
		e.preventDefault();
		sendPasswordResetEmail(auth, email)
			.then(() => {
				toast.success('Email-ul a fost trimis.');
				navigate(-1, { replace: true });
			})
			.catch((error) => {
				console.log(error.message);
				toast.error(error.message);
			});
	};
	return (
		<div className={styles['Auth-form-container']}>
			<Form className={styles['Auth-form']}>
				<div className={styles['Auth-form-content']}>
					<h3 className={styles['Auth-form-title']}>Resetează-ți parola</h3>
					<p className={clsx('text-center mt-5', styles['style-text'])}>
						Ți-ai reamintit parola? <a href='/login'>Intră în cont</a>
					</p>
					<div className={clsx('form-group mt-5', styles['form-group'])}>
						<label>Adresa de email</label>
						<input
							type='email'
							className={clsx('form-control mt-1', styles['form-control'])}
							placeholder='Email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>

					<div className='d-grid gap-2 mt-5'>
						<button
							type='submit'
							className={clsx(
								'btn btn-primary text-white rounded-pill',
								styles['submit']
							)}
							onClick={sendEmailWithLink}
						>
							Trimite
						</button>
					</div>
					<p className={clsx('text-center mt-5', styles['style-text'])}>
						Vei primi un email care contine link-ul de resetare al parolei.
					</p>
				</div>
			</Form>
		</div>
	);
}
