import { BsPatchPlus, BsPatchMinus, BsTrash } from 'react-icons/bs';
import {
	removeCartItemAsync,
	decreaseQtyAsync,
	increaseQtyAsync,
} from '../../features/Cart';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getProductItem } from '../../features/Products';
import { SelectedProdVariaties } from '../SelectedProdVariaties';
import { Currency } from '../Currency';
import { useSelectProductVarientStockBySku } from '../../hooks/productsStock';

import './styles.scss';

export default function CartItem({
	productId,
	cover,
	title,
	price,
	amount,
	stock,
	maxLimitOrder,
	variations,
	sku,
}) {
	const dispatch = useDispatch();
	const { increaseQty } = useSelector((state) => state.cart);
	const selectedVariantStock = useSelectProductVarientStockBySku(sku);

	const productStock = selectedVariantStock
		? selectedVariantStock.available
		: stock;

	let badgeText = '';
	if (productStock === 0) {
		badgeText = 'Stoc epuizat';
	} else if (productStock === 1) {
		badgeText = 'Ultimul produs';
	} else if (productStock < 5) {
		badgeText = `Ultimele ${productStock} produse`;
	}

	const increase = () => {
		const productMaxLimit = selectedVariantStock
			? selectedVariantStock.max
			: maxLimitOrder;

		if (amount < productMaxLimit) {
			dispatch(getProductItem(productId)).then((result) => {
				console.log(result);
				if (amount < result.payload.stock && amount < productStock) {
					dispatch(increaseQtyAsync(sku));
				} else {
					toast.warning('Stoc limitat');
				}
			});
		} else
			toast.warning(
				`Limita maximă este de ${productMaxLimit} produse pe comandă.`
			);
	};
	return (
		<article className='cart-item border-bottom'>
			<img src={cover} height='90px' alt={title} />
			<div className='cart-item-title'>
				<h4>{title}</h4>
				{variations && (
					<SelectedProdVariaties
						curvature={variations.curvature}
						thickness={variations.thickness}
						length={variations.length}
					/>
				)}
			</div>
			<div>
				<div className='amount-div'>
					<div
						className='toggle'
						onClick={() => {
							amount === 1
								? dispatch(removeCartItemAsync(sku))
								: dispatch(decreaseQtyAsync(sku));
						}}
					>
						<BsPatchMinus />
					</div>
					<p className='amount'>{amount}</p>
					<div
						className='toggle'
						disabled={increaseQty.isLoading}
						onClick={increase}
					>
						<BsPatchPlus />
					</div>
				</div>
			</div>
			<div className='price-col'>
				<h6 className={productStock === 0 ? 'item-badge active' : 'item-badge'}>
					{badgeText}
				</h6>
				<h4 className='item-price'>
					<Currency price={price} />
				</h4>
				<span
					className='remove-item'
					onClick={() => {
						dispatch(removeCartItemAsync(sku));
					}}
				>
					<BsTrash />
					Șterge
				</span>
			</div>
		</article>
	);
}
