import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Accordion from 'react-bootstrap/Accordion';
import {
	BsPlayCircle,
	BsFileEarmarkText,
	BsImage,
	BsCheckCircleFill,
} from 'react-icons/bs';

import styles from './CourseConsole.module.scss';

const CourseContent = ({
	contentSection,
	activeLessonId,
	completedLessons,
	onChangeLesson,
}) => {
	const [activeKeys, setActiveKeys] = useState([]);

	const handleSelect = (eventKey) => setActiveKeys(eventKey);
	const handleActionForAll = () => {
		if (activeKeys.length !== contentSection.length) {
			const allKeys = contentSection.map((item) => item.contentGroupId);
			setActiveKeys(allKeys);
		} else {
			setActiveKeys([]);
		}
	};

	const actionAllBtnText = useMemo(() => {
		return activeKeys.length !== contentSection.length
			? 'Expand All'
			: 'Collapse All';
	}, [activeKeys.length, contentSection.length]);

	useEffect(() => {
		if (!activeLessonId) {
			return;
		}
		const [groupId] = activeLessonId.split('_');
		const allActiveKeys = [...activeKeys];
		if (!allActiveKeys.some((activeId) => activeId === groupId)) {
			allActiveKeys.push(groupId);
			setActiveKeys(allActiveKeys);
		}
	}, [activeLessonId]);

	return (
		<div className=''>
			<div className='text-end'>
				<button
					className='btn btn-link text-primary fw-bold mb-0 px-0'
					onClick={handleActionForAll}
				>
					{actionAllBtnText}
				</button>
			</div>
			<Accordion
				className={styles.courseContentAccordian}
				alwaysOpen={true}
				activeKey={activeKeys}
				onSelect={handleSelect}
			>
				{contentSection.map((courseGroup, index) => (
					<Accordion.Item
						key={courseGroup.contentGroupId}
						eventKey={courseGroup.contentGroupId}
					>
						<Accordion.Header>
							<div className='d-flex justify-content-between align-items-center w-100 ps-4 flex-wrap'>
								<span className='fw-bold text-uppercase text-muted'>
									{courseGroup.groupTitle.substring(0, 40)}
									{courseGroup.groupTitle.length > 40 && '...'}
								</span>
								<div className={clsx(styles.contentSummary, 'text-dark')}>
									<span>{courseGroup.totalLectures} Lecții</span>
									<span>{courseGroup.duration}</span>
								</div>
							</div>
						</Accordion.Header>
						<Accordion.Body>
							<ul className='mb-0 ps-0'>
								{courseGroup.lectures.map((lecture, index) => {
									const lessonKey = `${courseGroup.contentGroupId}_${index}`;
									const isLessonCompleted = completedLessons.some(
										(item) => item === lessonKey
									);
									return (
										<li
											key={lessonKey}
											className='d-flex justify-content-between align-items-center py-2'
										>
											<div className='d-flex align-items-center'>
												{isLessonCompleted ? (
													<BsCheckCircleFill
														className='default flex-shrink-0 text-primary'
														size={15}
													/>
												) : lecture.lectureType === 'video' ? (
													<BsPlayCircle
														className='default flex-shrink-0'
														size={15}
													/>
												) : lecture.lectureType === 'text' ? (
													<BsFileEarmarkText
														className='default flex-shrink-0'
														size={15}
													/>
												) : (
													<BsImage
														className='default flex-shrink-0'
														size={15}
													/>
												)}
												<span
													className={clsx({
														[styles.lessonTitle]: true,
														[styles.active]: lessonKey === activeLessonId,
													})}
													onClick={() => onChangeLesson(lessonKey)}
												>
													{lecture.lectureTitle.substring(0, 70)}
													{lecture.lectureTitle.length > 70 && '...'}
												</span>
											</div>
											<span className='flex-shrink-0'>{lecture.duration}</span>
										</li>
									);
								})}
							</ul>
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
		</div>
	);
};

export default CourseContent;
