import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';
import { checkoutLabels } from '../../StaticData';

export const Breadcrumb = () => {
	return (
		<div className={clsx(styles.breadcrumb)}>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<h3 className={styles['breadcrumb-header']}>{checkoutLabels.orderDetails}</h3>
						<ul className={styles['breadcrumbTree']}>
							<li>
								<Link to={'/'}>{checkoutLabels.home}</Link>
							</li>
							<li className='active'>{checkoutLabels.orderDetails}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
