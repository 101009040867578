import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

// Reducers
import appConfigsReducer from './appConfigs';

import cartReducer from '../features/Cart';
import coursesCartReducer from './coursesCart';
import favoritesReducer from '../features/Favorites';
import productsReducer from '../features/Products';
import userReducer from '../features/User';

// Products Reducers
import productsCategoriesReducer from './productsCategories';
import productsListReducer from './productsList';
import productsVariationsStockReducer from './productsVariationsStock';
import productDetailReducer from './productDetail';

import coursesReducer from './courses';
import courseConsoleReducer from './courseConsole';

import ordersHistoryReducer from './ordersHistory';
import checkoutReducer from './checkout';

const rootReducer = combineReducers({
	appConfigs: appConfigsReducer,
	cart: cartReducer,
	coursesCart: coursesCartReducer,
	favorites: favoritesReducer,
	products: productsReducer,
	user: userReducer,
	productsCategories: productsCategoriesReducer,
	productsList: productsListReducer,
	productsVariationsStock: productsVariationsStockReducer,
	productDetail: productDetailReducer,
	courses: coursesReducer,
	courseConsole: courseConsoleReducer,
	ordersHistory: ordersHistoryReducer,
	checkout: checkoutReducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk],
});
