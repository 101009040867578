import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

const SERVICE_REGION = 'europe-west3';
const configProd = {
	apiKey: "AIzaSyAUHDxz9JLPWHiw_5itsKLwv7Q-tdIF08I",
	authDomain: "sancos-academy-20ec1.firebaseapp.com",
	projectId: "sancos-academy-20ec1",
	storageBucket: "sancos-academy-20ec1.appspot.com",
	messagingSenderId: "516744099731",
	appId: "1:516744099731:web:1a35d6301a610106211a66",
	measurementId: "G-C1481FTYXM"
};

export const app = initializeApp(configProd);
export const auth = getAuth(app);
auth.useDeviceLanguage();
export const fireDB = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, SERVICE_REGION);

export default app;
