import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
	showEmailVerificationAlert: false,
};

const slice = createSlice({
	name: 'appConfigs',
	initialState,
	reducers: {
		setShowEmailVerificationAlert: (state, { payload }) => {
			state.showEmailVerificationAlert = payload;
		},
	},
});

// ACTIONS
export const { setShowEmailVerificationAlert } = slice.actions;

// SELECTORS
const selectAppConfigsData = (state) => {
	return state.appConfigs;
};

export const selectIfShowEmailVerificationAlert = createSelector(
	selectAppConfigsData,
	(appConfigs) => appConfigs.showEmailVerificationAlert
);

export default slice.reducer;
