import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Currency } from '../../components/Currency';
import {
	selectOrderSuccessData,
	setOrderSuccessData,
} from '../../Store/checkout';

const OrderSuccess = () => {
	const dispatch = useDispatch();
	const orderData = useSelector(selectOrderSuccessData);

	useEffect(() => {
		return () => {
			dispatch(setOrderSuccessData(null));
		};
	}, []);

	return (
		<div className='row my-5 mx-auto' style={{ maxWidth: 600 }}>
			{!orderData ? (
				<div className='text-center my-5'>
					<p className='h5 fw-normal text-muted'>No recent order histroy.</p>
					<Link className='btn btn-outline-primary ms-2' to={'/'}>
						Browse Products
					</Link>
				</div>
			) : (
				<div className='col-12'>
					<div className='card non-hoverable border-0'>
						<div className='card-body text-center'>
							<BsCheckCircleFill className='text-success default' size={40} />
							<p className='h3 my-3 fw-normal'>Mulțumim pentru comandă.</p>
							<p className='h5 fw-normal lh-base'>
								Am primit comanda ta in valoare de{' '}
								<span className='fw-bold'>
									<Currency price={orderData.total} />
								</span>
								.
							</p>
							<div className='mt-4'>
								<Link
									className='btn btn-primary btn-md text-white'
									to={'/orders-history'}
								>
									View In Order History
								</Link>
								<Link className='btn btn-outline-primary ms-2' to={'/'}>
									Home
								</Link>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OrderSuccess;
