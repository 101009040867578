import { BsPencilSquare } from 'react-icons/bs';
const AddressDisplay = ({
	addressData,
	addressTitle,
	addressType,
	onUpdateAddress,
}) => {
	return (
		<div className=''>
			<div className='d-flex align-items-center border-bottom mb-2'>
				<div className=''>
					<p className='fw-bold small text-muted mb-0'>
						{addressTitle}
						{addressData.isBussiness && addressType === 'billing' && (
							<span className='ms-2'>&mdash; Persoană Juridică</span>
						)}
						{addressData.selfPickup && addressType === 'shipping' && (
							<span className='ms-2'>&mdash; Ridicare personală</span>
						)}
					</p>
				</div>
				<div className=''>
					<button className='btn btn-sm btn-link' onClick={onUpdateAddress}>
						<BsPencilSquare className='default' size={14} />
					</button>
				</div>
			</div>
			<div className=''>
				{addressData.name && (
					<p className='mb-1'>
						{addressData.name}
						<span className='ms-2'>{addressData.phone}</span>
					</p>
				)}
				{addressData.street && <p className='mb-1'>{addressData.street}</p>}
				{addressData.city && (
					<p className='mb-1'>
						{addressData.city}
						{', '}
						{addressData.zipCode}
					</p>
				)}
				<p className='mb-1'>{addressData.country}</p>
			</div>
		</div>
	);
};

export default AddressDisplay;
