import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import SharedLayout from './pages/SharedLyout';
import Products from './pages/Products';
import SingleProduct from './pages/SingleProduct';
import Courses from './pages/Courses';
import CourseDetail from './pages/CourseDetail';
import Cosmetics from './pages/Cosmetics';
import Login from './pages/Login';
import Cart from './pages/Cart';
import Favorites from './pages/Favorites';
import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './pages/ProtectedRoute';
import CheckoutDetails from './pages/CheckoutDetails';
import CourseConsole from './pages/CourseConsole';
import OrderPayment from './pages/OrderPayment';
import Error from './pages/Error';
import OrdersHistory from './pages/OrdersHistory';
import OrderSuccess from './pages/OrderSuccess';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import MyAccount from './pages/MyAccount';
import MyCourses from './pages/MyCourses';
import Help from './pages/Help';
import { AppErrorBoundary } from './components/ErrorBoundries';
import { auth, fireDB } from './Firebase';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import {
	getCartItemsAsync,
	updateCart,
	generateCartListAsync,
	postCartItemsAsync,
} from './features/Cart';
import {
	getFavoriteItemsAsync,
	updateFavorites,
	generateFavListAsync,
	postFavItemsAsync,
} from './features/Favorites';
import { getProductItems } from './features/Products';
import { loadAllCourses } from './Store/courses';
import { loadProductsVariationsStock } from './Store/productsVariationsStock';
import {
	sendEmailForVerification,
	setUser,
	setUserPreferences,
} from './features/User';
import {
	resetActiveCourses,
	setActiveCourses,
	setActiveCoursesError,
	resetPastCourses,
	setPastCourses,
	setPastCoursesError,
} from './Store/ordersHistory';
import { resetCoursesCart, syncCoursesCart } from './Store/coursesCart';
// import { VariationsStockSeeds } from './pages/VariationsStockSeeds';

import './default.scss';

function App() {
	const { cartItems } = useSelector((state) => state.cart);
	const { favItems, favCoursesList } = useSelector((state) => state.favorites);
	const { productItemsObj } = useSelector((state) => state.products);
	const { userInfo } = useSelector((state) => state.user);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getProductItems());
		dispatch(loadAllCourses());
		dispatch(loadProductsVariationsStock());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (Object.keys(productItemsObj).length > 0) {
			if (userInfo) {
				dispatch(getCartItemsAsync());
				dispatch(getFavoriteItemsAsync());
			} else {
				cartItems.length > 0 &&
					dispatch(updateCart(JSON.stringify(productItemsObj)));
				favItems.length > 0 &&
					dispatch(updateFavorites(JSON.stringify(productItemsObj)));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productItemsObj]);

	useEffect(() => {
		if (Object.keys(productItemsObj).length > 0) {
			cartItems && dispatch(generateCartListAsync());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartItems]);

	useEffect(() => {
		if (Object.keys(productItemsObj).length > 0) {
			favItems && dispatch(generateFavListAsync());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favItems]);

	useEffect(() => {
		let userDocUnsub;
		if (auth.currentUser) {
			dispatch(setUser(JSON.stringify({ user: auth.currentUser })));
			// TODO: Remove the dependancy on productItemsObj
			// Sync Product Cart and Favorites lists
			if (Object.keys(productItemsObj).length > 0) {
				if (cartItems.length > 0) {
					dispatch(postCartItemsAsync());
				} else {
					dispatch(getCartItemsAsync());
				}
				if (favItems.length > 0 || favCoursesList.length > 0) {
					dispatch(postFavItemsAsync());
				} else {
					dispatch(getFavoriteItemsAsync());
				}
			}
			userDocUnsub = onSnapshot(
				doc(fireDB, 'users', auth.currentUser.uid),
				(doc) => {
					const userData = doc.data();

					if (
						userData.shouldSendVerficationEmail &&
						!auth.currentUser.emailVerified
					) {
						dispatch(sendEmailForVerification(auth.currentUser));
					}
					dispatch(setUserPreferences(userData.preferences));
				}
			);
		} else {
			userDocUnsub && userDocUnsub();
			dispatch(setUserPreferences(null));
		}
		return () => {
			// unsubscribe
			userDocUnsub && userDocUnsub();
		};
	}, [auth.currentUser]);

	useEffect(() => {
		// TODO: Research about possible batching approach
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			let activeCoursesSub;
			let pastCoursesSub;
			if (user) {
				dispatch(syncCoursesCart());
				const docQuery = query(
					collection(fireDB, 'activeCourses'),
					where('userId', '==', user.uid)
				);
				activeCoursesSub = onSnapshot(docQuery, (querySnapshot) => {
					if (!querySnapshot.docs.length) {
						dispatch(
							setActiveCoursesError('No active course exists currently.')
						);
					}
					const activeCourses = querySnapshot.docs.map((docItem) => ({
						...docItem.data(),
						purchaseId: docItem.id,
					}));
					dispatch(setActiveCourses(activeCourses));
				});

				const pastCoursesQuery = query(
					collection(fireDB, 'pastCoursesSubscription'),
					where('userId', '==', user.uid)
				);
				pastCoursesSub = onSnapshot(pastCoursesQuery, (querySnapshot) => {
					if (!querySnapshot.docs.length) {
						dispatch(setPastCoursesError('No Past course exists currently.'));
					}

					const pastCourses = querySnapshot.docs.map((docItem) => ({
						...docItem.data(),
						purchaseId: docItem.id,
					}));
					dispatch(setPastCourses(pastCourses));
				});
			} else {
				dispatch(resetActiveCourses());
				dispatch(resetPastCourses());
				dispatch(resetCoursesCart());

				navigate('/');

				activeCoursesSub && activeCoursesSub();
				activeCoursesSub = undefined;

				pastCoursesSub && pastCoursesSub();
				pastCoursesSub = undefined;
			}
		});
		return () => unsubscribe();
	}, []);

	return (
		<AppErrorBoundary>
			<Routes>
				<Route path='/' element={<SharedLayout />}>
					<Route index element={<Products />} />
					<Route path=':productId' element={<SingleProduct />} />
					<Route path='courses' element={<Courses />} />
					<Route path='courses/:courseId' element={<CourseDetail />} />
					<Route path='courses/console/:courseId' element={<CourseConsole />} />
					<Route path='cosmetics' element={<Cosmetics />} />
					<Route path='login' element={<Login />} />
					<Route path='reset-password' element={<ResetPassword />} />
					<Route path='cart' element={<Cart />} />
					<Route
						path='checkout-details'
						element={
							<ProtectedRoute>
								<CheckoutDetails />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path='checkout-details/card'
						element={
							<ProtectedRoute>
								<OrderPayment />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path='order-success'
						element={
							<ProtectedRoute>
								<OrderSuccess />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path='orders-history'
						element={
							<ProtectedRoute>
								<OrdersHistory />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path='my-account'
						element={
							<ProtectedRoute>
								<MyAccount />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path='my-courses'
						element={
							<ProtectedRoute>
								<MyCourses />
							</ProtectedRoute>
						}
					></Route>
					<Route path='favorites' element={<Favorites />} />
					<Route path='about-us' element={<AboutUs />} />
					<Route path='privacy-policy' element={<PrivacyPolicy />} />
					<Route path='terms-and-conditions' element={<TermsConditions />} />
					<Route path='help' element={<Help />} />
					{/* <Route path='stock-seeds' element={<VariationsStockSeeds />} /> */}
					<Route path='*' element={<Error />} />
				</Route>
			</Routes>
		</AppErrorBoundary>
	);
}

export default App;
