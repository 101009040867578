import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { BsHeart, BsHeartFill, BsSliders } from 'react-icons/bs';
import { VariationsSelector } from './VariationsSelector';
import { SelectedProdVariaties } from '../SelectedProdVariaties';
import { Currency } from '../../components/Currency';
import { addCartItemAsync } from '../../features/Cart';
import { getProductItem } from '../../features/Products';
import {
	addFavoriteItemAsync,
	removeFavoritesItemAsync,
} from '../../features/Favorites';
import { updateProductVariantPreference } from '../../features/User';
import {
	useSelectProductVarientStockByOptions,
	useSelectFirstAvailableVarient,
} from '../../hooks/productsStock';
import { calculateProductSku } from '../../utils/products.utils';
import Star from './../../assets/star.png';

import './styles.scss';

export default function Product({
	id,
	cover,
	title,
	rating,
	reviewCount,
	price,
	stock,
	variations,
	oldPrice,
}) {
	const { favItems } = useSelector((state) => state.favorites);
	const { productItemsObj } = useSelector((state) => state.products);
	const { cartItems, addToCart } = useSelector((state) => state.cart);
	const [isFavorite, setIsFavorite] = useState(
		favItems.length > 0 && favItems.some((item) => item.productId === id)
	);
	const [availableCurvature, availableThickness, availableLength] =
		useSelectFirstAvailableVarient(id);

	const [curvature, setCurvature] = useState('');
	const [thickness, setThickness] = useState('');
	const [length, setLength] = useState('');

	const [showModal, setShowModal] = useState(false);

	const dispatch = useDispatch();
	const selectedVariantStock = useSelectProductVarientStockByOptions(
		id,
		curvature,
		thickness,
		length
	);

	useEffect(() => {
		favItems.length > 0 && favItems.some((item) => item.productId === id)
			? setIsFavorite(true)
			: setIsFavorite(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favItems]);

	const addItem = async (e) => {
		e.preventDefault();
		const productItem = productItemsObj[id];
		// Calculate SKU
		// Compare with the sku
		let sku = productItem.id;
		if (variations) {
			sku = calculateProductSku(sku, curvature, thickness, length);
		}

		const cartItem =
			cartItems.length > 0
				? cartItems.some((element) => element.sku === sku)
				: false;
		if (!cartItem) {
			dispatch(getProductItem(id)).then((result) => {
				if (result.payload.stock > 0) {
					dispatch(
						addCartItemAsync({
							id,
							sku,
							variations: variations
								? {
										curvature,
										thickness,
										length,
								  }
								: null,
						})
					);
				} else {
					toast.warning('Stoc epuizat');
				}
			});
		} else {
			toast.warning('Produsul se află deja in coșul tău.');
		}
	};

	const toggleFavorites = (e) => {
		e.preventDefault();
		if (!isFavorite) {
			dispatch(addFavoriteItemAsync(id));
		} else dispatch(removeFavoritesItemAsync(id));
	};

	const handleShowModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	const handleSave = ({ newCurvature, newThickness, newLength }) => {
		setCurvature(newCurvature);
		setThickness(newThickness);
		setLength(newLength);

		const variant = [newCurvature, newThickness, newLength]
			.join('_')
			.replace(/ /g, '');
		dispatch(
			updateProductVariantPreference({ productId: id, productVariant: variant })
		);

		handleCloseModal();
	};

	let badgeText;
	const productStock = selectedVariantStock
		? selectedVariantStock.available
		: stock;
	if (productStock === 0) {
		badgeText = 'Stoc epuizat';
	} else if (productStock === 1) {
		badgeText = 'Ultimul produs';
	} else if (productStock < 5) {
		badgeText = `Ultimele ${productStock} produse`;
	}

	useEffect(() => {
		if (variations) {
			availableCurvature && setCurvature(availableCurvature);
			availableThickness && setThickness(availableThickness);
			availableLength && setLength(availableLength);
		}
	}, [availableCurvature, availableThickness, availableLength]);

	return (
		<div
			className={`card position-relative h-100  d-flex justify-content-between  ${
				showModal && 'non-hoverable'
			}`}
		>
			<div>
				<LinkContainer to={`/${id}`}>
					<div className='img-wrapper'>
						{badgeText && (
							<div
								className={
									productStock === 0 ? 'card-badge active' : 'card-badge'
								}
							>
								{badgeText}
							</div>
						)}
						<img src={cover} className='card-image w-100' alt={title} />
						{oldPrice && (
							<div className='card-badge-price'>
								<Currency price={price - oldPrice} />
							</div>
						)}
					</div>
				</LinkContainer>
				<div className='card-details'>
					<Link className='card-title' to={`/${id}`}>
						<h5>{title}</h5>
					</Link>
					{variations && (
						<div className='d-flex align-items-center'>
							<SelectedProdVariaties
								curvature={curvature}
								thickness={thickness}
								length={length}
							/>
							<button className='btn btn-sm' onClick={handleShowModal}>
								<BsSliders className='default' size={16} />
							</button>
						</div>
					)}
					<div className='card-stats'>
						<img src={Star} className='card-star' alt='star' />
						<span> {rating} </span>
						<span className='gray'>({reviewCount})</span>
					</div>
				</div>
			</div>
			<div className=''>
				<div className='price-div'>
					{oldPrice && (
						<del className='product-old-price'>
							<Currency price={oldPrice} />
						</del>
					)}
					<h4 className='card-price'>
						<Currency price={price} />
					</h4>
				</div>
				<div className='buttons d-flex justify-content-between'>
					<div className='pe-2'>
						{isFavorite ? (
							<BsHeartFill className='heart active' onClick={toggleFavorites} />
						) : (
							<BsHeart onClick={toggleFavorites} />
						)}
					</div>
					<div>
						<Button
							className='btn-lg text-nowrap'
							variant='primary'
							disabled={
								addToCart.isLoading ||
								(selectedVariantStock
									? selectedVariantStock.available === 0
									: stock === 0)
							}
							onClick={addItem}
						>
							Adaugă în coș
						</Button>{' '}
					</div>
				</div>
			</div>
			{variations && (
				<VariationsSelector
					isVisible={showModal}
					variations={variations}
					selectedCurvature={curvature}
					slectedThickness={thickness}
					selectedLength={length}
					onCancel={handleCloseModal}
					onSave={handleSave}
				/>
			)}
		</div>
	);
}
