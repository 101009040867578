import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BsCheckCircleFill, BsExclamationTriangleFill } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Avatar from '../../components/Avatar';
import AcountDeletionActions from './AcountDeletionActions';
import AcountUpdateActions from './AcountUpdateActions';
import AddressDisplay from './AddressDisplay';
import AcountUpdateModal from './AcountUpdateModal';
import AcountConfirmationModal from './AcountConfirmationModal';
import { auth } from '../../Firebase';
import {
	sendEmailForVerification,
} from '../../features/User';

import {
	resetUserUpdateStatus,
	selectLoggedinUser,
	selectUserPreferences,
} from '../../features/User';

const MyAccount = () => {
	const dispatch = useDispatch();
	const userInfo = useSelector(selectLoggedinUser);
	const storePreferences = useSelector(selectUserPreferences);

	const [showFormModal, setShowFormModal] = useState(false);
	const [formType, setFormType] = useState(null);
	const [confirmationModal, setConfirmationModal] = useState('');

	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			{userInfo?.emailVerified ? 'Verified' : 'Please verify your email'}
		</Tooltip>
	);

	const handleShowFormModal = (type) => {
		setFormType(type);
		setShowFormModal(true);
	};

	const handleCloseFormModal = () => {
		setShowFormModal(false);
		setFormType(null);
	};

	const toggleConfirmationModal = (actionType) => {
		setConfirmationModal(actionType);
		dispatch(resetUserUpdateStatus());
	};

	return (
		<div className='card card-body non-hoverable border-0 my-5'>
			<div className='d-flex gap-3 flex-column flex-sm-row align-items-center align-items-sm-start'>
				<Avatar
					fullName={userInfo?.displayName}
					profilePhoto={userInfo?.providerData[0].photoURL}
				/>
				<div className='flex-grow-1'>
					<div className='d-flex justify-content-between'>
						<div className=''>
							<p className='h5 fw-normal'>{userInfo?.displayName}</p>
							<p className='mb-1'>
								<span className='me-1'>{userInfo?.email}</span>
								<OverlayTrigger placement='top' overlay={renderTooltip}>
									<span>
										{userInfo?.emailVerified ? (
											<BsCheckCircleFill
												className='default text-primary'
												size={12}
											/>
										) : (
											<BsExclamationTriangleFill
												className='default text-warning'
												size={12}
											/>
										)}
									</span>
								</OverlayTrigger>
							</p>
							<p className=''>{userInfo?.providerData[0].phoneNumber}</p>
						</div>
						<AcountUpdateActions
							onUpdateProfile={() => handleShowFormModal('profile')}
							onUpdatePassword={() => handleShowFormModal('password')}
							onVerificationEmail={() => dispatch(sendEmailForVerification(auth.currentUser))}
						/>
					</div>
					<div className='mt-2 d-flex gap-3 flex-wrap'>
						<AddressDisplay
							addressTitle='Adresa de facturare '
							addressData={storePreferences?.billingAddress || {}}
							addressType='billing'
							onUpdateAddress={() => handleShowFormModal('billingAddress')}
						/>
						<AddressDisplay
							addressTitle='Adresa de Livrare'
							addressData={storePreferences?.shippingAddress || {}}
							addressType='shipping'
							onUpdateAddress={() => handleShowFormModal('shippingAddress')}
						/>
					</div>

					<div className='my-4 border-bottom'>
						<Link className='btn btn-link' to='/my-courses'>
							Cursurile mele
						</Link>
						<Link className='btn btn-link' to='/orders-history'>
							Comenzile mele
						</Link>
					</div>

					<AcountDeletionActions onAction={toggleConfirmationModal} />
				</div>
			</div>
			<AcountUpdateModal
				showFormModal={showFormModal}
				handleCloseFormModal={handleCloseFormModal}
				formType={formType}
			/>
			<AcountConfirmationModal
				handleCloseFormModal={toggleConfirmationModal}
				acountActionType={confirmationModal}
			/>
		</div>
	);
};

export default MyAccount;
