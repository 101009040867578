import {
	createSlice,
	createAsyncThunk,
	createSelector,
	isAnyOf,
} from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { functions } from '../Firebase';
import { calculateCourseSku } from '../utils/courses.utils';

const initialState = {
	coursesInCart: [],
};

// sync carted courses
export const syncCoursesCart = createAsyncThunk(
	'coursesCart/syncCoursesCart',
	async (_, { getState, rejectWithValue }) => {
		const { coursesInCart } = getState().coursesCart;
		try {
			const syncCoursesCartFunc = httpsCallable(functions, 'syncCoursesCart');
			const result = await syncCoursesCartFunc(coursesInCart);
			return result.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const toggleCourseCart = createAsyncThunk(
	'coursesCart/toggleCourseCart',
	async (data, { getState, fulfillWithValue, rejectWithValue }) => {
		const { coursesInCart } = getState().coursesCart;
		const { userInfo } = getState().user;
		const { courseId, operationType, classTime } = data;
		let courseSku = calculateCourseSku(courseId, classTime);

		try {
			// Save entry to DB only if user is logged in
			if (userInfo) {
				const toggleCourseItemCart = httpsCallable(
					functions,
					'toggleCourseItemCartFunc'
				);
				await toggleCourseItemCart({ ...data, courseId: courseSku });
			}
			let coursesList = [...coursesInCart];
			if (operationType === 'add') {
				coursesList.push(courseSku);
				toast.success('Cursul este adăugat cu succes în coș.');
			} else {
				coursesList = coursesList.filter((sku) => sku !== courseSku);
			}
			return fulfillWithValue(coursesList);
		} catch (error) {
			toast.error('A apărut o eroare la acțiunea coșului dvs');
			return rejectWithValue(error);
		}
	}
);

const slice = createSlice({
	name: 'coursesCart',
	initialState,
	reducers: {
		resetCoursesCart: (state) => {
			state.coursesInCart = [];
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(toggleCourseCart.fulfilled, syncCoursesCart.fulfilled),
			(state, { payload }) => {
				state.coursesInCart = payload;
			}
		);
	},
});

export const { resetCoursesCart } = slice.actions;

// SELECTORS
const slectCoursesCartData = (state) => {
	return state.coursesCart;
};
export const selectCoursesInCart = createSelector(
	slectCoursesCartData,
	(coursesCartData) => coursesCartData.coursesInCart
);

export default slice.reducer;
