import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SidbarCategories from '../../components/SidebarCategories';
import ProductsList from '../../components/ProductsList';
import ProductBanner from '../../components/ProductBanner';
// import ShippingDelayBanner from '../../components/ShippingDelayBanner';
import ProductFiltersBar from '../../components/ProductFiltersBar';
import {
	loadProductsCategories,
	selectProdCatSelected,
	selectProdCatsError,
	selectProdCatsList,
	selectIsProdCatsLoading,
	selectProdCatSelectedTitle,
	setSelectedProdCat,
	setSelectedProdCatTitle,
} from '../../Store/productsCategories';
import {
	filterProducts,
	loadProductsList,
	selectIsProdListLoading,
	selectProdFilter,
	selectProdList,
	selectProdListError,
	setActiveFilterAndSort,
} from '../../Store/productsList';

import './styles.scss';

export default function Products() {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	// Categories Selector
	const categoriesLoading = useSelector(selectIsProdCatsLoading);
	const categoriesError = useSelector(selectProdCatsError);
	const selectedCategory = useSelector(selectProdCatSelected);
	const categoriesList = useSelector(selectProdCatsList);
	const selectedCatTitle = useSelector(selectProdCatSelectedTitle);

	// Filter Selectors
	const activeFilter = useSelector(selectProdFilter);

	// Products Listing Selector
	const isProductsLoading = useSelector(selectIsProdListLoading);
	const productsLoadError = useSelector(selectProdListError);
	const productsList = useSelector(selectProdList);

	const activeCategory = searchParams.get('category');

	const onCategorySelected = useCallback((category, title) => {
		setSearchParams({ category });
		dispatch(setSelectedProdCatTitle(title));
	}, []);

	const onFilterChange = useCallback(({ value }) => {
		dispatch(setActiveFilterAndSort(value));
		dispatch(filterProducts());
	}, []);

	useEffect(() => {
		dispatch(loadProductsCategories());
	}, []);

	useEffect(() => {
		if (activeCategory) {
			dispatch(setSelectedProdCat(activeCategory));
		}
		dispatch(loadProductsList());
	}, [activeCategory]);

	return (
		<div>
			{/* <div className='row'>
				<ShippingDelayBanner />
			</div> */}
			<div className='row'>
				<ProductBanner />
			</div>
			<div className='row'>
				<div className='col-md-3'>
					<SidbarCategories
						isLoading={categoriesLoading}
						error={categoriesError}
						categoriesList={categoriesList}
						selected={selectedCategory}
						onCategorySelected={onCategorySelected}
					/>
				</div>
				<div className='col-md-9 my-md-4'>
					<ProductFiltersBar
						selectedCatTitle={selectedCatTitle}
						productsCount={productsList.length}
						selectedFilter={activeFilter}
						onChangeFilter={onFilterChange}
					/>
					<ProductsList
						isLoading={isProductsLoading}
						error={productsLoadError}
						productsList={productsList}
					/>
				</div>
			</div>
		</div>
	);
}
