import { useSelector } from 'react-redux';
import { selectUserPreferences } from '../features/User';
import { selectProductsVariationsStock } from '../Store/productsVariationsStock';
import { calculateProductSku } from '../utils/products.utils';

export const useSelectProductVarientStockBySku = (skuId) => {
	const productsVariationsStock = useSelector(selectProductsVariationsStock);
	return productsVariationsStock[skuId];
};

export const useSelectProductVarientStockByOptions = (
	productId,
	curvature,
	thickness,
	length
) => {
	const productsVariationsStock = useSelector(selectProductsVariationsStock);
	const productSku = calculateProductSku(
		productId,
		curvature,
		thickness,
		length
	);
	return productsVariationsStock[productSku];
};

export const useSelectFirstAvailableVarient = (productId) => {
	const userPreferences = useSelector(selectUserPreferences);
	const allProductsVariationsStock = useSelector(selectProductsVariationsStock);
	const currentProductVaritentStock = Object.keys(allProductsVariationsStock)
		.filter((key) => key.includes(productId))
		.reduce((cur, key) => {
			return Object.assign(cur, {
				[key.replace(`${productId}_`, '')]: allProductsVariationsStock[key],
			});
		}, {});

	const userPreferredVarient =
		userPreferences && userPreferences.selectedVarients
			? userPreferences.selectedVarients[productId]
			: null;
	const isPreferredVariantAvailable = userPreferredVarient
		? currentProductVaritentStock[userPreferredVarient]?.available > 0
		: false;

	if (isPreferredVariantAvailable) {
		return userPreferredVarient.split('_');
	}

	const availableVarientKey = Object.keys(currentProductVaritentStock).find(
		(key) => currentProductVaritentStock[key]?.available > 0
	);

	if (availableVarientKey) {
		return availableVarientKey.split('_');
	}

	return userPreferredVarient
		? userPreferredVarient.split('_')
		: Object.keys(currentProductVaritentStock)[0]?.split('_') || [];
};
