import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import {
	selectActiveCourses,
	selectPastCourses,
} from '../../Store/ordersHistory';
import { selectAllCoursesObjectified } from '../../Store/courses';
import { setQuickCheckoutCourse } from '../../Store/checkout';
import { getHumanReadableTimeDifference } from '../../utils/date.utils';
import { MyCourseCard } from './MyCourseCard';

import styles from './MyCourses.module.scss';

const MyCourses = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { activeCoursesLoading, activeCourses, activeCoursesError } =
		useSelector(selectActiveCourses);
	const { pastCoursesLoading, pastCourses, pastCoursesError } =
		useSelector(selectPastCourses);
	const allCourses = useSelector(selectAllCoursesObjectified);

	const courseExpiryText = useCallback((courseExpiry) => {
		return getHumanReadableTimeDifference(courseExpiry);
	}, []);

	const onPayOutstandingFee = async (courseId, classTime) => {
		if (!allCourses[courseId]) {
			return;
		}
		await dispatch(
			setQuickCheckoutCourse({
				isQuickCheckout: true,
				quickOrderType: 'outstanding',
				course: allCourses[courseId],
				selectedClassTime: classTime,
			})
		);
		navigate('/checkout-details');
	};

	return (
		<div className='row my-5'>
			<Tabs defaultActiveKey='activeCourses' className={clsx(styles.tabNav)}>
				<Tab
					tabClassName={styles.tabButton}
					eventKey='activeCourses'
					title='Active Courses'
				>
					{activeCoursesLoading || !!activeCoursesError ? (
						<div className='text-center my-5'>
							{activeCoursesLoading && (
								<div
									className='spinner-border text-primary'
									role='status'
								></div>
							)}
							{activeCoursesError && (
								<p className='h5 fw-normal text-muted'>
									{activeCoursesError || 'Courses loading failed.'}
								</p>
							)}
						</div>
					) : (
						<div>
							{activeCourses.map((activeCourseItem) => (
								<MyCourseCard
									key={activeCourseItem.id}
									{...activeCourseItem}
									isActiveCourse={true}
									courseExpiryText={courseExpiryText}
									onPayOutstandingFee={onPayOutstandingFee}
								/>
							))}
							{activeCourses.length === 0 && (
								<p className='col-12 h5 text-center my-5'>
									Nu aveți încă un curs activ.
								</p>
							)}
						</div>
					)}
				</Tab>
				<Tab
					tabClassName={styles.tabButton}
					eventKey='pastCourses'
					title='Past Courses'
				>
					{pastCoursesLoading || !!pastCoursesError ? (
						<div className='text-center my-5'>
							{pastCoursesLoading && (
								<div
									className='spinner-border text-primary'
									role='status'
								></div>
							)}
							{pastCoursesError && (
								<p className='h5 fw-normal text-muted'>
									{pastCoursesError || 'Courses loading failed.'}
								</p>
							)}
						</div>
					) : (
						<div>
							{pastCourses.map((pastCourseItem) => (
								<MyCourseCard
									key={pastCourseItem.id}
									{...pastCourseItem}
									isActiveCourse={false}
								/>
							))}
							{pastCourses.length === 0 && (
								<p className='col-12 h5 text-center my-5'>
									Nu aveți încă abonamente la cursuri anterioare.
								</p>
							)}
						</div>
					)}
				</Tab>
			</Tabs>
		</div>
	);
};

export default MyCourses;
