import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { Currency } from '../../components/Currency';
import { LocalizedDate } from '../../components/LocalizedDate';

import styles from './Cart.module.scss';

const CartCourseItem = ({
	id,
	cover,
	title,
	oldPrice,
	price,
	isAccredited,
	isOnline,
	startsOn,
	onRemoveFromCart,
	classes,
	selectedClassTime,
}) => {
	return (
		<div className='position-relative overflow-hidden'>
			<div
				className={clsx(
					'd-flex flex-column flex-sm-row h-100 py-2 my-2 border-bottom'
				)}
			>
				<LinkContainer to={`/courses/${id}`}>
					<div className={clsx(styles['left-col'], 'position-relative')}>
						{isAccredited && (
							<span className={styles['acreditat']}>acreditat</span>
						)}
						<img
							src={cover}
							className={clsx(styles['fav-img'])}
							height='160px'
							alt={title}
						/>
					</div>
				</LinkContainer>
				<div className='px-sm-3'>
					<Link className='h5 fw-normal text-dark' to={`/courses/${id}`}>
						{title}
					</Link>
					<div className='mt-2'>
						<span className={styles['courseTypeBadge']}>
							Curs{isOnline ? ' Online' : ' Fizic'}
						</span>
						{isAccredited && (
							<span
								className={clsx(styles['courseTypeBadge'], 'ms-2 d-sm-none')}
							>
								Acreditat
							</span>
						)}
						{!!(classes && selectedClassTime) && (
							<p className='small mb-0'>
								Clasă:
								<span className='text-capitalize fw-bold fst-italic ms-1'>
									{selectedClassTime}
								</span>
							</p>
						)}
						{!isOnline && (
							<p className='small'>
								Începe pe:
								<LocalizedDate
									date={startsOn}
									className='fw-bold fst-italic ms-1'
								/>
							</p>
						)}
					</div>
				</div>
				<div className={styles['right-col']}>
					{oldPrice > 0 && (
						<Currency
							className='text-danger fw-bold text-decoration-line-through'
							price={oldPrice}
						/>
					)}
					<Currency className='h4 fw-bold' price={price} />
					<span
						className={styles['fav-remove-item']}
						onClick={() => onRemoveFromCart(id, selectedClassTime)}
					>
						<BsTrash />
						Șterge
					</span>
				</div>
			</div>
		</div>
	);
};

export default CartCourseItem;
