import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { profileUpdateFormSchema } from './formValidations';
import { BsCheckCircleFill } from 'react-icons/bs';

import {
	resetUserUpdateStatus,
	selectLoggedinUser,
	selectUserUpdateStatus,
	updateProfileData,
} from '../../features/User';
import { splitFullName } from '../../utils/general.utils';

import styles from './MyAccount.module.scss';
import clsx from 'clsx';

const ProfileUpdateFrom = ({ closeModal }) => {
	const userInfo = useSelector(selectLoggedinUser);
	const dispatch = useDispatch();
	const { userUpdateLoading, userUpdateSuccess, userUpdateError } = useSelector(
		selectUserUpdateStatus
	);

	const onUpdateUser = (values) => {
		const trimmedValues = {
			displayName: values.firstName.trim().concat(' ', values.lastName.trim()),
			email: values.email.trim().toLowerCase(),
			phone: values.phone.trim(),
		};
		if (trimmedValues.email !== userInfo.email) {
			trimmedValues.emailChanged = true;
		}

		dispatch(updateProfileData(trimmedValues));
	};

	const { values, handleChange, handleSubmit, touched, errors, dirty } =
		useFormik({
			initialValues: {
				firstName: splitFullName(userInfo?.displayName).firstName,
				lastName: splitFullName(userInfo?.displayName).lastName,
				email: userInfo?.email || '',
				phone: userInfo?.phone || '',
			},
			validationSchema: profileUpdateFormSchema,
			onSubmit: onUpdateUser,
		});

	const closeModalAfterSucces = () => {
		dispatch(resetUserUpdateStatus());
		closeModal();
	};

	return (
		<div className={clsx(userUpdateSuccess && styles.successContainer)}>
			<form onSubmit={handleSubmit}>
				{!!userUpdateError && (
					<p className='alert alert-danger fw-bold text-center'>
						{userUpdateError}
					</p>
				)}
				<div className='form-group'>
					<label>Nume</label>
					<input
						name='lastName'
						type='text'
						className='form-control mt-1'
						placeholder='Nume'
						value={values.lastName}
						onChange={handleChange}
					/>
					{touched.lastName && errors.lastName && (
						<span className='text-danger tiny-text'>{errors.lastName}</span>
					)}
				</div>
				<div className='form-group'>
					<label>Prenume</label>
					<input
						name='firstName'
						type='text'
						className='form-control mt-1'
						placeholder='Prenume'
						value={values.firstName}
						onChange={handleChange}
					/>
					{touched.firstName && errors.firstName && (
						<span className='text-danger tiny-text'>{errors.firstName}</span>
					)}
				</div>
				<div className='form-group'>
					<label>Email</label>
					<input
						name='email'
						type='email'
						className='form-control mt-1'
						placeholder='Adresă Email'
						value={values.email}
						onChange={handleChange}
					/>
					{touched.email && errors.email && (
						<span className='text-danger tiny-text'>{errors.email}</span>
					)}
				</div>
				<div className='form-group'>
					<label>Phone</label>
					<input
						name='phone'
						type='tel'
						className='form-control mt-1'
						placeholder='Phone'
						value={values.phone}
						onChange={handleChange}
					/>
					{touched.phone && errors.phone && (
						<span className='text-danger tiny-text'>{errors.phone}</span>
					)}
				</div>
				<div className='d-flex gap-2 mt-3'>
					<button
						className='btn btn-primary text-white'
						type='submit'
						disabled={!dirty}
					>
						Update Profile
					</button>
					<button
						className='btn btn-secondary'
						type='button'
						onClick={closeModal}
					>
						Cancel
					</button>
				</div>
			</form>
			{userUpdateLoading && (
				<div className={styles.loadingOverlay}>
					<div className='spinner-border text-primary' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
					<div className='message'>Processing...</div>
				</div>
			)}
			{!!userUpdateSuccess && (
				<div className={clsx(styles.successOverlay, 'p-0')}>
					<BsCheckCircleFill className='text-primary default' size={50} />
					<div className=''>
						<p>{userUpdateSuccess}</p>
						<button
							className='btn btn-primary text-white py-2 w-50 mt-2'
							onClick={closeModalAfterSucces}
						>
							Close
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProfileUpdateFrom;
