import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { OrderSummary } from '../../components/Checkout';
import { useDispatch, useSelector } from 'react-redux';
import {
	setLoading,
	selectHasShipping,
	selectIsCheckoutLoading,
	generatePaymentIntent,
	selectCheckoutData,
	setCheckoutData,
	setOrderSuccessData,
	selectQuickCheckout,
	setQuickCheckoutCourse,
	generateQuickCourseOrderPaymentIntent,
} from '../../Store/checkout';
import { clearCartAsync } from '../../features/Cart';
import { loadProductsVariationsStock } from '../../Store/productsVariationsStock';
import {
	useCoursesInCart,
	useCoursesInCartTotalAmount,
} from '../../hooks/courses';
import { resetCoursesCart } from '../../Store/coursesCart';

import { orderPaymentLabels } from '../../StaticData';

const STRIPE_INPUT_OPTIONS = {
	showIcon: true,
	classes: {
		base: 'form-control py-3',
		invalid: 'border-danger',
	},
};
const OrderPayment = () => {
	const stripe = useStripe();
	const stripeElements = useElements();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const coursesInCart = useCoursesInCart();
	const coursesPayableAmount = useCoursesInCartTotalAmount();
	const { cartItemsList } = useSelector((state) => state.cart);
	const { total, shipping } = useSelector((state) => state.cart);
	const isLoading = useSelector(selectIsCheckoutLoading);
	const hasShipping = useSelector(selectHasShipping);
	const checkoutData = useSelector(selectCheckoutData);
	const {
		isQuickCheckout,
		quickOrderType,
		courseToProcess,
		courseToProcessClassTime,
	} = useSelector(selectQuickCheckout);

	const handleQuickCheckout = async () => {
		try {
			// Get payment intent from the server
			const { payload: result } = await dispatch(
				generateQuickCourseOrderPaymentIntent()
			);

			if (!result.success) {
				throw new Error(result.message);
			}

			const { error: confirmationError, paymentIntent } =
				await stripe.confirmCardPayment(result.clientSecret, {
					payment_method: {
						card: stripeElements.getElement(CardNumberElement),
						billing_details: {
							name: checkoutData?.billingAddress?.name,
							phone: checkoutData?.billingAddress?.phone,
						},
					},
				});

			if (confirmationError) {
				throw confirmationError;
			}

			// Post Payment Actions
			if (paymentIntent.status === 'canceled') {
				toast.error(
					paymentIntent.cancellation_reason ||
					'Plata nu a putut fi făcută. Încearcă mai târziu.'
				);
				return;
			}

			let totalCharges =
				quickOrderType === 'full'
					? courseToProcess.price
					: quickOrderType === 'registration'
						? courseToProcess.registrationFee
						: quickOrderType === 'outstanding'
							? courseToProcess.price - courseToProcess.registrationFee
							: 0;

			if (
				courseToProcess.kitIncluded &&
				hasShipping &&
				shipping > 0 &&
				totalCharges < 299
			) {
				totalCharges += shipping;
			}
			await dispatch(
				setOrderSuccessData({
					total: totalCharges,
				})
			);

			dispatch(setCheckoutData(null));
			dispatch(setQuickCheckoutCourse());
			return navigate('/order-success');
		} catch (error) {
			console.error('ERROR::', error);
			toast.error(error.message);
		} finally {
			dispatch(setLoading(false));
		}
	};

	const onSubmit = async () => {
		try {
			dispatch(setLoading(true));

			// Check if card details provided
			if (!stripe || !stripeElements) {
				throw new Error('Stripe not intialized');
			}

			// Prevent user from submitting empty form...
			const paymentMethodObj = {
				type: 'card',
				card: stripeElements.getElement(CardNumberElement),
			};
			const { error } = await stripe.createPaymentMethod(paymentMethodObj);
			if (error) {
				throw error;
			}

			if (isQuickCheckout && courseToProcess) {
				await handleQuickCheckout();
				return;
			}

			// Get payment intent from the server
			const { payload: result } = await dispatch(generatePaymentIntent());
			if (!result.success) {
				throw new Error(result.message);
			}

			const { error: confirmationError, paymentIntent } =
				await stripe.confirmCardPayment(result.clientSecret, {
					payment_method: {
						card: stripeElements.getElement(CardNumberElement),
						billing_details: {
							name: checkoutData?.billingAddress?.name,
							phone: checkoutData?.billingAddress?.phone,
						},
					},
				});

			if (confirmationError) {
				throw confirmationError;
			}

			// Post Payment Actions
			if (paymentIntent.status === 'canceled') {
				toast.error(
					paymentIntent.cancellation_reason ||
					'Plata nu a putut fi făcută. Încearcă mai târziu.'
				);
				return;
			}

			let totalCharges = total + coursesPayableAmount;
			if (totalCharges < 299 && hasShipping && shipping > 0) {
				totalCharges += shipping;
			}
			await dispatch(
				setOrderSuccessData({
					total: totalCharges,
				})
			);

			dispatch(clearCartAsync());
			dispatch(resetCoursesCart());
			dispatch(setCheckoutData(null));
			dispatch(loadProductsVariationsStock());
			return navigate('/order-success');
		} catch (error) {
			console.error('ERROR::', error);
			toast.error(error.message);
		} finally {
			dispatch(setLoading(false));
		}
	};

	useEffect(() => {
		if (!checkoutData?.billingAddress) {
			navigate('/checkout-details');
		}
	}, []);

	return (
		<Formik initialValues={{}} onSubmit={onSubmit}>
			<div className='row my-5'>
				<div className='col-md-7 col-lg-8'>
					<div
						className={clsx('card', 'non-hoverable', 'border-0', 'px-4 pb-4')}
					>
						<div className='section-title border-bottom d-flex justify-content-between align-items-center'>
							<h3 className='title h5 fw-bold'>
								{orderPaymentLabels.displayName}
							</h3>
						</div>
						<div className='row g-3'>
							<div className='col-12'>
								<p className='text-muted'>{orderPaymentLabels.text}</p>
							</div>
							<div className='col-sm-7'>
								<label className='form-label'>
									{orderPaymentLabels.cardNumber}
								</label>
								<CardNumberElement options={STRIPE_INPUT_OPTIONS} />
							</div>
							<div className='col-sm-3 col-7'>
								<label className='form-label'>
									{orderPaymentLabels.expDate}
								</label>
								<CardExpiryElement options={STRIPE_INPUT_OPTIONS} />
							</div>
							<div className='col-sm-2 col-5'>
								<label className='form-label'>{orderPaymentLabels.cvc}</label>
								<CardCvcElement options={STRIPE_INPUT_OPTIONS} />
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-5 col-lg-4'>
					<Form>
						{isQuickCheckout && courseToProcess ? (
							<OrderSummary
								btnTitle={orderPaymentLabels.pay}
								cartItems={[]}
								cartCourses={[]}
								hidePaymentTypeSelection={true}
								hideTermsAcceptance={true}
								hasShipping={courseToProcess.kitIncluded && hasShipping}
								shippingCharges={shipping}
								codCharges={0}
								total={
									quickOrderType === 'full'
										? courseToProcess.price
										: quickOrderType === 'registration'
											? courseToProcess.registrationFee
											: quickOrderType === 'outstanding'
												? courseToProcess.price - courseToProcess.registrationFee
												: 0
								}
								totalCoursesAmount={0}
								isActionLoading={isLoading}
								isQuickCheckout={true}
								quickCheckoutTitle={courseToProcess.title}
								courseClassTime={courseToProcessClassTime}
								quickOrderType={quickOrderType}
							/>
						) : (
							<OrderSummary
								hidePaymentTypeSelection={true}
								hideTermsAcceptance={true}
								btnTitle={orderPaymentLabels.pay}
								cartItems={cartItemsList}
								cartCourses={coursesInCart}
								hasShipping={hasShipping}
								shippingCharges={shipping}
								codCharges={0}
								total={total}
								totalCoursesAmount={coursesPayableAmount}
								isActionLoading={isLoading}
							/>
						)}
					</Form>
				</div>
			</div>
		</Formik>
	);
};

export default OrderPayment;
