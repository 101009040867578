import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';

const ProductImagesSlider = ({ images }) => {
	const [activeThumb, setActiveThumb] = useState();

	return (
		<>
			<Swiper
				spaceBetween={10}
				navigation={true}
				modules={[Navigation, Thumbs]}
				thumbs={{ swiper: activeThumb }}
				className='product-images-slider'
			>
				{images.map((item, index) => (
					<SwiperSlide key={index}>
						<img src={item} alt='product images' />
					</SwiperSlide>
				))}
			</Swiper>
			<Swiper
				onSwiper={setActiveThumb}
				spaceBetween={4}
				slidesPerView={4}
				className='product-images-slider-thumbs'
			>
				{images.map((item, index) => (
					<SwiperSlide key={index}>
						<div className='product-images-slider-thumbs-wrapper'>
							<img src={item} alt='product images' />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
};

export default ProductImagesSlider;
