import {
	createSlice,
	createAsyncThunk,
	createSelector,
	isAnyOf,
} from '@reduxjs/toolkit';
import {
	collection,
	doc,
	documentId,
	getDoc,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { orderBy as sortProducts } from 'lodash';
import { fireDB } from '../Firebase';

const initialState = {
	loading: true,
	products: [],
	error: null,
	activeFilter: null,
	sortBy: null,
};

export const loadProductsList = createAsyncThunk(
	'productsList/load',
	async (_, { getState, rejectWithValue }) => {
		const { productsCategories, productsList } = getState();
		const { selected: activeCategory } = productsCategories;
		const { activeFilter, sortBy } = productsList;
		const docRef = doc(fireDB, 'productsByCategory', activeCategory);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) {
			return rejectWithValue('Product list not available for this category.');
		}
		const { products: categoryProducts } = docSnap.data();

		// Get refrence of all the products by IDs
		const prodRefs = collection(fireDB, 'products');
		const productsQueriesBatches = Math.ceil(categoryProducts.length / 10);
		let allProducts = [];

		for (let index = 0; index < productsQueriesBatches; index++) {
			const batchStartIndex = index * 10;
			const batchEndIndex = batchStartIndex + 10;
			const docsQuery = query(
				prodRefs,
				where(
					documentId(),
					'in',
					categoryProducts
						.slice(batchStartIndex, batchEndIndex)
						.map(({ id }) => id)
				)
			);
			const productsDocsSnap = await getDocs(docsQuery);
			let refinedProducts = productsDocsSnap.docs.map((doc) => ({
				...doc.data(),
				id: doc.id,
			}));
			allProducts.push(...refinedProducts);
		}

		if (activeFilter && sortBy) {
			allProducts = sortProducts(allProducts, activeFilter, sortBy);
		}
		return allProducts;
	}
);

export const filterProducts = createAsyncThunk(
	'productsList/filter',
	async (_, { getState }) => {
		const { productsList } = getState();
		const { activeFilter, sortBy, products } = productsList;
		if (activeFilter && sortBy) {
			return sortProducts(products, activeFilter, sortBy);
		}
		return products;
	}
);

const slice = createSlice({
	name: 'productsList',
	initialState,
	reducers: {
		setActiveFilterAndSort: (state, action) => {
			// Seperate filter and sort e.g. price:desc else returns null
			const [filter, sortBy] = action.payload
				? action.payload.split(':')
				: [null, null];
			state.activeFilter = filter;
			state.sortBy = sortBy;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(loadProductsList.pending), (state, action) => {
			state.products = [];
			state.loading = true;
			state.error = null;
		});
		builder.addMatcher(
			isAnyOf(loadProductsList.fulfilled, filterProducts.fulfilled),
			(state, action) => {
				state.products = action.payload;
				state.loading = false;
			}
		);
		builder.addMatcher(
			isAnyOf(loadProductsList.rejected, filterProducts.rejected),
			(state, action) => {
				state.loading = false;
				state.products = [];
				state.error =
					action.payload ||
					action.error.message ||
					"Couldn't load the data, please refresh.";
			}
		);
	},
});

// ACTIONS
export const { setActiveFilterAndSort } = slice.actions;

// SELECTORS
const selectProductListData = (state) => {
	return state.productsList;
};
export const selectIsProdListLoading = createSelector(
	selectProductListData,
	(productListData) => productListData.loading
);
export const selectProdListError = createSelector(
	selectProductListData,
	(productListData) => productListData.error
);
export const selectProdList = createSelector(
	selectProductListData,
	({ products }) => products
);
export const selectProdFilter = createSelector(
	selectProductListData,
	({ activeFilter, sortBy }) => {
		if (!activeFilter || !sortBy) {
			return null;
		}
		return `${activeFilter}:${sortBy}`;
	}
);

export default slice.reducer;
