import { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';
import { BsHeart, BsHeartFill, BsStarFill } from 'react-icons/bs';
import { Currency } from '../Currency';
import { LocalizedDate } from '../LocalizedDate';
import { CourseClassTimeSelection } from '../CourseClassTimeSelection';
import { toggleCourseItemFavorite } from '../../features/Favorites';
import { updateCourseClassTimePreference } from '../../features/User';
import {
	useIsCourseInFavorites,
	useSelectFirstAvailableClass,
} from '../../hooks/courses';

import styles from './CourseCard.module.scss';

const CourseCard = ({
	id,
	cover,
	title,
	rating,
	reviewCount,
	price,
	oldPrice,
	acreditat,
	startDate,
	isOnline,
	courseLocation,
	classes,
}) => {
	const navigate = useNavigate();
	const [isFavorite, setIsFavorite] = useState(false);
	const [selectedClassTime, setSelectedClassTime] = useState('');
	const isAlreadyInFavourites = useIsCourseInFavorites(id);

	const dispatch = useDispatch();
	const preferredClassTime = useSelectFirstAvailableClass(id, classes);

	useEffect(() => {
		setIsFavorite(isAlreadyInFavourites);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAlreadyInFavourites]);

	const onViewDetail = () => {
		navigate(`/courses/${id}`);
	};

	const toggleFavorites = async (e) => {
		e.preventDefault();
		const result = await dispatch(
			toggleCourseItemFavorite({
				itemId: id,
				operationType: isFavorite ? 'remove' : 'add',
			})
		);
		if (!result.error) {
			setIsFavorite(!isFavorite);
		}
	};

	const updateSelectedClassTime = async (classTime) => {
		dispatch(
			updateCourseClassTimePreference({
				courseId: id,
				selectedClassTime: classTime,
			})
		);
		setSelectedClassTime(classTime);
	};

	useEffect(() => {
		if (preferredClassTime) {
			setSelectedClassTime(preferredClassTime);
		}
	}, [preferredClassTime]);

	const selectedClass = useMemo(() => {
		if (classes && selectedClassTime) {
			return classes.find((item) => item.class === selectedClassTime);
		}
	}, [classes, selectedClassTime]);

	return (
		<div className={clsx(styles.card)}>
			<div>
				<LinkContainer to={`/courses/${id}`}>
					<div className={clsx(styles['img-wrapper'])}>
						{acreditat && (
							<span className={clsx(styles.acreditat, 'text-primary')}>
								acreditat
							</span>
						)}
						<img
							src={cover}
							className={clsx(styles['card-image'])}
							alt={title}
						/>
						{oldPrice > 0 && (
							<div className={styles['card-badge-price']}>
								<Currency price={price - oldPrice} />
							</div>
						)}
						{selectedClass && (
							<div className={styles.seatAvailablity}>
								{selectedClass.availableSeats} Locuri disponibile
							</div>
						)}
					</div>
				</LinkContainer>
				<div className={clsx(styles['card-details'])}>
					<div className='mb-3'>
						<p className={styles.courseTypeBadge}>
							Curs {isOnline ? 'Online' : 'Fizic'}
						</p>
						{courseLocation && (
							<p className='small mb-1'>
								<span>Locație:</span>
								<span className='fw-bold ms-1 fst-italic'>
									{courseLocation}
								</span>
							</p>
						)}
						{!isOnline && (
							<p className='small mb-1'>
								Începe pe:{' '}
								<LocalizedDate
									date={startDate}
									className='fw-bold fst-italic'
								/>
							</p>
						)}
						{classes && (
							<CourseClassTimeSelection
								courseId={id}
								classes={classes}
								selectedClassTime={selectedClassTime}
								onChange={updateSelectedClassTime}
								size='small'
							/>
						)}
					</div>
					<Link className={clsx(styles['card-title'])} to={`/courses/${id}`}>
						<h5>
							{title.substring(0, 70)}
							{title.length > 70 && <span>...</span>}
						</h5>
					</Link>
					<div className={clsx(styles['card-stats'])}>
						<BsStarFill
							className={clsx('default text-primary')}
							size={'14px'}
						/>
						<span className='mx-1'>{rating}</span>
						<span>({reviewCount})</span>
					</div>
				</div>
			</div>
			<div className=''>
				<div className={clsx(styles['price-div'])}>
					{oldPrice > 0 && (
						<del className={clsx(styles['product-old-price'])}>
							<Currency price={oldPrice} />
						</del>
					)}
					<h4 className={clsx(styles['card-price'])}>
						<Currency price={price} />
					</h4>
				</div>
				<div className={clsx(styles['buttons'])}>
					<div className='pe-2'>
						{isFavorite ? (
							<BsHeartFill
								className={clsx(styles['heart'], styles['active'])}
								onClick={toggleFavorites}
							/>
						) : (
							<BsHeart onClick={toggleFavorites} />
						)}
					</div>
					<div>
						<button
							className='btn btn-primary btn-lg text-nowrap text-white'
							onClick={onViewDetail}
						>
							Vezi detalii
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CourseCard;
